import React from 'react';
import {Box, Grid} from '@material-ui/core';
import {Warning} from '@material-ui/icons';
import {withTracker} from 'react-meteor-data-with-tracker';
import {ISlideshow, Slideshow} from 'shared/collections/Slideshows';
import SlideshowSelectField from '../../../../user/stream/SlideshowSelect';
import {LL} from 'shared/localization';

interface ISlideshowSelectProps {
	slideshowNumId?: string;
	onChange: (slideshowNumId: string) => void;
	slideshowsList: ISlideshow[];
}

const SlideshowSelect: React.FC<ISlideshowSelectProps> = ({
	slideshowNumId,
	onChange,
	slideshowsList,
}) => {
	return (
		<Grid container direction="column">
			<Grid item xs={12}>
				<Box mb={2} fontSize={21}>
					{LL('common.userHeader.slideShow')}
				</Box>
				<Box mb={slideshowNumId ? 5 : 2}>
					<SlideshowSelectField value={slideshowNumId} onChange={onChange} />
				</Box>

				{!slideshowNumId && (
					<Box
						mb={5}
						color="red"
						fontSize={18}
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 5,
						}}
					>
						<Warning />
						{LL('components.firsChooseSS')}
					</Box>
				)}
			</Grid>
		</Grid>
	);
};

export default withTracker(() => ({
	slideshowsList: Slideshow.find({}, {sort: {createdAt: -1}}).fetch() || [],
}))(SlideshowSelect);
