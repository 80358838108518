import * as React from 'react';
import {connect} from 'react-redux';

import {updateSlideElement} from 'client/actions/slideShowEditor';
import {
	ISlideElement,
	SlideElementWeatherTypeEnum,
	SlideElementWeatherTypeDisplayEnum,
	SlideElementWeatherWidgetTypeEnum,
	SlideElementWeatherWidgetTypeDisplayEnum,
	SlideElementWeatherIconSet,
	SlideElementWeatherInfo,
	SlideElementWeatherInfoEnum,
	SlideElementWeatherInfoDisplayEnum,
} from 'shared/collections/SlideElements';
import EditorActionButtonsCommon from '../formFields/EditorActionButtonsCommon';
import EditorDelimiter from '../formFields/EditorDelimiter';
import EditorSelect from '../formFields/EditorSelect';
import BgColorField from '../settingsFields/BgColorField';
import BgImageField from '../settingsFields/BgImageField';
import OpacityField from '../settingsFields/OpacityField';
import TextUnitField from '../settingsFields/TextUnitField';
import PermanentElementField from '../settingsFields/PermanentElementField';
import ElementSettingsWrapper from 'client/components/common/ElementSettingsWrapper';
import EditorCheckbox from 'client/components/editor/formFields/EditorCheckbox';
import RotateAngleField from 'client/components/editor/settingsFields/RotateAngleField';
import ToggleBoxGroup from 'client/components/common/ToggleBox/ToggleBoxGroup';
import {Box, Grid} from '@material-ui/core';
import EditorAutocomplete, {
	IEditorAutocompleteOption,
} from 'client/components/editor/formFields/EditorAutocomplete';
import {appConfig} from 'client/constants/config';
import EditorMultipleSelect from 'client/components/editor/formFields/EditorMultipleSelect';
import {LL} from 'shared/localization';

interface ITextElementSettingsProps {
	element: ISlideElement;
	updateSlideElement: typeof updateSlideElement;
}
interface ITextElementSettingsState {
	address?: string;
	foundObjects: IEditorAutocompleteOption[] | [];
	addressLoading?: boolean;
}

interface ICityOption extends IEditorAutocompleteOption {
	location: {lan: string; lon: string};
	label?: string;
	value?: string;
}

class WeatherElementSettings extends React.PureComponent<
	ITextElementSettingsProps,
	ITextElementSettingsState
> {
	state: ITextElementSettingsState = {foundObjects: [], address: ''};

	componentDidMount(): void {
		const {element} = this.props;

		if (element.location) {
			this.setState({address: element.location});

			if (!element.lat || !element.lon) {
				this.handleUpdateCoords(element.location);
			}
		}
	}
	componentDidUpdate(prevProps: Readonly<ITextElementSettingsProps>) {
		const {element} = this.props;

		if (element.location !== prevProps.element.location) {
			this.setState({address: element.location});
		}
	}

	handleDisplayDaysChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {element} = this.props;

		this.props.updateSlideElement(element, {
			displayDays: Number(event.target.value),
		});
	};

	handleIconChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		// console.log({weatherIconSet: event.target.value});
		const {element} = this.props;
		this.props.updateSlideElement(element, {
			weatherIconSet: event.target.value,
		});
	};

	handleInfoParams = (event: React.ChangeEvent<{value?: string[] | string}>) => {
		const {element} = this.props;

		this.props.updateSlideElement(element, {weatherInfo: event.target.value as string[]});
	};

	handleDisplayWidgetTypeChange = (event) => {
		const {element} = this.props;
		this.props.updateSlideElement(element, {
			weatherWidgetType: event.target.value,
			// 50
			height:
				{
					WIDE: 186,
					COMPACT: 349,
					THIN: 177,
					ROUND: 349,
					INFO: 177,
					WEEK_H: 349,
					WEEK_V: 700,
				}[event.target.value] + (element.hideTitle ? 0 : 50),
			width: {
				WIDE: 800,
				COMPACT: 600,
				THIN: 800,
				ROUND: 349,
				INFO: 200,
				WEEK_H: 900,
				WEEK_V: 400,
			}[event.target.value],
		});
	};
	handleColorChange = (textColor, prefix) => {
		const {element} = this.props;

		this.props.updateSlideElement(element, {
			[prefix ? `${prefix}FontColor` : 'textColor']: textColor,
		});
	};
	handleHideTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = e.target;
		const {element} = this.props;

		this.props.updateSlideElement(element, {
			hideTitle: checked,
			height: element.height + (checked ? -(element.fontSize * 2) : element.fontSize * 2),
		});
	};

	handleInfoParamsRules = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, value} = e.target;
		const {element} = this.props;

		this.props.updateSlideElement(element, {
			[value]: checked,
		});
	};

	handleWeatherTypeChange = (weatherType?: SlideElementWeatherTypeEnum) => {
		const {element} = this.props;
		this.props.updateSlideElement(element, {
			weatherType,
			height: weatherType === SlideElementWeatherTypeEnum.WIDGET ? 370 : 970,
		});
	};

	clearFoundObjects = () => this.setState({foundObjects: []});

	handleClearCords = () => {
		const {element} = this.props;

		this.props.updateSlideElement(element, {
			lon: '',
			lat: '',
		});
	};

	handleLocationChange = (value?: string) => {
		const {element} = this.props;

		this.props.updateSlideElement(element, {location: value});
	};

	handleCityChange = (value: string) => {
		// const {element} = this.props;
		// console.log({value});

		this.setState({address: value, addressLoading: true});

		// this.props.updateSlideElement(element, {
		// 	location: value,
		// });

		if (!value) return;

		this.handleUpdateCoords(value);
	};

	handleCityClear = () => {
		this.clearFoundObjects();
	};

	handleCitySelect = (option: ICityOption) => {
		const {element} = this.props;
		const text = option.value.toLowerCase();
		this.setState({address: text});

		this.props.updateSlideElement(element, {
			location: text,
			airQualityCoordinates: [
				parseFloat(option.location.lat),
				parseFloat(option.location.lon),
			],
		});
	};

	handleUpdateCoords = (location: string) => {
		const {element} = this.props;
		const successStatus = 200;
		const {CITYGUIDE_ACCESS_KEY, YANDEX_GEOCODER_API_KEY} = appConfig;

		HTTP.get(
			`https://geocode-maps.yandex.ru/1.x/?format=json&kind=locality&geocode=${location}&apikey=${YANDEX_GEOCODER_API_KEY}`,
			// @ts-ignore
			(err, result) => {
				// console.log({result});
				if (!err && result.data && result.statusCode === successStatus) {
					if (
						result.data.response &&
						result.data.response.GeoObjectCollection &&
						result.data.response.GeoObjectCollection.featureMember
					) {
						const settlements = result.data.response.GeoObjectCollection.featureMember;

						const city = settlements[0];

						if (city && !!city.GeoObject.name) {
							if (settlements.length > 0) {
								this.setState({
									foundObjects: settlements.map((s: any) => ({
										label: `${s.GeoObject.name}, ${s.GeoObject.description ||
											''}`,
										value: `${s.GeoObject.name}`,
										location: {
											lat: String(s.GeoObject.Point.pos.split(' ')[1]),
											lon: String(s.GeoObject.Point.pos.split(' ')[0]),
										},
									})),
								});
							} else {
								this.clearFoundObjects();
							}

							this.setState({addressLoading: false});
						} else {
							HTTP.post(
								'https://api.probki.net/search/geocode',
								{
									data: {
										accessKey: CITYGUIDE_ACCESS_KEY,
										address: location,
									},
								},
								// @ts-ignore
								(error, res) => {
									// console.log({res});
									if (!error && res.data && res.statusCode === successStatus) {
										const {addresses} = res.data;

										if (addresses && addresses[0] && addresses[0].location) {
											if (addresses.length > 1) {
												this.setState({
													foundObjects: addresses.map((s: any) => ({
														label: `${s.city}
														${s.street ? `, ${s.street}` : ''}${s.house ? ` ${s.house}` : ''}
														${s.province ? `, ${s.province}` : ''}
														${s.region ? `, ${s.region}` : ''}`,
														value: `${s.city}`,
														location: s.location,
													})),
												});
											} else {
												this.clearFoundObjects();
											}
										} else {
											this.handleClearCords();
											this.clearFoundObjects();
										}

										this.setState({addressLoading: false});
									}
								},
							);
						}
					}
				} else {
					this.handleClearCords();

					this.setState({addressLoading: false});
				}
			},
		);
	};

	renderIconLabel(value) {
		const iconUri = 'https://s.prtv.su/informers/weather';
		return (
			<Grid container spacing={3} style={{width: '100%', maxWidth: 240, padding: '5%'}}>
				<Grid item xs style={{textAlign: 'center'}}>
					<img
						style={{width: '100%'}}
						src={`${iconUri}/img_${value}_svg/skc_d.svg`}
						loading="lazy"
					/>
				</Grid>
				<Grid item xs style={{textAlign: 'center'}}>
					<img
						style={{width: '100%'}}
						src={`${iconUri}/img_${value}_svg/ovc_-ra.svg`}
						loading="lazy"
					/>
				</Grid>
				<Grid item xs style={{textAlign: 'center'}}>
					<img
						style={{width: '100%'}}
						src={`${iconUri}/img_${value}_svg/bkn_n.svg`}
						loading="lazy"
					/>
				</Grid>
			</Grid>
		);
	}

	render() {
		const {element} = this.props;
		const {address, foundObjects, addressLoading} = this.state;
		const isWidget = element.weatherType === SlideElementWeatherTypeEnum.WIDGET;
		const isRound = element.weatherWidgetType === SlideElementWeatherWidgetTypeEnum.ROUND;
		const isWeek = [
			SlideElementWeatherWidgetTypeEnum.WEEK_V,
			SlideElementWeatherWidgetTypeEnum.WEEK_H,
		].includes(element.weatherWidgetType);
		return (
			<ElementSettingsWrapper elementId={element._id}>
				{!element.weatherType && (
					<h5 style={{color: '#fff'}}>{LL('editor.element.weatherItemNoSupported')}</h5>
				)}
				{element.weatherType && (
					<>
						<Box display="flex" justifyContent="center" mb={2}>
							<ToggleBoxGroup
								name="weatherType"
								value={element.weatherType || SlideElementWeatherTypeEnum.WIDGET}
								data={[
									{
										value: SlideElementWeatherTypeEnum.WIDGET,
										label: SlideElementWeatherTypeDisplayEnum.WIDGET,
									},
									{
										value: SlideElementWeatherTypeEnum.MAP,
										label: SlideElementWeatherTypeDisplayEnum.MAP,
									},
								]}
								onChange={this.handleWeatherTypeChange}
							/>
						</Box>

						<EditorAutocomplete
							options={foundObjects}
							label={LL('editor.element.cityOrTown')}
							value={address}
							onSuggChange={this.handleCityChange}
							onSuggClear={this.handleCityClear}
							onSuggSelect={this.handleCitySelect}
						/>

						<EditorCheckbox
							label={LL('editor.element.hideTitle')}
							value="hideTitle"
							checked={element.hideTitle}
							onChange={this.handleHideTitleChange}
						/>

						<EditorSelect
							label={LL('editor.element.iconSet')}
							tooltip={LL('editor.element.iconSet.tooltip')}
							options={SlideElementWeatherIconSet.getValues().map((item) => {
								return {
									value: item,
									label: this.renderIconLabel(item),
								};
							})}
							value={element.weatherIconSet}
							onChange={this.handleIconChange}
						/>

						{!isRound && (
							<>
								<EditorMultipleSelect
									label={LL('editor.element.additionalParameters')}
									options={SlideElementWeatherInfo.getIdentifiers()
										.map((item) => {
											if (
												isWeek &&
												[
													SlideElementWeatherInfoEnum.DEW_POINT,
													SlideElementWeatherInfoEnum.SUNRISE,
													SlideElementWeatherInfoEnum.SUNSET,
													SlideElementWeatherInfoEnum.IS_DAY,
												].includes(SlideElementWeatherInfoEnum[item])
											)
												return null;
											return {
												label: SlideElementWeatherInfoDisplayEnum[item],
												value: SlideElementWeatherInfoEnum[item],
											};
										})
										.filter((val) => val)}
									onChange={this.handleInfoParams}
									value={element.weatherInfo || []}
								/>
								<EditorCheckbox
									label={LL('editor.element.newLine')}
									value="weatherInfoBrakes"
									checked={element.weatherInfoBrakes || false}
									onChange={this.handleInfoParamsRules}
								/>
								<EditorCheckbox
									label={LL('editor.element.namesPairs')}
									value="weatherInfoNames"
									checked={element.weatherInfoNames || false}
									onChange={this.handleInfoParamsRules}
								/>
								<EditorCheckbox
									label={LL('editor.element.iconPairs')}
									value="weatherInfoIcons"
									checked={element.weatherInfoIcons || false}
									onChange={this.handleInfoParamsRules}
								/>
							</>
						)}

						{isWidget && (
							<EditorSelect
								label={LL('editor.element.widgetOption')}
								tooltip={LL('editor.element.widgetOption.tooltip')}
								options={[
									{
										value: SlideElementWeatherWidgetTypeEnum.WIDE,
										label: SlideElementWeatherWidgetTypeDisplayEnum.WIDE,
									},
									{
										value: SlideElementWeatherWidgetTypeEnum.COMPACT,
										label: SlideElementWeatherWidgetTypeDisplayEnum.COMPACT,
									},
									{
										value: SlideElementWeatherWidgetTypeEnum.THIN,
										label: SlideElementWeatherWidgetTypeDisplayEnum.THIN,
									},
									{
										value: SlideElementWeatherWidgetTypeEnum.ROUND,
										label: SlideElementWeatherWidgetTypeDisplayEnum.ROUND,
									},
									{
										value: SlideElementWeatherWidgetTypeEnum.INFO,
										label: SlideElementWeatherWidgetTypeDisplayEnum.INFO,
									},
									{
										value: SlideElementWeatherWidgetTypeEnum.WEEK_H,
										label: SlideElementWeatherWidgetTypeDisplayEnum.WEEK_H,
									},
									{
										value: SlideElementWeatherWidgetTypeEnum.WEEK_V,
										label: SlideElementWeatherWidgetTypeDisplayEnum.WEEK_V,
									},
								]}
								value={element.weatherWidgetType}
								onChange={this.handleDisplayWidgetTypeChange}
							/>
						)}

						{!isWidget && (
							<EditorSelect
								label={LL('editor.element.day')}
								tooltip={LL('editor.element.day.tooltip')}
								options={[
									{label: LL('messages.today'), value: 1},
									{label: LL('editor.element.tomorrow'), value: 2},
									{label: LL('editor.element.afterTomorrow'), value: 3},
									{label: LL('editor.element.4thDay'), value: 4},
									{label: LL('editor.element.5thDay'), value: 5},
								]}
								value={element.displayDays}
								onChange={this.handleDisplayDaysChange}
							/>
						)}

						{isWidget &&
							element.weatherWidgetType !==
								SlideElementWeatherWidgetTypeEnum.WEEK_V &&
							element.weatherWidgetType !==
								SlideElementWeatherWidgetTypeEnum.WEEK_H && (
								<EditorSelect
									label={LL('editor.element.day')}
									tooltip={LL('editor.element.day.tooltip')}
									options={[
										{label: LL('messages.today'), value: 1},
										{label: LL('editor.element.tomorrow'), value: 2},
									]}
									value={element.displayDays}
									onChange={this.handleDisplayDaysChange}
								/>
							)}

						<TextUnitField
							element={element}
							prefix={null}
							label={isWidget ? LL('common.header') : LL('editor.element.tags')}
							tooltip={LL('editor.element.header.tooltip')}
							onChange={this.handleColorChange}
						/>

						{isWidget && (
							<>
								{!isRound && (
									<TextUnitField
										element={element}
										prefix={'time'}
										label={LL('editor.element.time')}
										tooltip={LL('editor.element.time.tooltip')}
										onChange={this.handleColorChange}
									/>
								)}
								<TextUnitField
									element={element}
									prefix={'temp'}
									label={LL('editor.element.temperature')}
									tooltip={LL('editor.element.temp.tooltip')}
									onChange={this.handleColorChange}
								/>
								{!isRound && (
									<TextUnitField
										element={element}
										prefix={'text'}
										label={LL('common.text')}
										tooltip={LL('editor.element.text.tooltip')}
										onChange={this.handleColorChange}
									/>
								)}
							</>
						)}

						<BgColorField element={element} />
						<BgImageField element={element} />
						<RotateAngleField element={element} />
						<OpacityField element={element} />
						<PermanentElementField element={element} />

						<EditorDelimiter />
					</>
				)}

				<EditorActionButtonsCommon element={element} />
			</ElementSettingsWrapper>
		);
	}
}

export default connect(null, {updateSlideElement})(WeatherElementSettings);
