import {Moment} from 'moment';
import MomentFunc from 'moment-timezone';
import {ISlideStreamSchedule} from 'shared/collections/SlideStream';
import {extendMoment} from 'moment-range';
import {IEventRanges} from './types';
import {DATE_FORMAT} from './constants';

const moment = extendMoment(MomentFunc);

export default (start: Moment, end: Moment, event: ISlideStreamSchedule) => {
	const it = start.clone();
	const res: IEventRanges = {};

	do {
		const from = it.clone();
		const to = end.isBefore(it.clone().endOf('day')) ? end.clone() : it.clone().endOf('day');

		const dateKey = it.format(DATE_FORMAT);
		res[dateKey] = res[dateKey] || [];
		res[dateKey].push({
			event,
			range: moment.range(from, to),
		});

		it.startOf('day').add(1, 'day');
	} while (it.isBefore(end));

	return res;
};
