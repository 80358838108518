import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import {format} from 'shared/utils/dates';
import UserDialog from './UserDialog';
import routerUrls from '../../../constants/routerUrls';
import {checkAdmin} from 'shared/utils/user';
import {methodNames} from 'shared/constants/methodNames';
import {Meteor} from 'meteor/meteor';
import ChangeUserPasswordDialog from 'client/components/admin/users/ChangeUserPasswordDialog';

import {LL} from 'shared/localization';

export const UsersTable = ({users, loading}) => {
	const authByUser = (_id) => {
		Meteor.call(methodNames.user.prepareForFakeLogin, {_id}, function(error, result) {
			// 1. take token
			if (error) {
				// TODO: show error message
			} else {
				Accounts.logout(() => {
					// 2. if ok, logout
					Accounts.callLoginMethod({
						// 3. ..and login by token
						methodArguments: [{fakeLoginToken: result}],
						userCallback: () => {
							location.href = '/';
						},
					});
				});
			}
		});
	};

	const approveUser = (_id) => {
		Meteor.call(methodNames.user.setStatus, {_id, status: 'approved'});
	};

	const blockUser = (_id) => {
		Meteor.call(methodNames.user.setStatus, {_id, status: 'blocked'});
	};

	const deleteUser = (id) => {
		Meteor.call(methodNames.user.delete, id, function(error) {
			if (error) {
				console.log(LL('common.error'));
			} else {
				console.log(LL('admin.users.userDeleted'));
			}
		});
	};

	const getUserAppsCount = () => {
		return LL('admin.users.temporarilyNotWorking');
		// return Slideshow.find({userId}).count();
	};

	if (loading) return <CircularProgress />;
	if (users && !users.length) {
		return <Paper className="errorPaper">{LL('admin.users.noUsersToDisplay')}</Paper>;
	}

	return (
		<Card className="tableCard">
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>#</TableCell>
						<TableCell>{LL('admin.users.fullName')}</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>{LL('common.telephone')}</TableCell>
						<TableCell>{LL('admin.users.registrationDate')}</TableCell>
						<TableCell>{LL('common.adminHeader.apps')}</TableCell>
						<TableCell style={{width: 195}}>{LL('common.actions')}</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{users.map((user, index) => (
						<TableRow key={user._id}>
							<TableCell>{index + 1}</TableCell>
							<TableCell>
								<a href={routerUrls.adminUsersEdit.replace(':id', user._id)}>
									{user.name} {user.surname}{' '}
									{user.name || user.surname ? '' : '-'}
								</a>
								<br />
								{checkAdmin(user) && (
									<small>{LL('admin.users.admin').toLowerCase()}</small>
								)}
							</TableCell>
							<TableCell>
								{user.emails && user.emails.map((email) => email.address)}
							</TableCell>
							<TableCell>{user.phone}</TableCell>
							<TableCell>
								{user.createdAt ? format(user.createdAt, 'd MMM yyyy') : ''}
							</TableCell>
							<TableCell>{getUserAppsCount(user._id)}</TableCell>
							<TableCell>
								<UserDialog
									userId={user._id}
									title={LL('admin.users.loginAcc')}
									onSuccess={authByUser}
								/>
								{user.status === 'new' && (
									<UserDialog
										userId={user._id}
										title={LL('admin.users.approve')}
										onSuccess={approveUser}
									/>
								)}
								<ChangeUserPasswordDialog
									userId={user._id}
									title="Сменить пароль"
								/>
								<UserDialog
									userId={user._id}
									title={LL('admin.users.block')}
									onSuccess={blockUser}
								/>
								<UserDialog
									userId={user._id}
									title={LL('common.delete')}
									onSuccess={deleteUser}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Card>
	);
};
