import React, {useState, useEffect} from 'react';
import {Meteor} from 'meteor/meteor';
import {withTracker} from 'react-meteor-data-with-tracker';
import {ISlideStreamSchedule} from 'shared/collections/SlideStream';
import {TRANSITIONS_TIME} from 'client/components/common/stream/constants';
import SlideshowSelect from './SlideshowSelect';
import {Drawer} from '@material-ui/core';
import useStyles from './useStyles';
import {publishNames} from 'shared/constants/publishNames';
import {INewEvent} from '../types';
import Controls from '../Controls';
import RRule from 'rrule'; // rrule – dependency from @fullcalendar/rrule
import RecurrenceSettings, {NEVER_REPEAT_ID} from './RecurrenceSettings';
import EventStartEndDates from './EventStartEndDates';
import moment from 'moment-timezone';
import {methodNames} from 'shared/constants/methodNames';
import FastActions from './FastActions';

interface IEventEditorProps {
	isOpened: boolean;
	slideStreamId: string;
	onClose: () => void;
	event: ISlideStreamSchedule | (INewEvent & Partial<Pick<ISlideStreamSchedule, 'rRule'>>) | null;
	loading: boolean;
	currentTimezone: string;
}

const EventEditor: React.FC<IEventEditorProps> = ({
	isOpened,
	slideStreamId,
	onClose,
	event,
	loading,
	currentTimezone,
}) => {
	const isNewEvent = !!event && !('id' in event);

	const [slideshowNumId, setSlideshowNumId] = useState(
		event && 'slideshowNumId' in event ? event.slideshowNumId : undefined,
	);
	const [eventDates, setEventDates] = useState({
		startDate: event?.startDate || new Date(),
		endDate: event?.endDate || new Date(),
		allDay: !!event?.allDay,
		date: event?.date,
	});
	const [rRuleOptions, setRRuleOptions] = useState(RRule.parseString(event?.rRule || ''));

	useEffect(() => {
		if (isOpened && event) {
			setSlideshowNumId('slideshowNumId' in event ? event.slideshowNumId : undefined);
			setEventDates({
				startDate: event.startDate,
				endDate: event.endDate,
				allDay: !!event.allDay,
				date: event.date,
			});
			setRRuleOptions(RRule.parseString(event?.rRule || ''));
		}
	}, [isOpened]);

	const classes = useStyles();

	const deleteEvent = () => {
		if (!slideStreamId || !event || !('id' in event)) return;

		Meteor.call(
			methodNames.slideStream.removeSchedule,
			{
				slideStreamId,
				id: event.id,
			},
			onClose,
		);
	};

	const saveEvent = (forceNew = false) => {
		if (moment(eventDates.endDate).isBefore(moment(eventDates.startDate))) return;
		if (!slideStreamId || !slideshowNumId) return;

		const methodName =
			event && 'id' in event && event.id && !forceNew
				? methodNames.slideStream.updateSchedule
				: methodNames.slideStream.createSchedule;
		const data =
			event && 'id' in event && event.id && !forceNew
				? {
						...event,
						slideStreamId,
						slideshowNumId,
						...eventDates,
						rRule: RRule.optionsToString(rRuleOptions),
				  }
				: {
						slideStreamId,
						slideshowNumId,
						...eventDates,
						rRule: RRule.optionsToString(rRuleOptions),
				  };

		// Since we work with native Date object we need take into account that it's in local timezone
		// So we need to make offset correction before saving event
		const localTimezoneOffset = new Date().getTimezoneOffset();
		const currentTimezoneOffset = moment.tz
			.zone(currentTimezone)
			.utcOffset(moment.tz(currentTimezone));
		const timezoneOffsetDiff = currentTimezoneOffset - localTimezoneOffset;

		data.startDate = moment(data.startDate)
			.add(timezoneOffsetDiff, 'minutes')
			.toDate();
		data.endDate = moment(data.endDate)
			.add(timezoneOffsetDiff, 'minutes')
			.toDate();

		Meteor.call(methodName, data, onClose);
	};

	return (
		<Drawer
			open={isOpened}
			disableScrollLock={true}
			transitionDuration={TRANSITIONS_TIME}
			onClose={onClose}
			PaperProps={{className: classes.paperRoot}}
		>
			{loading || !event ? (
				<div className={classes.root}>Loading...</div>
			) : (
				<div className={classes.root}>
					<SlideshowSelect slideshowNumId={slideshowNumId} onChange={setSlideshowNumId} />

					<EventStartEndDates
						startDate={eventDates.startDate}
						endDate={eventDates.endDate}
						allDay={eventDates.allDay}
						date={eventDates.date}
						onChange={setEventDates}
					/>

					<RecurrenceSettings
						frequency={rRuleOptions.freq}
						amountOfRepeats={rRuleOptions.count}
						repeatUntil={rRuleOptions.until}
						byweekday={rRuleOptions.byweekday}
						onChange={(settings) => {
							if (settings.frequency === NEVER_REPEAT_ID) {
								setRRuleOptions({});
								return;
							}

							setRRuleOptions(
								RRule.parseString(
									RRule.optionsToString({
										freq: settings.frequency,
										byweekday: settings.byweekday?.length
											? settings.byweekday
											: undefined,
										count: settings.amountOfRepeats,
										until: settings.repeatUntil,
									}),
								),
							);
						}}
					/>

					<FastActions
						onDuplicate={() => saveEvent(true)}
						onRepeatAllWeak={() => {
							setRRuleOptions(
								RRule.parseString(
									RRule.optionsToString({
										freq: RRule.DAILY,
										until: moment()
											.endOf('week')
											.endOf('day')
											.toDate(),
									}),
								),
							);
						}}
					/>

					<Controls
						isNew={isNewEvent}
						onDelete={deleteEvent}
						onCancel={onClose}
						onSave={() => saveEvent()}
					/>
				</div>
			)}
		</Drawer>
	);
};

export default withTracker(() => ({
	loading: !Meteor.subscribe(publishNames.slideshow.myList).ready(),
}))(EventEditor);
