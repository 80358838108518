exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".avatarVK--1UuYa {\r\n\twidth: 80px !important;\r\n\theight: 80px !important;\r\n\tcursor: pointer;\r\n}\r\n\r\n.description--1W9s_ {\r\n\tmargin-bottom: 15px;\r\n\tmargin-top: 15px !important;\r\n\tcursor: pointer;\r\n}\r\n\r\n.pointer--2rF3c {\r\n\tcursor: pointer;\r\n\tborder: 1px solid transparent;\r\n}\r\n\r\n.feedItem--_zUHP {\r\n\tmargin-top: 10px !important;\r\n\tpadding: 16px;\r\n\tbackground-color: white;\r\n\tborder-bottom: 2px solid #ccc;\r\n}\r\n\r\n.title--20qUc {\r\n\tborder-bottom: 1px solid #ccc;\r\n\tpadding-bottom: 10px;\r\n}\r\n\r\n.itemFeed--3CDX5 {\r\n\tpadding: 20px 0 10px;\r\n\tborder-bottom: 1px solid #ccc;\r\n}\r\n\r\n.image--5NbkR {\r\n\twidth: 100%;\r\n}\r\n", ""]);

// Exports
exports.locals = {
	"avatarVK": "avatarVK--1UuYa",
	"description": "description--1W9s_",
	"pointer": "pointer--2rF3c",
	"feedItem": "feedItem--_zUHP",
	"title": "title--20qUc",
	"itemFeed": "itemFeed--3CDX5",
	"image": "image--5NbkR"
};