import React, {useState, useEffect} from 'react';
import MomentUtils from '@date-io/moment';
import {Box} from '@material-ui/core';
import {MuiPickersUtilsProvider, DatePicker, KeyboardTimePicker} from '@material-ui/pickers';
import TextInputContentWidth from './TextInputContentWidth';
import useStyles from '../useStyles';
import moment, {Moment} from 'moment';
import TextInputNaked from './TextInputNaked';

interface IDateEditorProps {
	value: Date;
	excludeTime?: boolean;
	onChange: (newValue: Date) => void;
}

const DateEditor: React.FC<IDateEditorProps> = ({value, excludeTime, onChange}) => {
	const classes = useStyles();

	const [timeValue, setTimeValue] = useState(moment(value));

	useEffect(() => setTimeValue(value), [value]);

	const dateChangeHandler = (date: Moment) => {
		const newDate = new Date(value);
		newDate.setFullYear(date.get('year'), date.get('month'), date.get('date'));
		onChange(newDate);
	};
	const timeChangeHandler = (time: Moment) => {
		setTimeValue(time);
		if (!time?.isValid()) return;

		const newDate = new Date(value);
		newDate.setHours(time.get('hours'), time.get('minutes'), time.get('seconds'));
		onChange(newDate);
	};

	return (
		<MuiPickersUtilsProvider utils={MomentUtils} locale="ru">
			<Box display="flex" alignItems="center">
				<DatePicker
					className={classes.dateEditor}
					variant="inline"
					margin="normal"
					value={value}
					onChange={dateChangeHandler}
					format="dddd, DD MMMM"
					inputVariant="outlined"
					TextFieldComponent={TextInputContentWidth}
				/>
				{!excludeTime && (
					<Box style={{flexGrow: 0}} ml={3}>
						<KeyboardTimePicker
							ampm={false}
							value={timeValue}
							TextFieldComponent={TextInputNaked}
							onChange={timeChangeHandler}
							invalidDateMessage="Неправильное время"
						/>
					</Box>
				)}
			</Box>
		</MuiPickersUtilsProvider>
	);
};

export default DateEditor;
