import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {DialogActions, FormControl, TextField} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Modal from 'client/components/common/ui/Modal';
import {EditField} from 'client/components/common/ui/EditableField';
import './SurveyFormDialog.scss';
import {isEmpty, isEqual} from 'lodash';
import {LL} from 'shared/localization';

export const SurveyFormDialog = ({quizData = {}, title, onSuccess, children}) => {
	const defaultQuestion = {
		key: Date.now(),
		text: quizData.question,
	};
	const arrAnswers = quizData.answers || ['', ''];
	const defaultAnswers = arrAnswers.map((answer, index) => ({
		text: answer,
		key: answer + index,
	}));

	const defaultIsCustomAnswer = quizData?.customAnswer ? true : false;
	const defaultIsActive = quizData?.isActive || false;
	const defaultSendPulseVariable = quizData?.sendPulseVariable || '';
	const defaultUnisenderVariable = quizData?.unisenderVariable || '';
	const defaultType = quizData?.type || 'checkbox';

	const [errors, setErrors] = useState({});
	const [question, setQuestion] = useState(defaultQuestion);
	const [answers, setAnswers] = useState(defaultAnswers);
	const [sendPulseVariable, setSendPulseVariable] = useState(defaultSendPulseVariable);
	const [unisenderVariable, setUnisenderVariable] = useState(defaultUnisenderVariable);
	const [isCustomAnswer, setIsCustomAnswer] = useState(defaultIsCustomAnswer);
	const [sendPulseCheckbox, setSendPulseCheckbox] = useState(!!defaultSendPulseVariable);
	const [unisenderCheckbox, setUnisenderCheckbox] = useState(!!defaultUnisenderVariable);
	const [isActive, setIsActive] = useState(defaultIsActive);
	const [type, setType] = useState(defaultType);
	const [isOpen, setIsOpen] = useState(false);
	const [firstTouch, setFirstTouch] = useState(true);

	let customAnswer = {
		label: LL('admin.surveys.yourOption'),
		errorText: LL('admin.surveys.valueRequired'),
		helperText: LL('admin.surveys.enterValue'),
	};

	const removeAnswer = (key) => {
		const field = answers.find((value) => value.key === key);
		const index = answers.indexOf(field);
		if (index > -1) {
			answers.splice(index, 1);
			setAnswers([...answers]);
		}
	};

	const setAnswer = (newAnswer) => {
		const field = answers.find((value) => value.key === newAnswer.key);
		field.text = newAnswer.text;
		setAnswers([...answers]);
	};

	const isValid = () => {
		const errors = {};
		const quiz = buildQuiz();
		if (isEmpty(question.text)) {
			errors.question = LL('admin.surveys.needToAsk');
		}
		if (sendPulseCheckbox && !sendPulseVariable) {
			errors.sendPulseVariable = LL('admin.surveys.needToAsk');
		}
		if (unisenderCheckbox && !unisenderVariable) {
			errors.unisenderVariable = '* Нужно задать переменную.';
		}
		const filledAnswers = answers.map((answer) => !isEmpty(answer.text)).filter(Boolean);
		if (filledAnswers.length < 2) {
			errors.answers = LL('admin.surveys.completeAtList');
		}
		const unfilledAnswers = answers.map((answer) => isEmpty(answer.text)).filter(Boolean);
		if (!isEmpty(unfilledAnswers)) {
			errors.unfilledAnswers = LL('admin.surveys.fillInAll');
		}
		if (isEqual({...quizData}, quiz)) {
			/*
				No need to show this error, it's just disable submit button.
			*/
			errors.compare = LL('admin.news.errorsCompare');
		}
		setErrors(errors);
		return isEmpty(errors);
	};

	const closeForm = () => {
		setIsOpen(false);
		setFirstTouch(true);
	};

	const flushForm = () => {
		setQuestion(defaultQuestion);
		setAnswers(defaultAnswers);
		setIsCustomAnswer(false);
		setIsActive(false);
		setSendPulseVariable('');
		setUnisenderVariable('');
		setSendPulseCheckbox(false);
		setUnisenderCheckbox(false);
		setType('checkbox');
	};

	useEffect(() => {
		if (!isEmpty(quizData)) {
			setQuestion(defaultQuestion);
			setAnswers(defaultAnswers);
			setIsCustomAnswer(defaultIsCustomAnswer);
			setIsActive(defaultIsActive);
			setSendPulseVariable(defaultSendPulseVariable);
			setSendPulseCheckbox(!!defaultSendPulseVariable);
			setUnisenderVariable(defaultUnisenderVariable);
			setUnisenderCheckbox(!!defaultUnisenderVariable);
			setType(defaultType);
		}
	}, [quizData]);

	useEffect(() => {
		isValid();
	}, [
		question,
		answers,
		isCustomAnswer,
		isActive,
		sendPulseVariable,
		sendPulseCheckbox,
		unisenderVariable,
		unisenderCheckbox,
		type,
	]);

	const buildQuiz = () => {
		let tmp = sendPulseVariable;
		let tmp1 = unisenderVariable;
		!isCustomAnswer && (customAnswer = null);
		!sendPulseCheckbox && (tmp = null);
		!unisenderCheckbox && (tmp1 = null);
		return {
			...quizData,
			customAnswer,
			type,
			question: question.text,
			answers: answers.map((answer) => answer.text).filter(Boolean),
			sendPulseVariable: tmp,
			unisenderVariable: tmp1,
			isActive,
		};
	};

	const submitForm = () => {
		const quiz = buildQuiz();
		onSuccess(quiz);
		closeForm();
		flushForm();
	};

	return (
		<>
			<div onClick={() => setIsOpen(true)}>{children}</div>
			<Modal
				title={title || LL('admin.surveys.newPoll')}
				isOpen={isOpen}
				onClose={() => closeForm()}
			>
				<FormControl fullWidth={true} onClick={() => setFirstTouch(false)}>
					<Paper className="questionPaper">
						<EditField
							placeholder={LL('admin.surveys.askQuestion')}
							label={LL('admin.surveys.yourQuestion')}
							field={question}
							setField={setQuestion}
							required={true}
						/>
						{answers.map((answer, i) => (
							<Paper className="answerPaper" key={answer.key}>
								<EditField
									placeholder={`${LL('admin.surveys.answerOption')} ${i + 1}...`}
									label={LL('admin.surveys.yourAnswer')}
									field={answer}
									removeField={removeAnswer}
									setField={setAnswer}
									required={true}
								/>
							</Paper>
						))}
						{!firstTouch && errors.answers && (
							<p className="errorText ml-20">{errors.answers}</p>
						)}
						{!firstTouch && !errors.answers && errors.unfilledAnswers && (
							<p className="errorText ml-20">{errors.unfilledAnswers}</p>
						)}
						<Button
							color="primary"
							variant="contained"
							className="addAnswerButton"
							onClick={() => setAnswers([...answers, {key: Date.now()}])}
						>
							{LL('admin.surveys.addAnswer')}
						</Button>
						<FormControlLabel
							className="customAnswer"
							control={
								<Checkbox
									name="isCustomAnswer"
									checked={isCustomAnswer}
									onChange={() => setIsCustomAnswer(!isCustomAnswer)}
								/>
							}
							label={LL('admin.surveys.allowToAnswer')}
						/>
						<br />
						<FormControlLabel
							className="customAnswer mt-0"
							control={
								<Checkbox
									name="type"
									checked={type !== 'checkbox'}
									onChange={() =>
										setType(type === 'checkbox' ? 'radio' : 'checkbox')
									}
								/>
							}
							label={LL('admin.surveys.allowOneAnswer')}
						/>
						<br />
						<FormControlLabel
							className="customAnswer mt-0"
							control={
								<Checkbox
									name="isSendPulseIntegration"
									checked={sendPulseCheckbox}
									onChange={() => setSendPulseCheckbox(!sendPulseCheckbox)}
								/>
							}
							label={LL('admin.surveys.integration')}
						/>
						<br />
						{sendPulseCheckbox && (
							<>
								<TextField
									label={LL('admin.surveys.setVariable')}
									variant="outlined"
									autoFocus={true}
									onChange={(e) => {
										setSendPulseVariable(e.target.value);
									}}
									value={sendPulseVariable}
									fullWidth={true}
									multiline
									className="sendpulse-variable"
									placeholder={LL('admin.surveys.essenceSurvey')}
								/>
								{!firstTouch && errors.sendPulseVariable && (
									<p className="errorText ml-20">{errors.sendPulseVariable}</p>
								)}
								<br />
							</>
						)}
						<FormControlLabel
							className="customAnswer mt-0"
							control={
								<Checkbox
									name="isUnisenderIntegration"
									checked={unisenderCheckbox}
									onChange={() => setUnisenderCheckbox(!unisenderCheckbox)}
								/>
							}
							label="Интеграция с Unisender"
						/>
						<br />
						{unisenderCheckbox && (
							<>
								<TextField
									label="Задайте переменную Unisender"
									variant="outlined"
									autoFocus={true}
									onChange={(e) => {
										setUnisenderVariable(e.target.value);
									}}
									value={unisenderVariable}
									fullWidth={true}
									multiline
									className="unisender-variable"
									placeholder="Название переменной Unisender (суть опроса)"
								/>
								{!firstTouch && errors.unisenderVariable && (
									<p className="errorText ml-20">{errors.unisenderVariable}</p>
								)}
								<br />
							</>
						)}
						<FormControlLabel
							className="customAnswer mt-0"
							control={
								<Checkbox
									name="isActive"
									checked={isActive}
									onChange={() => setIsActive(!isActive)}
								/>
							}
							label={LL('admin.surveys.activateSurvey')}
						/>
					</Paper>
					<DialogActions>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={!isEmpty(errors)}
							onClick={() => submitForm()}
						>
							{LL('common.save')}
						</Button>
					</DialogActions>
				</FormControl>
			</Modal>
		</>
	);
};
