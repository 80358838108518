import React from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from 'client/components/common/stream/StreamScheduler/EventEditor/useStyles';
import {Button} from '@material-ui/core';
import {LL} from 'shared/localization';

interface IControlsProps {
	isNew: boolean;
	onDelete: () => void;
	onCancel: () => void;
	onSave: () => void;
}

const Controls: React.FC<IControlsProps> = ({isNew, onDelete, onCancel, onSave}) => {
	const classes = useStyles();

	return (
		<Grid container alignItems="center" justifyContent="flex-end">
			{!isNew && (
				<Button className={classes.deleteButton} onClick={onDelete} variant="outlined">
					{LL('common.delete')}
				</Button>
			)}

			<Button className={classes.cancelButton} onClick={onCancel}>
				{LL('common.cancel')}
			</Button>
			<Button className={classes.saveButton} onClick={onSave} variant="contained">
				{LL('common.save')}
			</Button>
		</Grid>
	);
};

export default Controls;
