import React from 'react';
import {withTracker} from 'react-meteor-data-with-tracker';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {Quiz, QuizResponse} from 'shared/collections/Quizzes';
import {publishNames} from 'shared/constants/publishNames';
import {UsersTable} from 'client/components/admin/users/UsersTable';

import {SearchField} from 'client/components/common/ui/SearchField';
import {ChartCanvas} from 'client/components/common/ui/ChartCanvas';
import TabPanel from 'client/components/common/ui/TabPanel';
import {countItems, getTabData} from './helpers';
import './SurveysStatisticPage.scss';
import {LL} from 'shared/localization';

const SurveysStatisticPage = ({
	loading,
	question,
	quizResponses,
	users,
	search,
	match,
	history,
}) => {
	if (loading) return <CircularProgress />;
	if (quizResponses && !quizResponses.length) {
		return (
			<div className="chart-container">
				<Typography variant="h6" className="alert alert-warning">
					{LL('admin.surveys.noInformation')}
				</Typography>
			</div>
		);
	}
	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const a11yProps = (index) => ({
		id: `wrapped-tab-${index}`,
		'aria-controls': `wrapped-tabpanel-${index}`,
	});

	const allAnswers = quizResponses?.map((response) => response.answers).flat();
	const chartData = countItems(allAnswers);
	const tabData = getTabData(quizResponses);

	return (
		<div>
			<div className="stat-container p-24">
				<Typography variant="h5" className="pageTitle">
					{LL('admin.surveys.surveyResults')}
				</Typography>
				<ChartCanvas chartData={chartData} caption={question} />
			</div>
			<div className="stat-container">
				<SearchField
					caption={LL('admin.surveys.usersCompletedSurvey')}
					search={search}
					history={history}
					match={match}
				/>
				<AppBar position="static" color="default" className="users-container">
					<Tabs
						value={value}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						className="user-tabs"
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						{tabData.map((tab, index) => (
							<Tab
								key={tab.answer + index}
								value={index}
								label={tab.answer}
								wrapped
								{...a11yProps(index)}
							/>
						))}
					</Tabs>
				</AppBar>
				{tabData.map((tab, index) => (
					<TabPanel value={value} index={index} key={'panel-' + tab.answer + index}>
						<UsersTable
							loading={loading}
							users={users.filter((user) => tab.userIds.includes(user._id))}
						/>
					</TabPanel>
				))}
			</div>
		</div>
	);
};

export default withTracker((props) => {
	const query = new URLSearchParams(props.location.search);
	const search = query.get('search');

	const quizId = props.match.params.id;
	const {question} = props.match.params;

	const quizSub = Meteor.subscribe(publishNames.quiz.byQuizId, {quizId}).ready();
	const quizResponsesSub = Meteor.subscribe(publishNames.quizResponse.byQuizId, {quizId}).ready();

	let usersSub, users;
	if (quizSub) {
		const quiz = Quiz.findOne({});
		const {userIds} = quiz || {};
		usersSub = Meteor.subscribe(publishNames.user.userList, {search, userIds}).ready();
		users = Meteor.users.find({_id: {$in: userIds}}, {sort: {_id: 1}}).fetch();
	}

	const loading = !quizResponsesSub && !usersSub && !quizSub;
	return {
		search,
		loading,
		question,
		match: props.match,
		history: props.history,
		quizResponses: QuizResponse.find({}).fetch(),
		users,
	};
})(SurveysStatisticPage);
