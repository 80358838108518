import React from 'react';

import {Button, Popover, ClickAwayListener} from '@material-ui/core';
import css from '../formFields/EditorColor.pcss';
import css2 from '../formFields/EditorActionButtons.pcss';
import EditorFieldLabel from 'client/components/editor/formFields/EditorFieldLabel';
import EditorTextarea from 'client/components/editor/formFields/EditorTextarea';

interface IEditorColorState {
	isOpen: boolean;
	anchorEl: boolean;
}
interface IEditorColorProps {
	label: string;
	value: string;
	tooltip: string;
	element: any;
	onChange: any;
}

class TextAreaField extends React.PureComponent<IEditorColorProps, IEditorColorState> {
	state = {isOpen: false, anchorEl: null};

	handleClose = () => {
		this.setState({isOpen: false, anchorEl: null});
	};

	handleOpen = (event) => {
		this.setState({isOpen: true, anchorEl: event.currentTarget});
	};

	render() {
		const {label, value, onChange, tooltip, helpText} = this.props;
		const {isOpen, anchorEl} = this.state;
		const id = isOpen ? 'simple-popover' : undefined;
		return (
			<div className={css.inputOuter}>
				<EditorFieldLabel tooltip={tooltip}>{label}</EditorFieldLabel>
				<div className={css2.buttons}>
					<EditorTextarea
						value={value}
						aria-describedby={id}
						onClick={this.handleOpen}
						onChange={onChange}
						helpText={helpText}
						maxRows={5}
					/>
				</div>

				<Popover
					id={id}
					anchorEl={anchorEl}
					open={isOpen}
					calsses={{root: 'MuiTooltip-root'}}
				>
					<ClickAwayListener
						mouseEvent="onMouseDown"
						touchEvent="onTouchStart"
						onClickAway={this.handleClose}
					>
						<div
							style={{
								padding: 12,
								paddingRight: 22,
								backgroundColor: '#333',
							}}
						>
							<EditorTextarea
								style={{
									width: '100%',
									minWidth: 300,
									maxWidth: 1000,
									resize: 'both',
								}}
								rows={9}
								value={value}
								onChange={onChange}
								ref={this.popRef}
								autoFocus
							/>
							<div className={css2.buttons}>
								<Button className={css2.button} onClick={this.handleClose}>
									Закрыть
								</Button>
							</div>
						</div>
					</ClickAwayListener>
				</Popover>
			</div>
		);
	}
}

export default TextAreaField;
