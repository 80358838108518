import * as React from 'react';
import {withTracker} from 'react-meteor-data-with-tracker';
import {Meteor} from 'meteor/meteor';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import {TextField, IconButton} from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import {format} from 'shared/utils/dates';

import {News} from 'shared/collections/News';
import {publishNames} from 'shared/constants/publishNames';
import {methodNames} from 'shared/constants/methodNames';
import {NewsFormDialog} from './newsFormDialog';
import DialodWithElement from 'client/components/common/ui/DialodWithElement';
import './NewsPage.scss';
import {LL} from 'shared/localization';

class UsersPage extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			checkedActive: true,
		};
	}

	getBody = () => {
		const {news, loading} = this.props;

		if (loading) return <CircularProgress />;
		if (news && !news.length) {
			return (
				<Typography variant="h6" className="alert alert-warning">
					{LL('admin.news.noNews')}
				</Typography>
			);
		}

		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>#</TableCell>
						<TableCell>{LL('common.header')}</TableCell>
						<TableCell>{LL('admin.news.article')}</TableCell>
						<TableCell>{LL('common.actions')}</TableCell>
						<TableCell>{LL('admin.news.updated')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{news.map((newsItem, index) => (
						<TableRow key={newsItem._id} className="background">
							<TableCell>{index + 1}</TableCell>
							<TableCell>{newsItem.title}</TableCell>
							<TableCell>{newsItem.article?.slice(0, 50)}...</TableCell>
							<TableCell>
								<Grid container={true} wrap="nowrap">
									<NewsFormDialog
										title={LL('admin.news.editNews')}
										newsData={newsItem}
										onSuccess={(newsItem) =>
											Meteor.call(methodNames.news.update, newsItem)
										}
									>
										<IconButton
											edge="end"
											title={LL('common.edit')}
											onClick={() => this.setState({isOpen: true})}
										>
											<EditRoundedIcon style={{color: '#3f51b5'}} />
										</IconButton>
									</NewsFormDialog>
									<DialodWithElement
										title={LL('common.delete')}
										onSuccess={() =>
											Meteor.call(methodNames.news.remove, newsItem._id)
										}
									>
										<IconButton title={LL('common.delete')} edge="end">
											<DeleteForeverRoundedIcon style={{color: '#f00'}} />
										</IconButton>
									</DialodWithElement>
								</Grid>
							</TableCell>
							<TableCell>
								<div style={{whiteSpace: 'nowrap'}}>
									{newsItem.updatedAt
										? format(newsItem.updatedAt!, 'd MMM yyyy')
										: ''}
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		);
	};

	handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.history.replace({
			pathname: this.props.match.path,
			search: `?search=${e.target.value}`,
		});
	};

	render() {
		return (
			<div>
				<Grid container justify="space-between">
					<Grid item className="headerContainer">
						<Typography className="pageTitle" variant="h5">
							{LL('common.adminHeader.news')}
						</Typography>
						<Grid container justify="space-between">
							<TextField
								label={LL('common.search')}
								variant="outlined"
								onChange={this.handleFontSizeChange}
								defaultValue={this.props.search}
								className="searchField"
							/>
							<NewsFormDialog
								title={LL('admin.news.newNews')}
								onSuccess={(news) => Meteor.call(methodNames.news.create, news)}
							>
								<Button
									variant="outlined"
									color="primary"
									className="add-button"
									onClick={() => this.setState({isOpen: true})}
								>
									{<AddIcon className="buttonIcon" />}
									{LL('admin.news.addNews')}
								</Button>
							</NewsFormDialog>
						</Grid>
					</Grid>
				</Grid>

				<Card className="tableCard">{this.getBody()}</Card>
			</div>
		);
	}
}

export default withTracker((props) => {
	const query = new URLSearchParams(props.location.search);

	// When the URL is /the-path?some-key=a-value ...
	const search = query.get('search');
	const subNews = Meteor.subscribe(publishNames.news.all, {search}).ready();
	const loading = !subNews;

	return {
		search,
		loading,
		news: News.find(
			{},
			{
				sort: {
					updatedAt: -1,
				},
			},
		).fetch(),
	};
})(UsersPage);
