import {isEqual, groupBy} from 'lodash';

export const avoidDuplicates = (dataArray) => {
	return dataArray.reduce((acc, el) => {
		if (!acc.find((v) => isEqual(v, el))) {
			acc.push(el);
		}
		return acc;
	}, []);
};

export const countItems = (dataArray) => {
	return dataArray.reduce((acc, el) => {
		acc[el] = (acc[el] || 0) + 1;
		return acc;
	}, {});
};

export const getTabData = (data) => {
	const dataArray = data
		.map((response) =>
			response?.answers.map((answer) => ({
				answer,
				userId: response.userId,
			})),
		)
		.flat();

	const objectData = groupBy(dataArray, (el) => {
		const makeValue = el.answer;
		delete el.answer;
		return makeValue;
	});

	let tabData = [];
	let i = 0;
	for (const key in objectData) {
		if (Object.hasOwnProperty.call(objectData, key)) {
			tabData[i] = {
				answer: key,
				userIds: avoidDuplicates(objectData[key]).map((userId) => userId.userId),
			};
			i++;
		}
	}

	return tabData;
};
