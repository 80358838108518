import * as React from 'react';
import {Meteor} from 'meteor/meteor';
import {TextField} from 'final-form-material-ui';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {Field, Form} from 'react-final-form';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography/Typography';

import {methodNames} from 'shared/constants/methodNames';
import {requiredInput} from 'client/components/common/ui/requiredValidator';
import {LL} from 'shared/localization';

const styles = createStyles({
	title: {
		paddingTop: '15px',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
	},
	textFields: {
		margin: '10px 0',
	},
	buttons: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row-reverse',
	},
});

interface INewPasswordFormData {
	password: string;
	passwordRe: string;
}

interface IPasswordFormProps {
	classes: {
		container: string;
		title: string;
		textFields: string;
		buttons: string;
	};
	userId: string;
	handleClose?: () => void;
}

class PasswordForm extends React.PureComponent<IPasswordFormProps> {
	onSubmitHandler = (values: any) => {
		const {userId} = this.props;

		Meteor.call(methodNames.user.setPassword, userId, values.password, (error, result) => {
			if (error) {
				console.log(error.reason);
			} else {
				console.log(LL('admin.users.newPasswordSet'));
			}
		});
		if (this.props.handleClose) {
			this.props.handleClose();
		}
	};

	render() {
		const {classes} = this.props;

		return (
			<>
				<Typography
					variant="h5"
					className={classes.title}
					gutterBottom
					style={this.props.handleClose ? {textAlign: 'center'} : {}}
				>
					{LL('admin.users.toChangePass')}
				</Typography>

				<Card style={{padding: 16}}>
					<Form
						onSubmit={this.onSubmitHandler}
						validate={(values) => {
							const errors: INewPasswordFormData = {
								password: undefined,
								passwordRe: undefined,
							};
							const valuesData = values as INewPasswordFormData;

							if (valuesData.password !== valuesData.passwordRe) {
								errors.passwordRe = LL('admin.users.passwordMismatch');
							}
							if (valuesData.password && valuesData.password.length < 6) {
								errors.password = LL('admin.users.atListPass');
							}
							if (valuesData.password && valuesData.password.length > 20) {
								errors.password = LL('admin.users.noMorePass');
							}
							return errors;
						}}
						render={({handleSubmit, pristine, reset, submitting}) => (
							<form className={classes.container} onSubmit={handleSubmit}>
								<Field
									name="password"
									component={TextField}
									type="password"
									label={LL('admin.users.newPass')}
									className={classes.textFields}
									validate={requiredInput()}
									variant="outlined"
								/>

								<Field
									name="passwordRe"
									component={TextField}
									type="password"
									label={LL('admin.users.repeatPass')}
									className={classes.textFields}
									validate={requiredInput()}
									variant="outlined"
								/>

								<div className={classes.buttons}>
									<Button
										variant="outlined"
										color="primary"
										type="submit"
										disabled={submitting || pristine}
									>
										{LL('admin.users.changePass')}
									</Button>
								</div>
							</form>
						)}
					/>
				</Card>
			</>
		);
	}
}

export default withStyles(styles)(PasswordForm);
