import {ISlideStream} from 'shared/collections/SlideStream';
import RRule from 'rrule';
import {Moment} from 'moment';
import MomentFunc from 'moment-timezone';
import {extendMoment} from 'moment-range';
import {ISimpleRange, IEventRanges, IOverlapsInfo} from './types';
import {TIME_FORMAT} from './constants';
import getEventRanges from './getEventRanges';

const moment = extendMoment(MomentFunc);

export default (slideStream: ISlideStream, timezone?: string): IOverlapsInfo | null => {
	if (!slideStream || !Array.isArray(slideStream.schedules)) return null;

	const tzMoment = (d?: Date | Moment | string) => {
		if (timezone) return moment(d).tz(timezone, typeof d === 'string');
		return moment(d);
	};

	const overlaps: IOverlapsInfo = {};
	const currentAndFutureDates: IEventRanges = {};
	const now = tzMoment();

	slideStream.schedules.forEach((event) => {
		const eventDates: ISimpleRange[] = [];

		if (event.rRule) {
			const eventMStart = event.allDay ? tzMoment(event.date) : tzMoment(event.startDate);

			const dtStartFormat = event.allDay ? 'YYYYMMDD' : 'YYYYMMDDTHHmmss[Z]';
			const dtStartString = `DTSTART:${eventMStart.format(dtStartFormat)}`;
			const rRuleString = `${dtStartString}\n${event.rRule}`;
			const rRule = RRule.fromString(rRuleString);

			const eventDurationInMinutes = moment(event.endDate).diff(
				moment(event.startDate),
				'minutes',
			);

			rRule.all().forEach((rDate) => {
				const mRDate = tzMoment(rDate);
				if (mRDate.isBefore(now, 'day')) return;

				let from: Moment;
				let to: Moment;

				if (event.allDay) {
					from = tzMoment(mRDate).startOf('day');
					to = tzMoment(mRDate).endOf('day');
				} else {
					from = tzMoment(mRDate)
						.set('hour', tzMoment(event.startDate).get('hours'))
						.set('minutes', tzMoment(event.startDate).get('minutes'));
					to = from.clone().add(eventDurationInMinutes, 'minutes');
				}

				eventDates.push({from, to});
			});
		} else {
			const to = event.allDay ? tzMoment(event.date).endOf('day') : tzMoment(event.endDate);

			if (to.isBefore(now, 'day')) return;

			eventDates.push({
				to,
				from: event.allDay
					? tzMoment(event.date).startOf('day')
					: tzMoment(event.startDate),
			});
		}

		eventDates.forEach(({from, to}) => {
			const ranges = getEventRanges(from, to, event);
			Object.keys(ranges).forEach((date) => {
				currentAndFutureDates[date] = currentAndFutureDates[date] || [];
				currentAndFutureDates[date].push(...ranges[date]);
			});
		});
	});

	Object.keys(currentAndFutureDates)
		.filter((dateKey) => currentAndFutureDates[dateKey].length > 1)
		.forEach((dateKey) => {
			const events = currentAndFutureDates[dateKey];
			overlaps[dateKey] = [];

			for (let i = 0; i < events.length; i += 1) {
				for (let j = i + 1; j < events.length; j += 1) {
					const {event: iEvent, range: iRange} = events[i];
					const {event: jEvent, range: jRange} = events[j];

					if (iEvent.allDay && jEvent.allDay) {
						overlaps[dateKey].push({
							from: '00:00',
							to: '23:59',
							events: [iEvent, jEvent],
						});
						continue;
					}

					if (iEvent.allDay) {
						overlaps[dateKey].push({
							from: tzMoment(jRange.start).format(TIME_FORMAT),
							to: tzMoment(jRange.end).format(TIME_FORMAT),
							events: [iEvent, jEvent],
						});
						continue;
					}

					if (jEvent.allDay) {
						overlaps[dateKey].push({
							from: tzMoment(iRange.start).format(TIME_FORMAT),
							to: tzMoment(iRange.end).format(TIME_FORMAT),
							events: [iEvent, jEvent],
						});
						continue;
					}

					const intersect = iRange.intersect(jRange);
					if (intersect && !intersect.start.isSame(intersect.end, 'minute')) {
						overlaps[dateKey].push({
							from: tzMoment(intersect.start).format(TIME_FORMAT),
							to: tzMoment(intersect.end).format(TIME_FORMAT),
							events: [iEvent, jEvent],
						});
					}
				}
			}

			if (!overlaps[dateKey].length) delete overlaps[dateKey];
		});

	return Object.keys(overlaps).length ? overlaps : null;
};
