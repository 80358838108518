import * as React from 'react';
import {connect} from 'react-redux';

import {updateSlideElement} from 'client/actions/slideShowEditor';
import {ISlideElement} from 'shared/collections/SlideElements';
import EditorActionButtons from 'client/components/editor/formFields/EditorActionButtons';
import Tooltip from 'client/components/common/ui/Tooltip';
import Button from '@material-ui/core/Button';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatStrikethroughIcon from '@material-ui/icons/FormatStrikethrough';

interface IFontSizeFieldProps {
	element: ISlideElement;
	updateSlideElement: typeof updateSlideElement;
	name: string;
	text: string;
}

class FontStyleField extends React.Component<IFontSizeFieldProps> {
	toggleFormat = (value?: string) => {
		const {element, name} = this.props;

		let stylesArr = [...element[name || 'fontStyle']];
		let index = stylesArr.indexOf(value);
		if (index === -1) {
			stylesArr.push(value);
		} else {
			stylesArr.splice(index, 1);
		}

		this.props.updateSlideElement(element, {
			[name || 'fontStyle']: stylesArr,
		});
	};

	render() {
		const {element, text, name} = this.props;

		let stylesArr = element[name || 'fontStyle'] || [];

		return (
			<div style={{width: 150}}>
				<EditorActionButtons tooltip="Форматирование текста">
					<Tooltip selected={stylesArr.indexOf('bold') !== -1} title="Жирный">
						<Button onClick={() => this.toggleFormat('bold')}>
							<FormatBoldIcon />
						</Button>
					</Tooltip>

					<Tooltip selected={stylesArr.indexOf('italic') !== -1} title="Курсив">
						<Button onClick={() => this.toggleFormat('italic')}>
							<FormatItalicIcon />
						</Button>
					</Tooltip>

					<Tooltip selected={stylesArr.indexOf('underline') !== -1} title="Подчеркнутый">
						<Button onClick={() => this.toggleFormat('underline')}>
							<FormatUnderlinedIcon />
						</Button>
					</Tooltip>

					<Tooltip
						selected={stylesArr.indexOf('line-through') !== -1}
						title="Зачеркнутый"
					>
						<Button onClick={() => this.toggleFormat('line-through')}>
							<FormatStrikethroughIcon />
						</Button>
					</Tooltip>
				</EditorActionButtons>
			</div>
		);
	}
}

export default connect(null, {
	updateSlideElement,
})(FontStyleField);
