exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".toggleBox--XWVIC {\n    position: relative;\n}\n    .toggleBox--XWVIC .toggleBoxInput--m_9mr {\n        pointer-events: none;\n        opacity: 0;\n        position: absolute;\n        top: 0;\n        left: 0;\n    }\n    .toggleBox--XWVIC .toggleBoxLabel--gzyJ_ {\n        display: -webkit-inline-box;\n        display: -webkit-inline-flex;\n        display: -moz-inline-box;\n        display: inline-flex;\n        -webkit-box-align: center;\n        -webkit-align-items: center;\n           -moz-box-align: center;\n                align-items: center;\n        background-color: #ffffff;\n        color: #555555;\n        cursor: pointer;\n        margin: 0;\n        padding: 6px 11px;\n    }\n    .toggleBox--XWVIC .toggleBoxIcon--2bgDX {\n        margin-left: 6px;\n    }\n    .toggleBox--XWVIC .toggleBoxInput--m_9mr:checked ~ .toggleBoxLabel--gzyJ_ {\n        color: #ffffff;\n        background-color: transparent;\n    }\n    .toggleBox--XWVIC .toggleBoxInput--m_9mr:disabled ~ .toggleBoxLabel--gzyJ_ {\n        cursor: not-allowed;\n    }", ""]);

// Exports
exports.locals = {
	"toggleBox": "toggleBox--XWVIC",
	"toggleBoxInput": "toggleBoxInput--m_9mr",
	"toggleBoxLabel": "toggleBoxLabel--gzyJ_",
	"toggleBoxIcon": "toggleBoxIcon--2bgDX"
};