import {ISlideStreamSchedule} from 'shared/collections/SlideStream';
import {Slideshow} from 'shared/collections/Slideshows';
import {rainbow} from 'client/components/common/stream/StreamScheduler/helpers';
import moment from 'moment-timezone';
import {Moment} from 'moment';

interface IEventSlideshowInfo {
	slideshowName: string;
	color: string;
}

export default (slideStreamSchedule: ISlideStreamSchedule[], currentTimeZone: string) => {
	const slideshows: Record<string, IEventSlideshowInfo> = {};

	const tzMoment = (d?: Date | Moment | string) => {
		if (currentTimeZone) return moment(d).tz(currentTimeZone, typeof d === 'string');
		return moment(d);
	};

	slideStreamSchedule.forEach((event, ind) => {
		if (slideshows[event.slideshowNumId]) return;
		slideshows[event.slideshowNumId] = {
			slideshowName: Slideshow.findOne({numId: event.slideshowNumId})?.name,
			color: rainbow(slideStreamSchedule.length + 1, ind + 1),
		};
	});

	return slideStreamSchedule.map((event) => {
		const preparedEvent = {
			id: event.id,
			title: slideshows[event.slideshowNumId].slideshowName,
			color: slideshows[event.slideshowNumId].color,
			extendedProps: {rawEvent: event},
			allDay: !!event.allDay,
		};

		if (event.rRule) {
			const dtStart = event.allDay
				? event.date?.replace(/-/g, '')
				: tzMoment(event.startDate).format('YYYYMMDDTHHmmss[Z]');

			return {
				...preparedEvent,
				groupId: event.id,
				duration: event.allDay
					? undefined
					: moment(event.endDate).diff(moment(event.startDate), 'milliseconds'),
				rrule: `DTSTART:${dtStart}\n${event.rRule}`,
			};
		}

		const start = event.allDay
			? tzMoment(event.date)
					.startOf('day')
					.format('YYYY-MM-DD HH:mm:ss')
			: tzMoment(event.startDate).format('YYYY-MM-DD HH:mm:ss');
		const end = event.allDay
			? tzMoment(event.date)
					.endOf('day')
					.format('YYYY-MM-DD HH:mm:ss')
			: tzMoment(event.endDate).format('YYYY-MM-DD HH:mm:ss');

		return {
			...preparedEvent,
			start,
			end,
		};
	});
};
