import React from 'react';
import {SketchPicker, ColorChangeHandler} from 'react-color';

import {Button, Popover, Grid, ClickAwayListener} from '@material-ui/core';
import css from '../formFields/EditorColor.pcss';
import css2 from '../formFields/EditorActionButtons.pcss';
import EditorFieldLabel from 'client/components/editor/formFields/EditorFieldLabel';
import FontSizeField from './FontSizeField';
import FontField from './FontField';
import FontStyleField from 'client/components/editor/settingsFields/FontStyleField';

interface IEditorColorState {
	isOpen: boolean;
	anchorEl: boolean;
}
interface IEditorColorProps {
	label: string;
	value: string;
	tooltip: string;
	element: any;
}

class TextUnitField extends React.PureComponent<IEditorColorProps, IEditorColorState> {
	state = {isOpen: false, anchorEl: null};

	handleClick = () => {
		this.setState({isOpenPicker: !this.state.isOpenPicker});
	};

	handleClose = () => {
		this.setState({isOpen: false, anchorEl: null});
	};

	handleOpen = (event) => {
		this.setState({isOpen: true, anchorEl: event.currentTarget});
	};

	handleChange: ColorChangeHandler = (color) => {
		const {onChange, prefix} = this.props;
		const {r, g, b, a} = color.rgb;

		// this.props.getHexColor(this.props.name, color.hex);

		if (onChange) {
			onChange(`rgba(${r}, ${g}, ${b}, ${a})`, prefix);
		}
		// this.handleClose();
	};

	handleReset = () => {
		const {onChange, resetValue} = this.props;

		if (onChange) {
			onChange(resetValue || '');
		}
		this.handleClose();
	};

	render() {
		const {label, prefix, tooltip, element} = this.props;
		const {isOpen, anchorEl} = this.state;
		const id = isOpen ? 'simple-popover' : undefined;
		return (
			<div className={css.inputOuter}>
				<EditorFieldLabel tooltip={tooltip}>{label}</EditorFieldLabel>
				<div className={css2.buttons}>
					<Button className={css2.button} aria-describedby={id} onClick={this.handleOpen}>
						Настроить
					</Button>
				</div>

				<Popover
					id={id}
					anchorEl={anchorEl}
					open={isOpen}
					calsses={{root: 'MuiTooltip-root'}}
				>
					<ClickAwayListener
						mouseEvent="onMouseDown"
						touchEvent="onTouchStart"
						onClickAway={this.handleClose}
					>
						<div
							style={{
								width: 374,
								padding: 12,
								paddingRight: 22,
								backgroundColor: '#333',
							}}
						>
							<Grid container spacing={3}>
								<Grid item xs>
									<SketchPicker
										width="145px"
										color={
											prefix
												? element[prefix + 'FontColor']
												: element.textColor
										}
										onChange={this.handleChange}
									/>
								</Grid>
								<Grid item xs>
									<FontField
										element={element}
										name={prefix ? `${prefix}FontFamily` : null}
									/>

									<FontSizeField
										element={element}
										name={prefix ? `${prefix}FontSize` : null}
									/>
									<FontStyleField
										element={element}
										name={prefix ? `${prefix}FontStyle` : null}
									/>
									<div className={css2.buttons}>
										<Button className={css2.button} onClick={this.handleClose}>
											Закрыть
										</Button>
									</div>
								</Grid>
							</Grid>
						</div>
					</ClickAwayListener>
				</Popover>
			</div>
		);
	}
}

export default TextUnitField;
