import React, {MouseEvent, useEffect, useState} from 'react';
import {getCurrentLocale, Locales} from 'shared/localization/index';
import {useHistory} from 'react-router-dom';
import {RuFlag} from 'client/components/widgets/icons/RuFlag';
import {IconButton, ListItemIcon, ListItemText, MenuItem, Popover} from '@material-ui/core';
import {UkFlag} from 'client/components/widgets/icons/UkFlag';

export const LangSwitcher = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null | undefined>(null);
	const [selectLocale, setSelectedLocale] = useState<Locales>(getCurrentLocale());
	const open = Boolean(anchorEl);
	const {
		location: {pathname},
		push,
	} = useHistory();

	useEffect(() => {
		push(pathname);
	}, [selectLocale]);

	const locales = [
		{
			id: 'ru',
			flag: <RuFlag />,
			value: (
				<>
					<ListItemIcon>
						<RuFlag />
					</ListItemIcon>
					<ListItemText primary={'Русский'} />
				</>
			),
		},
		// {
		// 	id: 'en',
		// 	flag: <UkFlag />,
		// 	value: (
		// 		<>
		// 			<ListItemIcon>
		// 				<UkFlag />
		// 			</ListItemIcon>
		// 			<ListItemText primary={'English'} />
		// 		</>
		// 	),
		// },
	];

	const handleClickListItem = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (id: Locales) => {
		setSelectedLocale(id);
		setAnchorEl(null);
		localStorage.setItem('locale', id);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const currenLocale = locales.filter((o) => o.id === selectLocale)[0].flag;

	return (
		<>
			<IconButton
				aria-controls={'menu-lang'}
				aria-haspopup="true"
				onClick={handleClickListItem}
			>
				{currenLocale}
			</IconButton>
			<Popover
				id={'menu-lang'}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
				transformOrigin={{horizontal: 'right', vertical: 'top'}}
			>
				{locales.map((option) => (
					<MenuItem
						key={option.id}
						selected={option.id === selectLocale}
						onClick={() => handleMenuItemClick(option.id as Locales)}
					>
						{option.value}
					</MenuItem>
				))}
			</Popover>
		</>
	);
};
