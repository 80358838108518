import {Meteor} from 'meteor/meteor';
import {useTracker} from './useTracker';
import {useState} from 'react';

export function useSubscription(pubName: string, ...subOpts: any[]): [boolean, boolean] {
	const [hasError, setError] = useState(false);
	const loading = useTracker(() => {
		setError(false);
		const s = Meteor.subscribe(pubName, ...subOpts, {
			onStop(error?: Meteor.Error) {
				if (error) {
					setError(true);
				}
			},
		});

		return !s.ready();
	}, [pubName, ...subOpts]);

	return [loading, hasError];
}
