import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {TextField} from '@material-ui/core';

import './SearchField.scss';
import {LL} from 'shared/localization';

export const SearchField = ({caption, search, match, history}) => {
	const handleFontSizeChange = (e) => {
		history.replace({
			pathname: match.url,
			search: `?search=${e.target.value}`,
		});
	};

	return (
		<Grid container justify="space-between">
			<Grid item className="headerContainer">
				<Typography className="pageTitle" variant="h5">
					{caption || LL('common.adminHeader.users')}
				</Typography>
				<TextField
					label={LL('common.search')}
					variant="outlined"
					onChange={handleFontSizeChange}
					defaultValue={search}
					className="searchField"
				/>
			</Grid>
		</Grid>
	);
};
