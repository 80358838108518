import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Typography from '@material-ui/core/Typography/Typography';

import StaticPage from 'client/components/pages/StaticPage';

const PrivacyPolicy: React.FunctionComponent<RouteComponentProps> = () => (
	<StaticPage title="Политика конфиденциальности в отношении обработки персональных данных">
		<Typography gutterBottom align="right">
			Дата публикации: «21» ноября 2019 г.
		</Typography>

		<Typography variant="h6" gutterBottom align="center">
			1. Общие положения
		</Typography>

		<Typography gutterBottom>
			1.1. Настоящая Политика конфиденциальности в отношении обработки персональных данных
			(далее &ndash; &laquo;Политика&raquo;) составлена в соответствии с требованиями
			Федерального закона от 27.07.2006 №152-ФЗ &laquo;О персональных данных&raquo; (далее
			&ndash; &laquo;Федеральный закон&raquo;) и определяет порядок обработки персональных
			данных и меры по обеспечению безопасности персональных данных со стороны&nbsp;Кузнецова
			Александра Юрьевича&nbsp;(далее &ndash; &laquo;Оператор&raquo;).
		</Typography>

		<Typography gutterBottom>
			1.2. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности
			соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в
			том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
		</Typography>

		<Typography gutterBottom>
			1.3. Настоящая политика Оператора в отношении обработки персональных данных применяется
			ко всей информации, которую Оператор может получить о Пользователях Сервиса&nbsp;
			<a href="https://prtv.su/">https://prtv.su</a>.
		</Typography>

		<Typography gutterBottom>
			1.4. Используя функции Сервиса&nbsp;
			<a href="https://prtv.su/">https://prtv.su</a>&nbsp;и предоставляя свои персональные
			данные Оператору, Пользователь дает согласие на обработку персональных данных в
			соответствии с данной Политикой, подтверждает, что ознакомлен со всеми пунктами
			настоящей Политики и принимает их без исключений и оговорок.
		</Typography>

		<Typography gutterBottom>
			1.5. Пользователь выражает свое полное согласие с условиями Политики путем нажатия
			специальных кнопок на страницах Сервиса при заполнении соответствующих форм. В случае
			несогласия с любым из пунктов Политики Пользователь не имеет права использовать Сервис.
			Также Пользователю запрещено использовать Сервис, если он не достиг установленного
			законом возраста, когда он имеет право заключать соглашения с Оператором.
		</Typography>

		<Typography gutterBottom>
			1.6. Пользователь предоставляет Оператору право получать, хранить, обрабатывать,
			использовать и раскрывать персональные данные Пользователя на условиях Политики в
			случае:
		</Typography>

		<Typography gutterBottom>
			1.7. Самостоятельного предоставления своих персональных данных на Сервисе или
			предоставлении этих данных Оператору иным путем;
		</Typography>

		<Typography gutterBottom>
			1.8. совершая любые действия на Сервисе и/или используя любую часть Сервиса.
		</Typography>

		<Typography variant="h6" align="center" gutterBottom>
			2. Основные понятия, используемые в Политике
		</Typography>

		<Typography gutterBottom>
			2.1. Сервис &ndash; веб-сайт, располагаемый по сетевому адресу&nbsp;
			<a href="https://prtv.su/">https://prtv.su</a>, администрируемый Оператором. Право
			собственности на Сервис принадлежит Оператору. Назначение Сервиса &ndash; создание
			слайд-шоу для телевизоров.
		</Typography>

		<Typography gutterBottom>
			2.2. Обработка персональных данных &ndash; любое действие (операция) или совокупность
			действий (операций), совершаемых с использованием средств автоматизации или без
			использования таких средств с персональными данными, включая сбор, запись,
			систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
			использование, передачу (распространение, предоставление, доступ), обезличивание,
			блокирование, удаление, уничтожение персональных данных.
		</Typography>

		<Typography gutterBottom>
			2.3. Оператор &ndash;&nbsp;Кузнецов Александр Юрьевич, самостоятельно или совместно с
			другими лицами организующий и (или) осуществляющий обработку персональных данных, а
			также определяющее цели обработки персональных данных, состав персональных данных,
			подлежащих обработке, действия (операции), совершаемые с персональными данными.
		</Typography>

		<Typography gutterBottom>
			2.4. Персональные данные &ndash; любая информация, относящаяся прямо или косвенно к
			определенному или определяемому Пользователю Сервиса.
		</Typography>

		<Typography gutterBottom>
			2.5. Пользователь &ndash; лицо, желающее воспользоваться функциями Сервиса.
		</Typography>

		<Typography gutterBottom>
			2.6. Услуги &ndash; услуги, оказываемые Оператором, по предоставлению в пользование
			Сервиса Пользователю с целью создания&nbsp;слайд-шоу для телевизоров.
		</Typography>

		<Typography gutterBottom>
			2.7. Файл &laquo;<span lang="en-US">c</span>ookie&raquo; - файл, который сохраняется на
			компьютере или мобильном устройстве Пользователя, который веб-клиент или веб-браузер
			каждый раз пересылает веб-серверу в&nbsp;
			<span lang="en-US">http</span>-запросе при попытке открыть страницу Сервиса.
		</Typography>

		<Typography variant="h6" align="center" gutterBottom>
			3. Персональные данные Пользователя, которые обрабатывает Оператор
		</Typography>

		<Typography gutterBottom>
			3.1. Оператор обрабатывает персональную информацию, которую Пользователь предоставляет о
			себе самостоятельно в процессе использования Сервиса, включая персональные данные
			Пользователя. Оператор получает и обрабатывает следующие данные Пользователя,
			направляемые посредством форм, указанных на Сервисе:
		</Typography>

		<Typography gutterBottom>- фамилия, имя;</Typography>

		<Typography gutterBottom>- адрес электронной почты;</Typography>

		<Typography gutterBottom>- данные о страницах социальных сетей;</Typography>

		<Typography gutterBottom>- пароль;</Typography>

		<Typography gutterBottom>- адрес местонахождения (локации) экрана (телевизора);</Typography>

		<Typography gutterBottom>
			- иную информацию, который Пользователь желает указать при дальнейшем пользовании
			Сервисом после регистрации.
		</Typography>

		<Typography gutterBottom>
			3.2. Сервис наряду со стандартной авторизацией предоставляет Пользователю возможность
			авторизации через социальные сети.
		</Typography>

		<Typography gutterBottom>
			3.3. При желании Пользователя при создании слайд-шоу Сервис может использовать
			персонифицированную информацию, указанную Пользователем в слайд-шоу, для чего будут
			запрашиваться права на доступ к ленте, фотографиям, местонахождению (локации) и другим
			функциям в социальных сетях Пользователя.
		</Typography>

		<Typography gutterBottom>
			3.4. На Сервисе происходит сбор и обработка обезличенных данных о посетителях (в т.ч.
			файлов &laquo;cookie&raquo;,&nbsp;<span lang="en-US">IP</span>-адрес
			Пользователя).&nbsp;Эти данные используются для персонализации материалов, настройки и
			измерения показателей рекламы, для обеспечения безопасности Сервиса и его Пользователей,
			улучшения и упрощения процесса посещения Сервиса. Оператор обрабатывает обезличенные
			данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя
			(включено сохранение файлов &laquo;cookie&raquo; и использование технологии JavaScript).
			Оператор не использует файлы &laquo;cookie&raquo; для хранения персональной информации
			или раскрытия информации третьим сторонам.
		</Typography>

		<Typography gutterBottom>
			3.5. Оператор использует Google Analytics и Яндекс.Метрику для анализа посещаемости
			Сервиса. Google Analytics и Яндекс.Метрика формирует статистическую и другую информацию
			о сайте, используя файлы-cookie, которые хранятся в компьютерах Пользователя. Эта
			информация используется для составления отчетов об использовании Сервиса. Полученные
			данные хранятся в компании Google и Яндекс.
		</Typography>

		<Typography gutterBottom>
			3.6. Дополнительно Оператор собирает информацию об ошибках работы Сервиса и поведении
			Пользователя на Сервисе.
		</Typography>

		<Typography gutterBottom>
			3.7 Оператор не обрабатывает персональные данные специальной категории, в том числе
			данные о политических, религиозных и иных убеждениях, о членстве в общественных
			объединениях и профсоюзной деятельности, о частной и интимной жизни Пользователя.
		</Typography>

		<Typography gutterBottom>
			3.8. В общем случае Оператор не проверяет достоверность персональной информации,
			предоставляемой Пользователем, и не осуществляет контроль за его дееспособностью. Однако
			Оператор исходит из того, что Пользователь предоставляет достоверную и достаточную
			персональную информацию, указанную в формах запроса, и поддерживает эту информацию в
			актуальном состоянии.
		</Typography>

		<Typography gutterBottom>
			3.9. Настоящая Политика применяется только к Сервису. Оператор не контролирует и не
			несет ответственность за веб-сайты третьих лиц, на которые Пользователь может перейти по
			ссылкам, доступным на Сервисе.
		</Typography>

		<Typography variant="h6" align="center" gutterBottom>
			4. Цели обработки персональных данных
		</Typography>

		<Typography gutterBottom>
			4.1. Оператор собирает и хранит только те персональные данные, которые необходимы для
			оказания Услуг.
		</Typography>

		<Typography gutterBottom>4.2. Цели обработки персональных данных Пользователя:</Typography>

		<Typography gutterBottom>
			4.2.1. Идентификация стороны в рамках использования Сервиса.
		</Typography>

		<Typography gutterBottom>
			4.2.2. Осуществление оказания Услуг и возможности использования Сервиса Пользователями.
		</Typography>

		<Typography gutterBottom>4.2.3. Таргетирование рекламных материалов.</Typography>

		<Typography gutterBottom>
			4.2.3. Предоставление Пользователю доступа к различной информации, содержащейся на
			Сервисе.
		</Typography>

		<Typography gutterBottom>
			4.2.4. Улучшение качества Сервиса, удобства его использования, разработка новых
			технологических дополнений.
		</Typography>

		<Typography gutterBottom>
			4.2.5. Рекламирование Услуг и их продвижение посредством Сервиса.
		</Typography>

		<Typography gutterBottom>4.2.6. Установление с Пользователем обратной связи.</Typography>

		<Typography gutterBottom>
			4.2.7. Проведение статистических и иных исследований на основе обезличенных данных.
		</Typography>

		<Typography align="center">
			4.2.8. Правовые основания обработки персональных данных.
		</Typography>

		<Typography variant="h6" align="center" gutterBottom>
			5. Согласие и права Пользователя.
		</Typography>

		<Typography gutterBottom>
			5.1. Оператор обрабатывает персональные данные Пользователя только в случае их
			заполнения и/или отправки Пользователем самостоятельно через специальные формы,
			расположенные в Сервисе.
		</Typography>

		<Typography gutterBottom>
			5.2. Заполняя добровольно соответствующие формы и/или отправляя свои персональные данные
			Оператору, Пользователь выражает свое согласие с данной Политикой. Согласие Пользователя
			на обработку персональных данных является конкретным, информированным и сознательным.
			Настоящее согласие Пользователя признается исполненным в простой письменной форме.
		</Typography>

		<Typography gutterBottom>
			5.3. Пользователь как субъект персональных данных имеет право на получение информации,
			касающейся обработки его персональных данных, в том числе содержащей:
		</Typography>

		<Typography gutterBottom>- подтверждение факта обработки персональных данных;</Typography>

		<Typography gutterBottom>
			- правовые основания и цели обработки персональных данных;
		</Typography>

		<Typography gutterBottom>
			- цели и применяемые оператором способы обработки персональных данных;
		</Typography>

		<Typography gutterBottom>
			- наименование и место нахождения Оператора, сведения о лицах (за исключением работников
			оператора), которые имеют доступ к персональным данным или которым могут быть раскрыты
			персональные данные на основании договора с оператором или на основании федерального
			закона;
		</Typography>

		<Typography gutterBottom>
			- обрабатываемые персональные данные, относящиеся к соответствующему субъекту
			персональных данных, источник их получения, если иной порядок представления таких данных
			не предусмотрен Федеральным законом;
		</Typography>

		<Typography gutterBottom>
			- сроки обработки персональных данных, в том числе сроки их хранения;
		</Typography>

		<Typography gutterBottom>
			- порядок осуществления субъектом персональных данных прав, предусмотренных Федеральным
			законом;
		</Typography>

		<Typography gutterBottom>
			- информацию об осуществленной или о предполагаемой трансграничной передаче данных;
		</Typography>

		<Typography gutterBottom>
			- наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку
			персональных данных по поручению оператора, если обработка поручена или будет поручена
			такому лицу;
		</Typography>

		<Typography gutterBottom>
			- иные сведения, предусмотренные Федеральным законом или другими федеральными законами.
		</Typography>

		<Typography variant="h6" align="center" gutterBottom>
			6. Безопасность, порядок и условия сбора, хранения, передачи и других видов обработки
			персональных данных
		</Typography>

		<Typography gutterBottom>
			6.1. Безопасность персональных данных, обрабатываемых Оператором, обеспечивается путем
			реализации правовых, организационных и технических мер, необходимых для выполнения в
			полном объеме требований действующего законодательства в области защиты персональных
			данных.
		</Typography>

		<Typography gutterBottom>
			6.2. Обработка персональных данных Пользователя осуществляется любым законным способом,
			в том числе в информационных системах персональных данных с использованием средств
			автоматизации или без использования таких средств.
		</Typography>

		<Typography gutterBottom>
			6.3. В отношении персональной информации Пользователя сохраняется ее конфиденциальность.
			Оператор обеспечивает сохранность персональных данных и принимает все возможные меры,
			исключающие доступ к персональным данным неуполномоченных лиц.
		</Typography>

		<Typography gutterBottom>
			6.4. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы
			третьим лицам, за исключением следующих случаев:
		</Typography>

		<Typography gutterBottom>
			6.4.1. Пользователь выразил свое согласие на такие действия.
		</Typography>

		<Typography gutterBottom>
			6.4.2. Передача необходима в рамках использования Пользователем Сервиса либо для
			оказания Услуг.
		</Typography>

		<Typography gutterBottom>
			6.4.3. Передача предусмотрена российским или иным применимым законодательством в рамках
			установленной законодательством процедуры.
		</Typography>

		<Typography gutterBottom>
			6.4.4. Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью
			или в части), при этом к приобретателю переходят все обязательства по соблюдению условий
			настоящей Политики применительно к полученной им персональной информации.
		</Typography>

		<Typography gutterBottom>
			6.5. Все данные, которые собирает Оператор, хранятся на одном или нескольких защищенных
			серверах.
		</Typography>

		<Typography gutterBottom>
			6.6. Оператор обеспечивает сохранность персональных данных и принимает все возможные
			меры, исключающие доступ к персональным данным неуполномоченных лиц. Доступ к
			персональным данным имеют только уполномоченные сотрудники и партнеры Оператора. Все
			сотрудники Оператора, имеющие доступ к персональным данным, должны придерживаться
			Политики. В целях обеспечения конфиденциальности информации и защиты персональных данных
			Оператор принимает все меры, необходимые для предотвращения несанкционированного
			доступа.
		</Typography>

		<Typography gutterBottom>
			6.7. В случае выявления неточностей в персональных данных, Пользователь может
			актуализировать (изменить, обновить, дополнить) их самостоятельно, путем отправки
			Оператору письма-уведомления на адрес электронной почты Оператора&nbsp;
			<a href="mailto:info@prtv.su">mailto:info@prtv.su</a>
			&nbsp;с пометкой &laquo;Актуализация/изменение персональных данных&raquo;.
		</Typography>

		<Typography gutterBottom>
			6.8. Срок обработки персональных данных является неограниченным. Пользователь может в
			любой момент отозвать свое согласие на обработку персональных данных, направив Оператору
			письмо-уведомление посредством электронной почты на электронный адрес Оператора&nbsp;
			<a href="mailto:info@prtv.su">info@prtv.su</a>
			&nbsp;с пометкой &laquo;Отзыв согласия на обработку персональных данных&raquo;.
		</Typography>

		<Typography gutterBottom>
			6.9. Письма-уведомления, указанные в п. 6.7.-6.8. Политики могут быть также направлены
			на бумажном носителе Пользователем по адресу:&nbsp;Москва, ул. Мусы Джалиля, дом 8,
			корп. 4, офис 612.
		</Typography>

		<Typography gutterBottom>
			6.10. Удаление, уничтожение, персональных данных осуществляются&nbsp;в порядке,
			предусмотренном действующим законодательством и нормативными актами Оператора. Удаление,
			уничтожение персональных данных осуществляется ответственными лицами:
		</Typography>

		<Typography gutterBottom>
			6.10.1. в случаях, предусмотренных Федеральным законом;
		</Typography>

		<Typography gutterBottom>
			6.10.2. по требованию Пользователя, государственного органа или суда;
		</Typography>

		<Typography gutterBottom>6.10.3. по истечении сроков хранения;</Typography>

		<Typography gutterBottom>6.10.4. за истечением надобности.</Typography>

		<Typography variant="h6" align="center" gutterBottom>
			7. Ответственность
		</Typography>

		<Typography gutterBottom>
			7.1. Настоящая Политика применяется только к Сервису. Оператор не контролирует и не
			несет ответственность за веб-сайты третьих лиц, на которые Пользователь может перейти по
			ссылкам, доступным на Сервисе.
		</Typography>

		<Typography gutterBottom>
			7.2. Политика конфиденциальности не регулирует и Оператор не несет ответственности за
			получение, хранение, обработку, использование и раскрытие персональных данных
			Пользователя третьими лицами, не находящимися во владении или под управлением Оператора,
			и физическими лицами, не являющимися работниками Оператора, даже если Пользователь
			получил доступ к веб-сайтам товаров или услуг этих лиц с помощью&nbsp;Сервиса&nbsp;или
			рассылки.
		</Typography>

		<Typography gutterBottom>
			7.3. В случае утраты или разглашения конфиденциальной информации Оператор не несёт
			ответственность, если данная информация:
		</Typography>

		<Typography gutterBottom>
			- стала публичным достоянием до её утраты или разглашения;
		</Typography>

		<Typography gutterBottom>
			- была получена от третьей стороны до момента её получения Оператором;
		</Typography>

		<Typography gutterBottom>- была разглашена с согласия Пользователя.</Typography>

		<Typography gutterBottom>
			7.4. Оператор не несет ответственности за ущерб или убытки, которые понес Пользователь
			и/или третьи лица в результате ошибочного понимания или непонимания условий Политики,
			инструкций или указаний о порядке использования&nbsp;Сервиса, относительно порядка
			размещения данных и других технических вопросов.
		</Typography>

		<Typography variant="h6" align="center" gutterBottom>
			8. Заключительные положения
		</Typography>

		<Typography gutterBottom>
			8.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся
			обработки его персональных данных, обратившись к Оператору по электронной почте&nbsp;
			<a href="mailto:info@prtv.su">info@prtv.su</a>.
		</Typography>

		<Typography gutterBottom>
			8.2. Оператор имеет право вносить изменения в настоящую Политику в любое время и по
			своему усмотрению. При внесении изменений в актуальной редакции указывается дата
			последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения
			в&nbsp;Сервисе, если иное не предусмотрено новой редакцией Политики.
		</Typography>

		<Typography gutterBottom>
			8.3. Действующая редакция всегда находится на страницах&nbsp;Сервиса&nbsp;и называется
			&laquo;Политика конфиденциальности в отношении обработки персональных данных&raquo;.
		</Typography>

		<Typography gutterBottom>
			8.4. Настоящая Политика применима только к&nbsp;Сервису.
		</Typography>

		<Typography gutterBottom>
			8.5. Политика распространяется на Пользователя с момента, когда во время
			использования&nbsp;Сервиса&nbsp;он при передаче своих персональных данных согласился с
			условиями Политики, и действует до тех пор, пока в&nbsp;Сервисе&nbsp;хранится любая
			информация о Пользователе, в том числе, персональные данные.
		</Typography>

		<Typography gutterBottom>
			8.6. Оператор не принимает предложения от Пользователей относительно изменений настоящей
			Политики.
		</Typography>

		<Typography gutterBottom>
			8.7. Все споры между сторонами решаются путем переговоров. Претензионный порядок решения
			споров является обязательным, срок ответа на претензию составляет 10 (десять) рабочих
			дней.
		</Typography>

		<Typography gutterBottom>
			8.8. В случае невозможности достижения согласия путем переговоров, споры решаются в
			судебном порядке по месту нахождения Оператора.
		</Typography>

		<Typography gutterBottom>
			Дата внесения изменений: &laquo;21&raquo; ноября 2019 г.
		</Typography>
	</StaticPage>
);

export default PrivacyPolicy;
