import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';
import {TextFieldProps} from '@material-ui/core/TextField/TextField';

const useTextInputNakedStyles = makeStyles({
	root: {
		padding: 0,
	},
	input: {
		padding: 0,
		width: '72px',
		height: '32px',
		textAlign: 'center',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		borderRadius: '4px',
		fontSize: '16px',
		lineHeight: 1.5,
	},
});

const TextInputNaked: React.FC<TextFieldProps> = (props) => {
	const classes = useTextInputNakedStyles();

	return <TextField {...props} InputProps={{classes}} />;
};

export default TextInputNaked;
