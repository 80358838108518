exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".page--7Qqlm {\n\tmargin-top: 100px;\n}\n\n\t.page--7Qqlm .paper--62fyK {\n\t\twidth: 800px;\n\t\tpadding: 16px 26px;\n\n\t}\n\n\t.page--7Qqlm .paper--62fyK ol {\n          list-style-type: none;\n          counter-reset: item;\n          margin: 0;\n          padding: 0;\n        }\n\n\t.page--7Qqlm .paper--62fyK ol > li {\n          display: table;\n          counter-increment: item;\n          margin-bottom: 0.6em;\n        }\n\n\t.page--7Qqlm .paper--62fyK ol > li:before {\n          content: counters(item, \".\") \". \";\n          display: table-cell;\n          padding-right: 0.6em;\n        }\n\n\t.page--7Qqlm .paper--62fyK li ol > li {\n          margin: 0;\n        }\n\n\t.page--7Qqlm .paper--62fyK li ol > li:before {\n          content: counters(item, \".\") \". \";\n        }\n\n\t.page--7Qqlm .paper--62fyK ul {\n            list-style: disc;\n        }\n\n\t.page--7Qqlm .paper--62fyK ul > li {\n            margin-bottom: 0.6em;\n        }\n\n\t.page--7Qqlm .paper--62fyK li ul {\n            list-style: circle;\n        }\n\n\t.page--7Qqlm .links--3UbYR {\n\t\tpadding: 26px;\n\t\tcolor: #9c9c9c;\n\t}\n\n\t.page--7Qqlm .links--3UbYR .link--3_gYX {\n\t\t\tcolor: #9c9c9c;\n\t\t\ttext-decoration: underline;\n\t\t}\n\n\t.page--7Qqlm .links--3UbYR .link--3_gYX:hover {\n\t\t\t\ttext-decoration: none;\n\t\t\t}\n", ""]);

// Exports
exports.locals = {
	"page": "page--7Qqlm",
	"paper": "paper--62fyK",
	"links": "links--3UbYR",
	"link": "link--3_gYX"
};