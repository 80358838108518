import React, {SVGProps, Ref, forwardRef, memo} from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 36.00 36.00"
		aria-hidden="true"
		role="img"
		className="iconify iconify--twemoji"
		preserveAspectRatio="xMidYMid meet"
		fill="#000000"
		ref={ref}
		{...props}
	>
		<g id="SVGRepo_bgCarrier" strokeWidth="0" />
		<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
		<g id="SVGRepo_iconCarrier">
			<path fill="#CE2028" d="M36 27a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4v-4h36v4z" />
			<path fill="#22408C" d="M0 13h36v10H0z" />
			<path fill="#EEE" d="M32 5H4a4 4 0 0 0-4 4v4h36V9a4 4 0 0 0-4-4z" />
		</g>
	</svg>
);
const ForwardRef = forwardRef(SvgComponent);

export const RuFlag = memo(ForwardRef);
