export default (numOfSteps: number, step: number) => {
	// This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
	// Adam Cole, 2011-Sept-14
	// HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
	const rMax = 100;
	const gMax = 240;
	const bMax = 200;
	// tslint:disable-next-line:one-variable-per-declaration
	let r, g, b;
	const h = step / numOfSteps;
	// tslint:disable-next-line:no-bitwise no-magic-numbers
	const i = ~~(h * 6);
	// tslint:disable-next-line:no-magic-numbers
	const f = h * 6 - i;
	const q = 1 - f;
	// tslint:disable-next-line:no-magic-numbers
	switch (i % 6) {
		case 0:
			r = 1;
			g = f;
			b = 0;
			break;
		case 1:
			r = q;
			g = 1;
			b = 0;
			break;
		// tslint:disable-next-line:no-magic-numbers
		case 2:
			r = 0;
			g = 1;
			b = f;
			break;
		// tslint:disable-next-line:no-magic-numbers
		case 3:
			r = 0;
			g = q;
			b = 1;
			break;
		// tslint:disable-next-line:no-magic-numbers
		case 4:
			r = f;
			g = 0;
			b = 1;
			break;
		// tslint:disable-next-line:no-magic-numbers
		case 5:
			r = 1;
			g = 0;
			b = q;
			break;
	}
	// @ts-ignore
	// prettier-ignore
	// tslint:disable-next-line
	return '#' + ('00' + (~~(r * rMax)).toString(16)).slice(-2) + ('00' + (~~(g * gMax)).toString(16)).slice(-2) + ('00' + (~~(b * bMax)).toString(16)).slice(-2);
};
