import React from 'react';
import {TextField} from '@material-ui/core';
import {TextFieldProps} from '@material-ui/core/TextField/TextField';

const TextInputContentWidth = (props: TextFieldProps) => {
	return (
		<div>
			<span
				role="textbox"
				onClick={props.onClick}
				style={{fontSize: '16px', lineHeight: '1.5'}}
			>
				{props.value as string}
			</span>
			<TextField style={{height: '0px', overflow: 'hidden', opacity: 0}} {...props} />
		</div>
	);
};

export default TextInputContentWidth;
