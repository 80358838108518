import SimpleSchema from 'simpl-schema';
import {Class} from 'meteor/jagi:astronomy';
import {methodNames} from 'shared/constants/methodNames';

const CustomAnswer = Class.create({
	name: 'CustomAnswer',
	fields: {
		label: String,
		errorText: String,
		helperText: {
			type: String,
			optional: true,
		},
	},
});

export const Quiz = Class.create({
	name: 'Quiz',
	collection: new Mongo.Collection('quizzes'),
	fields: {
		title: {
			type: String,
			optional: true,
		},
		type: String,
		question: String,
		customAnswer: {
			type: CustomAnswer,
			optional: true,
		},
		isActive: Boolean,
		sendPulseVariable: {
			type: String,
			optional: true,
		},
		unisenderVariable: {
			type: String,
			optional: true,
		},
		answers: [String],
		userIds: {
			type: [String],
			default: function() {
				return [];
			},
		},
	},
	behaviors: {
		timestamp: {
			hasCreatedField: true,
			hasUpdatedField: true,
			createdFieldName: 'createdAt',
			updatedFieldName: 'updatedAt',
		},
	},
});

export const QuizResponse = Class.create({
	name: 'QuizResponse',
	collection: new Mongo.Collection('quizResponses'),
	fields: {
		answers: [String],
		quizId: String,
		userId: String,
	},
	behaviors: {
		timestamp: {
			hasCreatedField: true,
			createdFieldName: 'createdAt',
		},
	},
});
