import * as React from 'react';
import {Meteor} from 'meteor/meteor';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import {methodNames} from 'shared/constants/methodNames';
import {connect} from 'react-redux';
import {RootState} from 'client/store/root-reducer';
import {updateSlideElement} from 'client/actions/slideShowEditor';

interface DeleteStatElementDialogProps {
	elementId: string;
	removed: any;
}

class DeleteStatElementDialog extends React.PureComponent<DeleteStatElementDialogProps> {
	state = {open: false};

	handleClickOpen = () => {
		this.setState({open: true});
	};

	handleCancel = () => {
		this.setState({open: false});
	};

	handleDelete = () => {
		Meteor.call(methodNames.statistics.remove, this.props.elementId, (error) => {
			if (error) {
				console.log('Ошибка');
			} else {
				this.props.removed();
				// console.log('Элемент удален', true);
				this.setState({open: false});
			}
		});
	};

	render() {
		return (
			<>
				<Tooltip title="Удалить из статистики" arrow>
					<IconButton onClick={this.handleClickOpen}>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
				<Dialog
					open={this.state.open}
					keepMounted
					onClose={this.handleCancel}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title">
						Удалить элемент из статистики? Все собранные данные статистики по элементу
						также будут удалены.
					</DialogTitle>
					<DialogActions>
						<Button onClick={this.handleDelete} color="primary">
							Удалить
						</Button>
						<Button onClick={this.handleCancel} color="primary">
							Отмена
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

export default connect((state: RootState) => ({}), {
	updateSlideElement,
})(DeleteStatElementDialog);
