import * as React from 'react';
import {connect} from 'react-redux';

import {updateSlideElement} from 'client/actions/slideShowEditor';
import {ISlideElement} from 'shared/collections/SlideElements';
import {RestaurantServiceEnum, RestaurantServiceEnumDisplay} from 'shared/collections/Users';
import EditorActionButtonsCommon from '../formFields/EditorActionButtonsCommon';
import EditorDelimiter from '../formFields/EditorDelimiter';
import EditorSelect from '../formFields/EditorSelect';
import BgColorField from '../settingsFields/BgColorField';
import BgImageField from '../settingsFields/BgImageField';
import OpacityField from '../settingsFields/OpacityField';
import TextUnitField from '../settingsFields/TextUnitField';
import PermanentElementField from '../settingsFields/PermanentElementField';
import ElementSettingsWrapper from 'client/components/common/ElementSettingsWrapper';
import RotateAngleField from 'client/components/editor/settingsFields/RotateAngleField';

import EditorMultipleSelect from 'client/components/editor/formFields/EditorMultipleSelect';
import EditorTextInput from 'client/components/editor/formFields/EditorTextInput';
import css from './RestMenuElementSettings.pcss';
import {methodNames} from 'shared/constants/methodNames';
import {withTracker} from 'react-meteor-data-with-tracker';
import {Meteor} from 'meteor/meteor';
import {publishNames} from 'shared/constants/publishNames';
import {IUser} from 'client/components/user/ProfilePage';
import {restConfig} from 'shared/collections/RestMenu.ts';

interface IRestMenuElementSettingsProps {
	element: ISlideElement;
	user: IUser;
	updateSlideElement: typeof updateSlideElement;
}
interface IRestMenuElementSettingsState {
	disabled: boolean;
	items: object[];
}

class RestMenuElementSettings extends React.PureComponent<
	IRestMenuElementSettingsProps,
	IRestMenuElementSettingsState
> {
	state: IRestMenuElementSettingsState = {disabled: true, items: []};

	componentDidUpdate(prevProps: Readonly<IRestMenuElementSettingsProps>) {
		const {element} = this.props;
		if (element && this.state.items.length === 0) this.getContent();
	}

	handleSystemChange = (event) => {
		const {element} = this.props;
		this.props.updateSlideElement(element, {
			restService: event.target.value,
		});
	};

	handleChoseItem = (event) => {
		const {element} = this.props;

		this.props.updateSlideElement(element, {
			restItemIds: event.target.value,
		});
	};

	handleColorChange = (textColor, prefix) => {
		const {element} = this.props;

		this.props.updateSlideElement(element, {
			[prefix ? `${prefix}FontColor` : 'textColor']: textColor,
		});
	};

	getContent = async () => {
		const {element} = this.props;
		Meteor.call(
			methodNames.restaurants.getMenu,
			{service: element.restService, restSettingsFields: element.restSettingsFields},
			(err, result) => {
				if (!err) {
					this.setState({items: [...result]});
				} else {
					console.log({err, result});
				}
			},
		);
	};

	handleChangeList = (event) => {
		const el = event.target;
		const {element} = this.props;

		this.props.updateSlideElement(element, {
			[el.name]: el.value,
		});
	};

	handleChangeSettings = (value, name) => {
		const {element} = this.props;
		let restSettingsFields = {...element.restSettingsFields};
		restSettingsFields[name] = value;

		this.props.updateSlideElement(element, {
			restSettingsFields,
		});
	};

	get enabledServices() {
		const {user} = this.props;
		if (!user?.projectServices) return [];
		return Object.values(RestaurantServiceEnum)
			.filter((serviceName) => {
				return !!user.projectServices[serviceName];
			})
			.map((serviceName) => {
				const findMe = Object.keys(RestaurantServiceEnum)[
					Object.values(RestaurantServiceEnum).indexOf(serviceName)
				];
				return {
					value: RestaurantServiceEnum[findMe],
					label: RestaurantServiceEnumDisplay[findMe],
				};
			});
	}

	render() {
		const {element, user} = this.props;
		const serviceAvailable =
			user?.projectServices && user?.projectServices[element.restService];

		const disabled = !(serviceAvailable && element.restService);

		return (
			<ElementSettingsWrapper elementId={element._id}>
				<EditorSelect
					label="Выбор системы"
					tooltip="Выберите вашу систему управления ресторанами"
					options={this.enabledServices}
					value={serviceAvailable ? element.restService : null}
					onChange={this.handleSystemChange}
				/>

				<div className={disabled ? css.holder : null}>
					{serviceAvailable &&
						restConfig[element.restService] &&
						restConfig[element.restService].settingsFormFields.map((field) => {
							switch (field.type) {
								case 'text':
									return (
										<EditorTextInput
											tooltip={field.tooltip}
											label={field.title}
											value={element.restSettingsFields[field.name]}
											onChange={(val) =>
												this.handleChangeSettings(val, field.name)
											}
										/>
									);
									break;
								default:
									return null;
							}
						})}

					<EditorSelect
						label="Количество в ряд"
						name={'restRowCount'}
						tooltip="Сколько блюд отобразить в один ряд по горизонтали"
						options={[
							{label: '1', value: 1},
							{label: '2', value: 2},
							{label: '3', value: 3},
							{label: '4', value: 4},
						]}
						value={element.restRowCount}
						onChange={this.handleChangeList}
					/>

					<EditorMultipleSelect
						label="Выбрать блюда"
						options={this.state.items
							.map((item) => {
								return {
									label: item.name,
									value: item.id,
									icon: item.imageUrls?.length ? true : false,
								};
							})
							.filter((val) => val)}
						onChange={this.handleChoseItem}
						value={element.restItemIds || []}
					/>

					<TextUnitField
						element={element}
						prefix={null}
						label={'Название'}
						tooltip="Настройки отображения шрифта элемента"
						onChange={this.handleColorChange}
					/>
					<TextUnitField
						element={element}
						prefix={'price'}
						label={'Цена'}
						tooltip="Настройки отображения шрифта элемента"
						onChange={this.handleColorChange}
					/>

					<BgColorField element={element} />
					<BgImageField element={element} />
					<RotateAngleField element={element} />
					<OpacityField element={element} />
					<PermanentElementField element={element} />

					<EditorDelimiter />
				</div>

				<EditorActionButtonsCommon element={element} />
			</ElementSettingsWrapper>
		);
	}
}

export default withTracker<{}>(() => {
	const subProfile = Meteor.subscribe(publishNames.user.userProfile);
	const loading = !subProfile.ready();

	return {
		loading,
		user: Meteor.user() as IUser,
	};
})(connect(null, {updateSlideElement})(RestMenuElementSettings));
