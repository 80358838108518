import * as React from 'react';
import {connect} from 'react-redux';

import {updateSlideElement} from 'client/actions/slideShowEditor';
import EditorColor from '../formFields/EditorColor';
import {ISlideElement} from 'shared/collections/SlideElements';

interface ITextColorFieldProps {
	element: ISlideElement;
	updateSlideElement: typeof updateSlideElement;
	name: string;
	text: string;
}

class TextColorField extends React.Component<ITextColorFieldProps> {
	handleChange = (color: string) => {
		const {element, name} = this.props;

		this.props.updateSlideElement(element, {[name || 'textColor']: color});
	};

	render() {
		const {element, text, name} = this.props;
		const value = element[name || 'textColor'];
		return (
			<EditorColor
				label={text || 'Цвет текста'}
				value={value}
				resetValue="#000"
				onChange={this.handleChange}
			/>
		);
	}
}

export default connect(null, {
	updateSlideElement,
})(TextColorField);
