import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {DialogActions, FormControl} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import Modal from 'client/components/common/ui/Modal';
import {EditField} from 'client/components/common/ui/EditableField';
import './NewsFormDialog.scss';
import {isEmpty, isEqual} from 'lodash';
import {LL} from 'shared/localization';

export const NewsFormDialog = ({newsData = {}, modalitle, onSuccess, children}) => {
	console.log(newsData);
	const defaultTitle = {
		key: Date.now(),
		text: newsData.title,
	};
	const defaultArticle = {
		key: Date.now(),
		text: newsData.article,
	};

	const [title, setTitle] = useState(defaultTitle);
	const [article, setArticle] = useState(defaultArticle);
	const [isOpen, setIsOpen] = useState(false);
	const [errors, setErrors] = useState({});
	const [firstTouch, setFirstTouch] = useState(true);

	const isValid = () => {
		const errors = {};
		const news = buildNews();
		if (isEmpty(title)) {
			errors.title = LL('admin.news.errorsTitle');
		}
		if (isEmpty(article)) {
			errors.article = LL('admin.news.errorsArticle');
		}
		if (isEqual({...newsData}, news)) {
			/*
				No need to show this error, it's just disable submit button.
			*/
			errors.compare = LL('admin.news.errorsCompare');
		}
		setErrors(errors);
		return isEmpty(errors);
	};

	const closeForm = () => {
		setIsOpen(false);
		setFirstTouch(true);
	};

	const flushForm = () => {
		setTitle(defaultTitle);
		setArticle(defaultArticle);
	};

	useEffect(() => {
		if (!isEmpty(newsData)) {
			setTitle(defaultTitle);
			setArticle(defaultArticle);
		}
	}, [newsData]);

	useEffect(() => {
		isValid();
	}, [title, article]);

	const buildNews = () => ({
		...newsData,
		title: title.text,
		article: article.text,
	});

	const submitForm = () => {
		const news = buildNews();
		console.log(news);
		onSuccess(news);
		closeForm();
		flushForm();
	};

	return (
		<>
			<div onClick={() => setIsOpen(true)}>{children}</div>
			<Modal
				title={modalitle || LL('common.adminHeader.news')}
				isOpen={isOpen}
				onClose={() => closeForm()}
			>
				<FormControl fullWidth={true} onClick={() => setFirstTouch(false)}>
					<Paper className="questionPaper">
						<Paper className="answerPaper">
							<EditField
								placeholder={LL('admin.news.setTitle')}
								label={LL('admin.faq.modalTitle')}
								field={title}
								setField={setTitle}
								required={true}
							/>
						</Paper>
						<Paper className="answerPaper">
							<EditField
								placeholder={LL('admin.news.writeText')}
								label={LL('common.text')}
								field={article}
								setField={setArticle}
								required={true}
							/>
						</Paper>
					</Paper>
					<DialogActions>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={!isEmpty(errors)}
							onClick={() => submitForm()}
						>
							{LL('common.save')}
						</Button>
					</DialogActions>
				</FormControl>
			</Modal>
		</>
	);
};
