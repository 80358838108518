import * as React from 'react';
import Button from '@material-ui/core/Button/Button';
import Modal from 'client/components/common/ui/Modal';
import {DialogActions, DialogContent, TextField} from '@material-ui/core';
import {LL} from 'shared/localization';

interface ISlideSaveModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (statId: string) => void;
	href: string;
}

class AddHrefToElementModal extends React.Component<ISlideSaveModalProps> {
	constructor(props: ISlideSaveModalProps) {
		super(props);
		this.state = {href: props.href || ''};
	}

	handleSubmit = () => {
		const {onSubmit, onClose} = this.props;
		if (this.state.href) {
			onSubmit(this.state.href);
		}
		onClose();
	};

	handleReset = () => {
		const {onSubmit, onClose} = this.props;

		onSubmit(null);

		onClose();
	};

	render() {
		const {isOpen, onClose} = this.props;
		const {href} = this.state;

		return (
			<Modal title={LL('editor.modals.setLinkWhenClick')} isOpen={isOpen} onClose={onClose}>
				<DialogContent>
					<TextField
						id="standard-basic"
						fullWidth
						placeholder={LL('editor.modals.link')}
						value={href}
						onChange={({target}) => this.setState({href: target.value})}
					/>
				</DialogContent>

				<DialogActions>
					<Button variant="contained" onClick={onClose}>
						{LL('common.cancel')}
					</Button>
					{this.props.href && this.props.href.length && (
						<Button color="secondary" variant="contained" onClick={this.handleReset}>
							{LL('editor.modals.removeClickability')}
						</Button>
					)}
					<Button
						color="primary"
						variant="contained"
						type="submit"
						onClick={this.handleSubmit}
					>
						{LL('common.save')}
					</Button>
				</DialogActions>
			</Modal>
		);
	}
}

export default AddHrefToElementModal;
