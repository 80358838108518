import {makeStyles} from '@material-ui/core/styles';
import {ensureColor} from 'client/components/common/stream/utils';

export default makeStyles(({spacing, typography, palette}) => ({
	paperRoot: {
		borderTopRightRadius: '12px',
		borderBottomRightRadius: '12px',
	},
	root: {
		minWidth: '505px',
		paddingTop: spacing(3),
		paddingBottom: spacing(3),
		paddingLeft: spacing(4),
		paddingRight: spacing(4),
		boxSizing: 'border-box',
	},
	deleteButton: {
		border: '1px solid #B00020',
		color: '#B00020',
		marginRight: 'auto',
	},
	cancelButton: {
		marginRight: spacing(0.5),
	},
	saveButton: {
		padding: spacing(0.5, 3.5),
		marginLeft: spacing(3),
		height: spacing(4.5),
		'&:first-child': {
			marginLeft: 0,
		},
		textTransform: 'uppercase',
		backgroundColor: ensureColor(900, '#3F51B5'),
		color: palette.primary.contrastText,
		borderRadius: '4px',
		'&:hover, &:focus': {
			backgroundColor: ensureColor(800, '#3F51B5'),
		},
	},
	main: {
		display: 'flex',
		alignItems: 'center',
	},
	side: {
		marginRight: 24,
	},
	dateEditor: {
		width: 'auto',
		display: 'flex',
		paddingTop: '0px!important',
		marginTop: '0',
		paddingBottom: '0px!important',
		marginBottom: 0,
	},
	recurrenceWrapper: {
		display: 'flex',
		alignItems: 'center',
		borderTop: '1px solid rgba(0, 0, 0, 0.12)',
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
		padding: '16px 0',
		margin: '16px 0',
	},
	afterLabel: {
		marginBottom: 8,
		opacity: 0.6,
	},
	fastActionsWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
		padding: '16px 0',
		margin: '16px 0',
		gap: 5,
	},
	fastActionsTitle: {
		marginBottom: 8,
		opacity: 0.6,
		width: '100%',
	},
}));
