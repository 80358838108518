import React from 'react';
import getOverlappingEvents from './getOverlappingEvents';
import {Warning} from '@material-ui/icons';
import {Box} from '@material-ui/core';
import {ISlideStream} from 'shared/collections/SlideStream';
import Typography from '@material-ui/core/Typography/Typography';
import {Slideshow} from 'shared/collections/Slideshows';
import {LL} from 'shared/localization';

interface IOverlappingEvents {
	slideStream: ISlideStream;
	timezone?: string;
}

const getSlideshowTitle = (slideshowNumId: string) =>
	Slideshow.findOne({numId: slideshowNumId})?.name ?? '';

const OverlappingEvents: React.FC<IOverlappingEvents> = ({slideStream, timezone}) => {
	const overlappingEvents = getOverlappingEvents(slideStream, timezone);

	if (!overlappingEvents) return null;

	return (
		// @ts-ignore
		<Box mb={5}>
			<Box
				my={2}
				color="red"
				fontSize={18}
				sx={{
					display: 'flex',
					alignItems: 'center',
					// @ts-ignore
					gap: 5,
				}}
			>
				<Warning />
				{LL('components.impressionWarning')}
			</Box>

			{Object.keys(overlappingEvents).map((date) => (
				// @ts-ignore
				<Box key={date}>
					<Typography variant="h5">{date}</Typography>

					{overlappingEvents[date].map((overlap) => (
						// @ts-ignore
						<Box key={`${overlap.events[0].id}-${overlap.events[1].id}`} pl={2} my={1}>
							{`С ${overlap.from} до ${overlap.to}:`}
							&nbsp;
							<Typography variant="caption">
								{getSlideshowTitle(overlap.events[0].slideshowNumId)}
							</Typography>
							&nbsp; и &nbsp;
							<Typography variant="caption">
								{getSlideshowTitle(overlap.events[1].slideshowNumId)}
							</Typography>
						</Box>
					))}
				</Box>
			))}
		</Box>
	);
};

export default OverlappingEvents;
