import React, {useRef, useState} from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import ruLocale from '@fullcalendar/core/locales/ru';
import rrulePlugin from '@fullcalendar/rrule';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {ISlideStream, ISlideStreamSchedule} from 'shared/collections/SlideStream';
import moment from 'moment-timezone';
import EventEditor from './EventEditor';
import {INewEvent} from './types';
import {prepareEvents, handleEventMove, handleEventResize} from './helpers';
import OverlappingEvents from './OverlappingEvents';

interface IStreamScheduler {
	slideStream: ISlideStream | undefined;
	currentTimeZone: string;
}

const StreamScheduler: React.FC<IStreamScheduler> = ({slideStream, currentTimeZone}) => {
	const [eventToEdit, setEventToEdit] = useState<ISlideStreamSchedule | INewEvent | null>(null);
	// todo: if we update this value then full calender resets scroll
	const {current: nowWithTimeZone} = useRef(
		moment()
			.tz(currentTimeZone)
			.format('YYYY-MM-DDTHH:mm:ss'),
	);

	if (!slideStream) return null;

	const events = prepareEvents(slideStream?.schedules ?? [], currentTimeZone);

	return (
		<>
			<OverlappingEvents slideStream={slideStream} timezone={currentTimeZone} />

			<FullCalendar
				plugins={[rrulePlugin, timeGridPlugin, interactionPlugin]}
				initialView="timeGridWeek"
				locale={ruLocale}
				nowIndicator
				now={nowWithTimeZone}
				timeZone={currentTimeZone}
				scrollTime="9:00:00"
				headerToolbar={{
					left: 'title',
					center: '',
					right: 'prev,today,next',
				}}
				events={events}
				editable
				eventDrop={(event) => handleEventMove(slideStream._id, event)}
				eventResize={(event) => handleEventResize(slideStream._id, event)}
				selectable
				selectMirror
				select={({start: startDate, end: endDate, allDay}) => {
					// By default, FullCalendar will force all dates to UTC format if timezone was specified
					// So we need to make correction
					const localTimezoneOffset = new Date().getTimezoneOffset();

					setEventToEdit({
						allDay,
						startDate: moment(startDate)
							.add(localTimezoneOffset, 'minutes')
							.toDate(),
						endDate: moment(endDate)
							.add(localTimezoneOffset, 'minutes')
							.toDate(),
						date: allDay ? moment(startDate).format('YYYY-MM-DD') : undefined,
					});
				}}
				eventClick={(event) => {
					// Because of working with native Date objects we need to convert dates to current timezone first
					const localTimezoneOffset = new Date().getTimezoneOffset();
					const currentTimezoneOffset = moment.tz
						.zone(currentTimeZone)
						.utcOffset(moment.tz(currentTimeZone));

					// POSIX compatibility requires that the offsets are inverted – https://momentjs.com/timezone/docs/#/zone-object/offset/
					// So we need to invert it back before manipulations
					const timezoneOffsetDiff = -(currentTimezoneOffset - localTimezoneOffset);

					const {startDate, endDate} = event.event.extendedProps
						.rawEvent as ISlideStreamSchedule;

					setEventToEdit({
						...(event.event.extendedProps.rawEvent as ISlideStreamSchedule),
						startDate: moment(startDate)
							.add(timezoneOffsetDiff, 'minutes')
							.toDate(),
						endDate: moment(endDate)
							.add(timezoneOffsetDiff, 'minutes')
							.toDate(),
					});
				}}
			/>

			<EventEditor
				isOpened={!!eventToEdit}
				onClose={() => setEventToEdit(null)}
				event={eventToEdit}
				slideStreamId={slideStream._id}
				currentTimezone={currentTimeZone}
			/>
		</>
	);
};

export default StreamScheduler;
