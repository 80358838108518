export const googleFonts = [
	'Bad Script',
	'Comfortaa',
	'El Messiri',
	'Fira Code',
	'Forum',
	'Jura',
	'Kelly Slab',
	'Kurale',
	'Lobster',
	'Marck Script',
	'Neucha',
	'Open Sans',
	'Oswald',
	'Pacifico',
	'Pangolin',
	'Play',
	'Poiret One',
	'Press Start 2P',
	'Roboto',
	'Rubik Mono One',
	'Ruslan Display',
	'Russo One',
	'Seymour One',
	'Stalinist One',
	'Underdog',
];
