exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".checkoutHeader--wgZv1 {\n    padding: 6px 40px;\n}\n\n    .checkoutHeader--wgZv1 .wrapper--3oU1C {\n        display: -webkit-box;\n        display: -webkit-flex;\n        display: -moz-box;\n        display: flex;\n        -webkit-box-align: center;\n        -webkit-align-items: center;\n           -moz-box-align: center;\n                align-items: center;\n        -webkit-box-pack: justify;\n        -webkit-justify-content: space-between;\n           -moz-box-pack: justify;\n                justify-content: space-between;\n    }\n\n    .checkoutHeader--wgZv1 .brand--2pyQm {\n        font-size: 20px;\n        line-height: 28px;\n        color: #3F51B5;\n        font-weight: 500;\n        min-width: 80px;\n    }\n\n    .checkoutHeader--wgZv1 .account--1Vd3G {\n        min-width: 80px;\n    }\n\n    .checkoutHeader--wgZv1 .main--1NjJB {\n        -webkit-flex-basis: 720px;\n                flex-basis: 720px;\n    }\n\n    .checkoutHeader--wgZv1 .backLink--38ln- {\n        display: -webkit-box;\n        display: -webkit-flex;\n        display: -moz-box;\n        display: flex;\n        -webkit-box-align: center;\n        -webkit-align-items: center;\n           -moz-box-align: center;\n                align-items: center;\n        text-decoration: none;\n        font-size: 24px;\n        line-height: 36px;\n        color: rgba(0, 0, 0, 0.87);\n    }\n\n    .checkoutHeader--wgZv1 .backLink--38ln-:hover {\n            opacity: 0.7;\n        }\n\n", ""]);

// Exports
exports.locals = {
	"checkoutHeader": "checkoutHeader--wgZv1",
	"wrapper": "wrapper--3oU1C",
	"brand": "brand--2pyQm",
	"account": "account--1Vd3G",
	"main": "main--1NjJB",
	"backLink": "backLink--38ln-"
};