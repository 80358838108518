import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {LL} from 'shared/localization';

interface IProps {
	title: string;
	onSuccess: () => void;
}

class DialogWithElement extends React.PureComponent<IProps> {
	state = {open: false};

	handleClickOpen = () => {
		this.setState({open: true});
	};

	handleCancel = () => {
		this.setState({open: false});
	};

	handleSuccess = () => {
		this.props.onSuccess();
		this.handleCancel();
	};

	render() {
		const {title, children} = this.props;

		return (
			<>
				<div onClick={() => this.handleClickOpen()}>{children}</div>
				<Dialog
					open={this.state.open}
					keepMounted
					onClose={this.handleCancel}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title">{title}?</DialogTitle>
					<DialogActions>
						<Button onClick={this.handleSuccess} color="primary">
							{LL('common.apply')}
						</Button>
						<Button onClick={this.handleCancel} color="primary">
							{LL('common.cancel')}
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

export default DialogWithElement;
