import * as React from 'react';
import {connect} from 'react-redux';

import {updateSlideElement} from 'client/actions/slideShowEditor';
import EditorTextInput from '../formFields/EditorTextInput';
import {ISlideElement} from 'shared/collections/SlideElements';

interface IFontSizeFieldProps {
	element: ISlideElement;
	updateSlideElement: typeof updateSlideElement;
	name: string;
	text: string;
}

class FontSizeField extends React.Component<IFontSizeFieldProps> {
	handleChange = (value?: number) => {
		const {element, name} = this.props;

		this.props.updateSlideElement(element, {
			[name || 'fontSize']: value,
		});
	};

	render() {
		const {element, text, name} = this.props;

		const value = element[name || 'fontSize'];

		return (
			<EditorTextInput
				label={text || 'Размер текста'}
				value={value}
				onChange={this.handleChange}
				type="number"
				inputProps={{min: 1, step: 1}}
			/>
		);
	}
}

export default connect(null, {
	updateSlideElement,
})(FontSizeField);
