import React, {useEffect, useState} from 'react';
import {
	ISlideshowAggregatedStats,
	ISlideshowDetailedStats,
} from 'shared/collections/SlideshowStats';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import {methodNames} from 'shared/constants/methodNames';
import {CircularProgress} from '@material-ui/core';
import {useUnmounted} from '../../../hooks';

interface ISlideshowDetailedStatsDialogProps {
	isOpened: boolean;
	details: ISlideshowAggregatedStats['details'][number] | null;
	onClose: () => void;
}

const SlideshowDetailedStatsDialog: React.FC<ISlideshowDetailedStatsDialogProps> = ({
	isOpened,
	details,
	onClose,
}) => {
	const [detailedStats, setDetailedStats] = useState<ISlideshowDetailedStats | null>(null);
	const unmounted = useUnmounted();

	useEffect(() => {
		if (detailedStats) setDetailedStats(null);

		if (details) {
			Meteor.callAsync(methodNames.slideshowStats.getDetailedStats, details.slideshowId).then(
				(res: ISlideshowDetailedStats) => {
					if (unmounted.current) return;

					setDetailedStats(res);
				},
			);
		}
	}, [isOpened]);

	if (!details) return null;

	return (
		<Dialog open={isOpened} onClose={onClose}>
			<DialogTitle>{details.slideshowName}</DialogTitle>
			<DialogContent>
				<pre>
					<div>{`Пользователь – ${details.owner?.userEmail}`}</div>
					<div>{`Всего показов – ${detailedStats?.totalAmountOfViews ??
						details.totalAmountOfViews}`}</div>
					<br />
					<div>{`Всего показов в данный момент – ${detailedStats?.amountOfActiveViews
						?.total ?? details.amountOfActiveViews?.total}`}</div>
					<div>{`	Без учета предпросмотров – ${detailedStats?.amountOfActiveViews?.views ??
						details.amountOfActiveViews?.views}`}</div>
					<div>{`	Предпросмотры – ${detailedStats?.amountOfActiveViews?.previews ??
						details.amountOfActiveViews?.previews}`}</div>
					<br />
					{detailedStats ? (
						<>
							<div>{`Количество слайдов на данный момент – ${detailedStats.slidesCount}`}</div>
							<div>{`Количество элементов на данный момент – ${detailedStats.elementsCount}`}</div>
							<br />
							<div>{`Общее количество показов элементов – ${detailedStats.usedElementsCount}`}</div>
							{detailedStats.elementsUsage.map((elementUsage) => (
								<div>{`	${elementUsage.elementType} – ${elementUsage.amountOfUsage}`}</div>
							))}
						</>
					) : (
						<CircularProgress />
					)}
				</pre>
			</DialogContent>
		</Dialog>
	);
};

export default SlideshowDetailedStatsDialog;
