import React, {ChangeEvent, useEffect, useState} from 'react';
import {Grid, IconButton, TextField} from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './EditableField.scss';
import {isEmpty} from 'lodash';
import {LL} from 'shared/localization';

interface IProps {
	placeholder: string;
	label: string;
	removeField?: (key: number) => void;
	setField: (field: IFieldType) => void;
	field: IFieldType;
	required: boolean;
}

interface IFieldType {
	key: number;
	text: string;
}

export const EditField = ({placeholder, label, removeField, setField, field, required}: IProps) => {
	const [edit, setEdit] = useState(false);
	const [firstTouch, setFirstTouch] = useState(true);
	const [textValue, setTextValue] = useState(field?.text || '');

	useEffect(() => {
		setTextValue(textValue.trim());
	}, [edit]);

	const mappedTextValue = textValue
		?.split('\n')
		.map((line, i) => (line ? <p key={line + i}>{line}</p> : <br key={line + i} />));

	const isRequiredField = !firstTouch && required && isEmpty(textValue);

	const onChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const text = e.target.value;
		setTextValue(text);
		field.text = text;
		setField({...field});
		setFirstTouch(false);
	};

	const onBlurHandler = () => {
		setEdit(false);
		setFirstTouch(false);
	};

	const onClickHandler = () => {
		setEdit(!edit);
	};

	const removeFieldHandler = () => {
		removeField?.(field.key);
	};

	return (
		<>
			<Grid container alignItems="center" className="fieldAddContainer">
				<Grid container alignItems="center" wrap="nowrap">
					{edit ? (
						<TextField
							label={label}
							variant="outlined"
							autoFocus={true}
							onChange={onChangeHandler}
							onBlur={onBlurHandler}
							value={textValue}
							fullWidth={true}
							multiline
							className="multiLine"
							placeholder={placeholder}
						/>
					) : (
						<div onClick={onClickHandler}>
							{!textValue ? <p>{placeholder}</p> : mappedTextValue}
						</div>
					)}
					<IconButton edge="end" onClick={onClickHandler} className="editButton">
						{!edit && <EditRoundedIcon color="primary" />}
					</IconButton>
				</Grid>
				{removeField && (
					<IconButton
						edge="end"
						onClick={removeFieldHandler}
						className="selfRemoveButton"
					>
						<DeleteForeverIcon fontSize="large" style={{color: '#f00'}} />
					</IconButton>
				)}
			</Grid>
			{isRequiredField && <p className="errorText">* {LL('common.ui.requiredField')}!</p>}
		</>
	);
};
