export type LocalizationType = typeof ru;

export const ru = {
	'common.prtv': 'PRTV',
	'common.profile': 'Профиль',
	'common.exit': 'Выйти',
	'common.login': 'Вход',
	'common.registration': 'Регистрация',
	'common.slideShow': 'слайд-шоу',
	'common.header': 'Заголовок',
	'common.apply': 'Применить',
	'common.save': 'Сохранить',
	'common.cancel': 'Отменить',
	'common.edit': 'Редактировать',
	'common.delete': 'Удалить',
	'common.text': 'Текст',
	'common.search': 'Поиск',
	'common.error': 'Ошибка',
	'common.add': 'Добавить',
	'common.title': 'Название',
	'common.yes': 'Да',
	'common.no': 'Нет',
	'common.back': 'Назад',
	'common.name': 'Имя',
	'common.surname': 'Фамилия',
	'common.company': 'Компания',
	'common.telephone': 'Телефон',
	'common.actions': 'Действия',
	'common.password': 'Пароль',
	'common.loading': 'Загрузка...',
	'common.open': 'Открыть',
	'common.video': 'Видео',

	'Asia/Anadyr': 'Анадырь',
	'Asia/Barnaul': 'Барнаул',
	'Asia/Chita': 'Чита',
	'Asia/Irkutsk': 'Иркутск',
	'Asia/Kamchatka': 'Камчатка',
	'Asia/Khandyga': 'Хандыга',
	'Asia/Krasnoyarsk': 'Красноярск',
	'Asia/Magadan': 'Магадан',
	'Asia/Novokuznetsk': 'Новокузнецк',
	'Asia/Novosibirsk': 'Новосибирск',
	'Asia/Omsk': 'Омск',
	'Asia/Sakhalin': 'Сахалин',
	'Asia/Srednekolymsk': 'Среднеколымск',
	'Asia/Tomsk': 'Томск',
	'Asia/Ust-Nera': 'Усть-Нера',
	'Asia/Vladivostok': 'Владивосток',
	'Asia/Yakutsk': 'Якутск',
	'Asia/Yekaterinburg': 'Екатеринбург',
	'Europe/Astrakhan': 'Астрахань',
	'Europe/Kaliningrad': 'Калининград',
	'Europe/Kirov': 'Киров',
	'Europe/Moscow': 'Москва',
	'Europe/Samara': 'Самара',
	'Europe/Saratov': 'Саратов',
	'Europe/Simferopol': 'Симферополь',
	'Europe/Ulyanovsk': 'Ульяновск',
	'Europe/Volgograd': 'Волгоград',

	'homePage.advertisingPlacement':
		'Размещайте объявления на экранах телевизоров в подъездах домов и офисных зданиях.',
	'homePage.signUpFree': 'Зарегистрироваться бесплатно',
	'homePage.opportunities': 'Возможности',
	'homePage.description':
		'Создавайте презентации для телевизоров с огромным числом возможностей в пару кликов. Огромный выбор источников данных: погода, курсы валют, VK, ОК, Facebook и другие.',
	'homePage.personalization': 'Персонализация',
	'homePage.personalization.description':
		'Полная настройка презентации: задавайте цвет фона, шрифт цвет и размер текста, отступы.',
	'homePage.easeOfLearning': 'Легкость в освоении',
	'homePage.easeOfLearning.description':
		'Интерфейс создания презентации интуитивно понятен и вам не составит труда сделать презентацию любой сложности!',
	'homePage.freeSupport': 'Бесплатная поддержка',
	'homePage.freeSupport.description':
		'Обращайтесь к нам по любым вопросам, связанных с нашим приложением',

	'contacts.support-email': 'info@4prtv.ru',
	'contacts.forumUrl': 'https://s.prtv.su/forums/?option=oauthredirect&app_name=prtv',
	'contacts.supportUrl':
		'https://chat.integracio.ru/689d65cc29dc1df3df6dcea76c91ae92/s.prtv.su/ru',
	'errors.server-error': 'Произошла ошибка на сервере. Попробуйте повторить позднее.',
	'pages.contactsTitle': 'Контакты',

	'widgets.errors.select-video': 'Выберите видео для показа плеера.',
	'widgets.errors.inValidLink': 'Некорректная ссылка.',

	'admin.faq.error': 'Поле не может быть пустым',
	'admin.faq.modalTitle': 'Изменение раздела',
	'admin.faq.enterTitle': 'Введите заголовок',
	'admin.faq.enterContent': 'Введите контент',
	'admin.faq.content': 'Контент',

	'admin.news.errorsTitle': '* Нужно задать заголовок.',
	'admin.news.errorsArticle': '* Статья не должна быть пустой.',
	'admin.news.errorsCompare': 'Ничего не изменилось!',
	'admin.news.setTitle': 'Задайте заголовок...',
	'admin.news.writeText': 'Напишите текст',
	'admin.news.noNews': 'Нет новостей для отображения.',
	'admin.news.article': 'Статья',
	'admin.news.updated': 'Обновлена',
	'admin.news.editNews': 'Отредактируйте новость!',
	'admin.news.newNews': 'Новая новость!',
	'admin.news.addNews': 'Добавить новость',

	'admin.screens.noScreens': 'Нет экранов.',
	'admin.screens.user': 'Пользователь',
	'admin.screens.address': 'Адрес',
	'admin.screens.photo': 'Фото',
	'admin.screens.typeOfRoom': 'Вид помещения',
	'admin.screens.dateOfCreation': 'Дата создания',

	'admin.services.deleteQuestion': 'Удалить вопрос?',
	'admin.services.addElement': 'Добавить элемент',
	'admin.services.noData': 'Нет данных для показа',
	'admin.services.listOfServices': 'Загружаем список услуг...',
	'admin.services.deleteOffer': 'Удалить оффер?',
	'admin.services.addPrice': 'Введите цену',
	'admin.services.package': 'Выберите пакет',
	'admin.services.packageSelection': 'Выбор пакетов',
	'admin.services.packageChange': 'Изменение пакета',
	'admin.services.addPackage': 'Добавление пакета',
	'admin.services.toAddPackage': 'Добавить пакет',
	'admin.services.packages': 'Пакеты',
	'admin.services.packageCoast': 'Стоимость пакета для текущего оффера',
	'admin.services.incorrectValue': 'Неверное значение',
	'admin.services.addOffer': 'Добавление оффера',
	'admin.services.toAddOffer': 'Добавить оффер',
	'admin.services.timeLimit': 'Заголовок срока',
	'admin.services.numberOfMonth': 'Количество месяцев',
	'admin.services.priceForMonth': 'Цена за указанный месяц',
	'admin.services.discount': 'Процент скидки',
	'admin.services.defaultSelected': 'Выбрано по умолчанию?',
	'admin.services.serviceTitle': 'Название услуги',
	'admin.services.packageNotFound': 'Пакеты не найдены. Сначала добавьте их',
	'admin.services.noPackages': 'Нет пакетов',
	'admin.services.paidServices': 'Платные услуги',
	'admin.services.price': 'Цена',
	'admin.services.noOffersSelected': 'В выбранной услуге нет офферов',
	'admin.services.noInputParameters': 'Входные параметры не переданы',
	'admin.services.serviceChange': 'Изменение услуги',
	'admin.services.addService': 'Добавление услуги',
	'admin.services.serviceDescription': 'Описание услуги',
	'admin.services.description': 'Описание',

	'admin.surveys.yourOption': 'Ваш вариант',
	'admin.surveys.valueRequired': '* Нужно указать значение',
	'admin.surveys.enterValue': 'Укажите значение',
	'admin.surveys.needToAsk': '* Нужно задать вопрос.',
	'admin.surveys.completeAtList': '* Заполните минимум 2 ответа на вопрос.',
	'admin.surveys.fillInAll': '* Заполните ВСЕ варианты ответов',
	'admin.surveys.newPoll': 'Новый опрос',
	'admin.surveys.toAddPoll': 'Добавить опрос',
	'admin.surveys.askQuestion': 'Задайте вопрос...',
	'admin.surveys.yourQuestion': 'Ваш вопрос',
	'admin.surveys.answerOption': 'Вариант ответа',
	'admin.surveys.yourAnswer': 'Ваш ответ',
	'admin.surveys.addAnswer': 'Добавить ответ',
	'admin.surveys.allowToAnswer':
		'Разрешить ответить самостоятельно (поле ввода текста, куда можно написать свой вариант)',
	'admin.surveys.allowOneAnswer': 'Разрешить только один вариант ответа!',
	'admin.surveys.integration': 'Интеграция с SendPulse',
	'admin.surveys.setVariable': 'Задайте переменную SendPulse',
	'admin.surveys.essenceSurvey': 'Название переменной SendPulse (суть опроса)',
	'admin.surveys.activateSurvey': 'Активировать опрос!',
	'admin.surveys.noSurveysToDisplay': 'Нет опросов для отображения.',
	'admin.surveys.question': 'Вопрос',
	'admin.surveys.customField': 'Кастомное поле',
	'admin.surveys.completedSurvey': 'Прошли опрос',
	'admin.surveys.variableSendPulse': 'Переменная SendPulse',
	'admin.surveys.active': 'Активен',
	'admin.surveys.updated': 'Обновлен',
	'admin.surveys.statistics': 'Статистика',
	'admin.surveys.toDeactivate': 'Деактивировать',
	'admin.surveys.toActivate': 'Активировать',
	'admin.surveys.thisSurvey': 'этот опрос для всех пользователей',
	'admin.surveys.deactivate': 'Деактивация',
	'admin.surveys.activate': 'Активация',
	'admin.surveys.aSurvey': 'опроса для всех пользователей',
	'admin.surveys.editYourSurvey': 'Отредактируйте свой опрос!',
	'admin.surveys.noInformation': 'Нет информации для отображения.',
	'admin.surveys.surveyResults': 'Результаты опроса',
	'admin.surveys.usersCompletedSurvey': 'Пользователи, прошедшие опрос',

	'admin.users.usersRights': 'Права пользователя',
	'admin.users.admin': 'Администратор',
	'admin.users.notAdmin': 'Не является администратором',
	'admin.users.removeRights': 'Убрать права',
	'admin.users.giveRights': 'Дать права',
	'admin.users.ofAdmin': 'администратора',
	'admin.users.redactor': 'Редактор шаблонов',
	'admin.users.notRedactor': 'Не является редактором шаблонов',
	'admin.users.newPasswordSet': 'Новый пароль установлен!',
	'admin.users.toChangePass': 'Смена пароля',
	'admin.users.passwordMismatch': 'Пароли не совпадают',
	'admin.users.atListPass': 'Не менее 6 символов',
	'admin.users.noMorePass': 'Не более 20 символов',
	'admin.users.newPass': 'Новый пароль',
	'admin.users.repeatPass': 'Повторите пароль',
	'admin.users.changePass': 'Сменить пароль',
	'admin.users.profileSaved': 'Профиль сохранен',
	'admin.users.profileEditing': 'Редактирование профиля',
	'admin.users.userDeleted': 'Пользователь удален',
	'admin.users.temporarilyNotWorking': 'Временно не работает',
	'admin.users.noUsersToDisplay': 'Нет пользователей для отображения.',
	'admin.users.registrationDate': 'Дата регистрации',
	'admin.users.fullName': 'ФИО',
	'admin.users.loginAcc': 'Войти в личный кабинет',
	'admin.users.approve': 'Одобрить',
	'admin.users.block': 'Заблокировать',

	'auth.emailNotFound': 'E-mail не найден',
	'auth.errRecoveryPass': 'Ошибка восстановления пароля',
	'auth.accessRecovery': 'Восстановление доступа',
	'auth.accessRecoveryDescription':
		'Чтобы восстановить доступ, укажите E-mail, который вы использовали при регистрации. Мы отправим на него инструкции по восстановлению пароля.',
	'auth.send': 'Отправить',
	'auth.emailSent': 'На вашу почту было отправлено письмо с инструкциями.',
	'auth.errEmailPass': 'Введен неверный E-mail или пароль',
	'auth.accessBlocked': 'Доступ заблокирован',
	'auth.forgotPass': 'Забыл пароль',
	'auth.termsOfUse': 'Пользовательское соглашение',
	'auth.privacyPolicy': 'Политика конфиденциальности',
	'auth.pricesForServices': 'Цены на услуги',
	'auth.regDescription': 'The user already exists, try "Log In" or "Forgot password"',
	'auth.register': 'Зарегистрироваться',
	'auth.newPass': 'Ввод нового пароля',

	'components.SelectSS': 'Выберите слайд-шоу',
	'components.firsChooseSS': 'Сперва выберите слайд-шоу!',
	'components.showDate': 'Дата показа',
	'components.showStartDate': 'Дата начала показа',
	'components.showEndDate': 'Дата окончания показа',
	'components.allDay': 'Весь день',
	'components.showWarning': 'Дата окончания показа должна быть позже даты начала',

	'components.repeat': 'Повторять',
	'components.notRepeat': 'Не повторять',
	'components.daily': 'Ежедневно',
	'components.weekly': 'Еженедельно',
	'components.monthly': 'Ежемесячно',
	'components.yearly': 'Ежегодно',
	'components.daysOfWeek': 'Дни недели',
	'components.howManyTimes': 'Сколько раз',
	'components.untilDate': 'До даты',
	'components.impressionWarning': 'Обнаружены наложения показов!',

	'messages.today': 'Сегодня',
	'messages.repeat': 'Повторить',
	'messages.allTasks': 'Все задачи',
	'messages.current': 'Текущий',
	'messages.currentAndFollowing': 'Текущий и последующие',
	'messages.menuDeletingTitle': 'Изменение повторяющейся задачи',
	'methods.errorMessage.destination': 'Не верно указанно место назначения',
	'methods.errorMessage.departure': 'Не верно указанно место отправления',

	'common.userHeader.slideShow': 'Слайд-шоу',
	'common.userHeader.layouts': 'Макеты',
	'common.userHeader.collections': 'Подборки',
	'common.userHeader.streams': 'Потоки',
	'common.userHeader.videoHosting': 'Видеохостинг',
	'common.userHeader.informers': 'Информеры',
	'common.userHeader.instructions': 'Инструкции',
	'common.userHeader.technicalSupport': 'Тех.поддержка',
	'common.userHeader.forum': 'Форум',
	'common.userHeader.contacts': 'Контакты',
	'common.userHeader.myTariff': 'Мой тариф',

	'common.checkoutHeader.tariffPayment': 'Оплата тарифа',
	'common.checkoutSlideshowHeader.slideShowPurchase': 'Покупка слайд-шоу',

	'common.adminHeader.controlPanel': 'Панель управления',
	'common.adminHeader.users': 'Пользователи',
	'common.adminHeader.apps': 'Экраны',
	'common.adminHeader.services': 'Услуги',
	'common.adminHeader.surveys': 'Опросы',
	'common.adminHeader.news': 'Новости',

	'common.ui.fileLarger': 'Размер файла больше положенного',
	'common.ui.unsupportedFormat': 'Неподдерживаемый формат',
	'common.ui.selectVideo': 'Выбрать видео',
	'common.ui.uploadVideo':
		'Вы можете загрузить видео следующих форматов: MP4, AVI и размером не более 300МБ',
	'common.ui.uploadVideoError': 'Размер файла превышает максимально допустимый',
	'common.ui.uploadImageError':
		'Во время загрузки изображения возникла ошибка, повторите попытку',
	'common.ui.choose': 'Выбрать',
	'common.ui.change': 'Изменить',
	'common.ui.onComputer': 'На компьютере',
	'common.ui.fromCollection': 'Из коллекции',
	'common.ui.voted': 'Проголосовали',
	'common.ui.requiredField': 'Обязательное поле',

	'editor.element.fromWhere': 'Откуда',
	'editor.element.where': 'Куда',
	'editor.element.typeOfTransport': 'Тип транспорта',
	'editor.element.airplane': 'Самолет',
	'editor.element.train': 'Поезд',
	'editor.element.electricTrain': 'Электричка',
	'editor.element.bus': 'Автобус',
	'editor.element.output': 'Выводить',
	'editor.element.telegramId': 'ID Телеграм Канала',
	'editor.element.hideText': 'Скрыть текст',
	'editor.element.hideText.tooltip': 'Позволяет отображать только фотографии из публикаций',
	'editor.element.hidePictures': 'Скрыть картинки',
	'editor.element.hidePictures.tooltip': 'Позволяет отображать только текст из публикаций',
	'editor.element.numberOfMessages': 'Количество сообщений',
	'editor.element.numberOfMessages.tooltip':
		'Задает количество последних сообщений, выводимых в виджете',

	'editor.element.textAlignment': 'Выравнивание текста',
	'editor.element.textLineHeight': 'Высота линии текста',
	'editor.element.letterSpacing': 'Межбуквенный интервал',
	'editor.element.indentation': 'Отступ',
	'editor.element.lineText': 'Текст строки',
	'editor.element.scrollSpeed': 'Скорость прокрутки',
	'editor.element.scrollDirection': 'Направление прокрутки',
	'editor.element.service': 'Сервис',
	'editor.element.scale': 'Масштаб',
	'editor.element.toDisplay': 'Отображать',
	'editor.element.userLists': 'Списки пользователя',
	'editor.element.selectList': 'Выберите список',
	'editor.element.userCollections': 'Коллекции пользователя',
	'editor.element.selectCollection': 'Выберите коллекцию',
	'editor.element.outputtingTweets': 'Вывод твитов',
	'editor.element.outputtingTweets.tooltip': `Поддерживаемые форматы:<br />
	Тег - например mtv (без #)<br />
	Имя пользователя - ivan133 (без @)<br />
	Список - можно посмотреть по адресу https://twitter.com/ivan133/lists<br />
	или создать на сервисе https://tweetdeck.twitter.com/<br />
	Коллекция - можно создать на https://tweetdeck.twitter.com/ (collection)<br />`,
	'editor.element.errorEnterLink': 'Введите ссылку',
	'editor.element.errorInvalidVideoLink': 'Неверный формат ссылки на видео',
	'editor.element.errorUploadVimeo':
		'Загрузка видео через vimeo - платный функционал. Ознакомьтесь с тарифами на странице',
	'editor.element.myTariff': 'Мой тариф',
	'editor.element.videoHosting': 'Видеохостинг',
	'editor.element.linkVideoPlaylist': 'Ссылка на видео, плейлист или канал',
	'editor.element.linkVideoPlaylist.tooltip': `Поддерживаемые форматы:<br />
Ссылка на канал
https://www.youtube.com/channel/UCSyg9cb3Iq-NtlbxqNB9wGw<br />
ID канала https://www.youtube.com/watch?v=Sw3B6MQMjSU<br />
Короткая ссылка https://youtu.be/iYfVcD-GPT8<br />
Обычная ссылка https://www.youtube.com/watch?v=YqeW9_5kURI<br />
Плейлист
https://www.youtube.com/playlist?list=PLyFnbgoS1oYc_D7rRQWKYzAaTt7wbkoZL<br />
Встроенная ссылка
https://www.youtube.com/embed/videoseries?list=PLh_0hw-7CaS9Q0QZQ0IFsAawORIxeRzli<br />
Ид канала https://www.youtube.com/@FFChannel_Official`,
	'editor.element.linkToVideo': 'Ссылка на видео',
	'editor.element.linkToVideo.tooltip': `<p>Поддерживаемые форматы:</p><br />
<p>https://vk.com/video-00000000_000000000</p><br />
<p>\thttps://vk.com/group_name?z=video-00000000_000000000%2Fvideos-00000000%2Fpl_-00000000_-2
</p><br />
<p>https://vk.com/clips-00000000?z=clip-00000000_000000000</p>`,
	'editor.element.numberOfVideos': 'Количество роликов',
	'editor.element.display': 'Отображение',
	'editor.element.weatherItemNoSupported':
		'Этот элемент больше не поддерживается. Удалите его и используйте новый виджет погоды',
	'editor.element.cityOrTown': 'Город или город',
	'editor.element.hideTitle': 'Скрыть заголовок',
	'editor.element.iconSet': 'Набор иконок',
	'editor.element.iconSet.tooltip': 'Позволяет выбрать один из нескольких наборов иконок',
	'editor.element.additionalParameters': 'Показывать доп параметры',
	'editor.element.newLine': 'С новой строки',
	'editor.element.namesPairs': 'Названия парам.',
	'editor.element.iconPairs': 'Иконки парам.',
	'editor.element.widgetOption': 'Вариант виджета',
	'editor.element.widgetOption.tooltip':
		'Позволяет выбрать из нескольких вариантов дизайнов виджета',
	'editor.element.day': 'День',
	'editor.element.day.tooltip': 'Позволяет выбрать за какой день выводить прогноз',
	'editor.element.tomorrow': 'Завтра',
	'editor.element.afterTomorrow': 'Послезавтра',
	'editor.element.4thDay': '4-й день',
	'editor.element.5thDay': '5-й день',
	'editor.element.tags': 'Метки',
	'editor.element.time': 'Время',
	'editor.element.temperature': 'Температура',
	'editor.element.header.tooltip': 'Настройки отображения шрифта заголовка',
	'editor.element.time.tooltip': 'Настройки отображения шрифта времени',
	'editor.element.temp.tooltip': 'Настройки отображения шрифта температуры',
	'editor.element.text.tooltip': 'Настройки отображения шрифта текста',
	'editor.element.element.tooltip': 'Настройки отображения шрифта элемента',
	'editor.element.meetingNumber': 'Номер встречи',
	'editor.element.displayName': 'Отображаемое имя',
	'editor.element.mail': 'Почта',
	'editor.element.logoCorner': 'Логотип в углу',
	'editor.element.logoLocation': 'Расположение логотипа',
	'editor.element.topLeftCorner': 'Верхний-левый угол',
	'editor.element.topRightCorner': 'Верхний-правый угол',
	'editor.element.bottomLeftCorner': 'Нижний-левый угол',
	'editor.element.bottomRightCorner': 'Нижний-правый угол',
	'editor.element.saveProportions': 'Сохранить пропорции',
	'editor.element.saveProportions.tooltip':
		'Если данная опция включена, при изменении размера элемента пропорции будут сохранены',

	'editor.modals.setLinkWhenClick': 'Задать ссылку при клике на элемент',
	'editor.modals.link': 'ссылка',
	'editor.modals.removeClickability': 'Убрать кликабельность',
	'editor.modals.addFromSaved': 'Добавление из сохранённых',
	'editor.modals.chooseLayout': 'Выберете макет',
	'editor.modals.systemic': 'Системный',
	'editor.modals.createSlide': 'Создать слайд',
	'editor.modals.setElementIdForAdCompany':
		'Задать идентификатор элемента для рекламной компании',
	'editor.modals.elementIdForAdCompany': 'идентификатор элемента для рекламной компании',
	'editor.modals.setElementAnimation': 'Настройка анимации элемента',
	'editor.modals.animationStyle': 'Стиль анимации',
	'editor.modals.transitionDelay': 'Задержка до начала анимации (мс)',
	'editor.modals.transitionDuration': 'Длительность анимации (мс)',
	'editor.modals.transitionCount': 'Количество повторов анимации  (0 - бесконечно)',
	'editor.modals.setElementStyle': 'Настройка стиля элемента',
	'editor.modals.update': 'Обновить',
	'editor.modals.vectorElCollection': 'Коллекция векторных элементов',
	'editor.modals.slideDeletionConfirm': 'Подтвердите удаление слайда',
	'editor.modals.slideSaving': 'Сохранение слайда',
	'editor.modals.slideDeleting': 'Удаление слайда',
	'editor.modals.slideTitle': 'Название слайда',
	'editor.modals.slideSettings': 'Настройки слайда',
	'editor.modals.filter': 'Фильтр',
};
