import {EventDropArg} from '@fullcalendar/core';
import moment from 'moment';
import {Meteor} from 'meteor/meteor';
import {methodNames} from 'shared/constants/methodNames';
import {IResponseError, IResponseSuccess} from 'shared/models/Response';

export default (slideStreamId: string, {event, delta, revert}: EventDropArg) => {
	const {rawEvent} = event.extendedProps;

	let startDate: Date;
	let endDate: Date;
	let date: string | undefined;

	if (event.allDay) {
		date = moment(rawEvent.date)
			.add(delta.days, 'days')
			.format('YYYY-MM-DD');
		startDate = moment(date)
			.startOf('day')
			.toDate();
		endDate = moment(date)
			.endOf('day')
			.toDate();
	} else {
		startDate = moment(rawEvent.startDate)
			.add(delta.milliseconds, 'milliseconds')
			.add(delta.days, 'days')
			.toDate();
		endDate = moment(rawEvent.endDate)
			.add(delta.milliseconds, 'milliseconds')
			.add(delta.days, 'days')
			.toDate();
	}

	const payload = {
		...rawEvent,
		slideStreamId,
		startDate,
		endDate,
		date,
	};

	Meteor.call(
		methodNames.slideStream.updateSchedule,
		payload,
		(_: unknown, res: IResponseSuccess | IResponseError) => {
			if ('error' in res && res.error) revert();
		},
	);
};
