import React, {useEffect} from 'react';

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {LL} from 'shared/localization';

export const ChartCanvas = ({chartData, caption}) => {
	useEffect(() => {
		new Chart(document.getElementById('line-chart'), {
			type: 'bar', //'doughnut',
			heigh: 20,
			data: {
				labels: Object.keys(chartData),
				datasets: [
					{
						label: LL('common.ui.voted'),
						backgroundColor: [
							'#4dc9f685',
							'#f6701985',
							'#f5379485',
							'#537bc485',
							'#acc23685',
							'#166a8f85',
							'#00a95085',
							'#58595b85',
							'#8549ba85',
						],
						data: Object.values(chartData),
					},
				],
			},
			plugins: [ChartDataLabels],
			options: {
				responsive: true,
				datalabels: {
					display: true,
				},
				maintainAspectRatio: false,
				plugins: {
					legend: {
						position: 'top',
					},
					title: {
						display: true,
						text: caption,
					},
					datalabels: {
						backgroundColor: function(context) {
							return context.dataset.backgroundColor;
						},
						borderColor: 'white',
						borderRadius: 25,
						borderWidth: 2,
						color: 'white',
						anchor: 'end',
						align: 'center',
						display: true,
						font: {
							weight: 'bold',
						},
						padding: {
							top: 6,
							right: 10,
							left: 10,
							bottom: 6,
						},
						formatter: Math.round,
					},
				},
			},
		});
	}, []);

	return <canvas id="line-chart"></canvas>;
};
