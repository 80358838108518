exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".inputOuter--2UzF3 {\n\twidth: 100%;\n\tpadding-bottom: 15px;\n\tposition: relative;\n}\n\n.input--3Aan7 {\n\tdisplay: none !important;\n}\n\n.button--3zhyW {\n\tposition: relative;\n\twidth: 100%;\n\theight: 28px;\n\tcursor: pointer;\n\tbackground: url(/img/transparent-bg.png) repeat;\n\tborder: none;\n}\n\n.button--3zhyW .colorIndicator--EQce5 {\n\t\tposition: absolute;\n\t\tleft: 0;\n\t\tright: 0;\n\t\ttop: 0;\n\t\tbottom: 0;\n\t\tbackground: transparent;\n\t}\n\n.picker--Czzgd {\n\tz-index: 2;\n\tposition: absolute;\n}\n\n.cover--1cmEm {\n\tposition: fixed;\n\tleft: 0;\n\ttop: 0;\n\tright: 0;\n\tbottom: 0;\n}\n\n.reset--2Bccl {\n\tposition: absolute;\n\tright: 0;\n\tbottom: 14px;\n\tpadding: 4px;\n\tcursor: pointer;\n\tcolor: #fff;\n\tbackground: rgba(0, 0, 0, .3);\n\topacity: .3;\n\t-webkit-transition: .2s ease-in-out;\n\t-moz-transition: .2s ease-in-out;\n\ttransition: .2s ease-in-out;\n}\n\n.reset--2Bccl:hover {\n\t\topacity: 1;\n\t}\n\n.reset--2Bccl svg {\n\t\tfont-size: 20px;\n\t}", ""]);

// Exports
exports.locals = {
	"inputOuter": "inputOuter--2UzF3",
	"input": "input--3Aan7",
	"button": "button--3zhyW",
	"colorIndicator": "colorIndicator--EQce5",
	"picker": "picker--Czzgd",
	"cover": "cover--1cmEm",
	"reset": "reset--2Bccl"
};