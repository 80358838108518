import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default React.memo(({pathProps, ...restProps}) => (
	<SvgIcon {...restProps} xmlns="http://www.w3.org/2000/svg">
		<path
			{...pathProps}
			d="M12.1667 3.83337H3.83332V6.33337C3.83332 6.7917 3.45832 7.1667 2.99999 7.1667C2.54165 7.1667 2.16665 6.7917 2.16665 6.33337V3.00003C2.16665 2.5417 2.54165 2.1667 2.99999 2.1667H12.1667V0.675032C12.1667 0.300032 12.6167 0.116699 12.875 0.375032L15.2 2.70003C15.3667 2.8667 15.3667 3.12503 15.2 3.2917L12.875 5.6167C12.6167 5.88337 12.1667 5.70003 12.1667 5.32503V3.83337ZM3.83332 12.1667H12.1667V9.6667C12.1667 9.20837 12.5417 8.83337 13 8.83337C13.4583 8.83337 13.8333 9.20837 13.8333 9.6667V13C13.8333 13.4584 13.4583 13.8334 13 13.8334H3.83332V15.325C3.83332 15.7 3.38332 15.8834 3.12499 15.625L0.799988 13.3C0.633321 13.1334 0.633321 12.875 0.799988 12.7084L3.12499 10.3834C3.38332 10.1167 3.83332 10.3 3.83332 10.675V12.1667Z"
		/>
	</SvgIcon>
));
