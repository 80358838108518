import {Class} from 'meteor/jagi:astronomy';

export const News = Class.create({
	name: 'News',
	collection: new Mongo.Collection('news'),
	fields: {
		title: {
			type: String,
			optional: true,
		},
		article: String,
	},
	behaviors: {
		timestamp: {
			hasCreatedField: true,
			hasUpdatedField: true,
			createdFieldName: 'createdAt',
			updatedFieldName: 'updatedAt',
		},
	},
});
