import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default React.memo(({pathProps, ...restProps}) => (
	<SvgIcon {...restProps}>
		<path
			{...pathProps}
			d="M9.00004 5.66671H5.66671V9.00004C5.66671 9.36671 5.36671 9.66671 5.00004 9.66671C4.63337 9.66671 4.33337 9.36671 4.33337 9.00004V5.66671H1.00004C0.633374 5.66671 0.333374 5.36671 0.333374 5.00004C0.333374 4.63337 0.633374 4.33337 1.00004 4.33337H4.33337V1.00004C4.33337 0.633374 4.63337 0.333374 5.00004 0.333374C5.36671 0.333374 5.66671 0.633374 5.66671 1.00004V4.33337H9.00004C9.36671 4.33337 9.66671 4.63337 9.66671 5.00004C9.66671 5.36671 9.36671 5.66671 9.00004 5.66671Z"
		/>
	</SvgIcon>
));
