import React, {useState, useEffect} from 'react';
import {Field, ErrorMessage} from 'formik';
import Input from 'client/components/common/ui/Input';

import './Checkboxes.scss';

export const Checkboxes = ({checkboxes, name, addManualCheckbox, type = 'checkbox'}) => {
	const [customCaseShow, setCustomCaseShow] = useState(type === 'radio');
	const {checkboxLabel, input} = addManualCheckbox || {};

	return (
		<>
			<div
				className="dialog-container two-columns"
				role="group"
				aria-labelledby="my-radio-group"
			>
				{checkboxes.map((option, i) => (
					<label className="checkbox-label">
						<Field
							type={type}
							name={type === 'radio' ? 'picked' : `${name}[${i}]`}
							value={option.label || option}
						/>
						{`${option.label || option}`}
					</label>
				))}
			</div>
			<div className="dialog-container">
				{type === 'checkbox' && addManualCheckbox && (
					<label className="checkbox-label">
						<Field
							type={type}
							name={`${name}[${checkboxes.length}]`}
							onChange={() => setCustomCaseShow(!customCaseShow)}
						/>
						{`${checkboxLabel}!`}
					</label>
				)}
			</div>
			<ErrorMessage component="p" name={name} className="error" />
			{input && (
				<div className={customCaseShow ? 'dialog-container' : 'dialog-container hidden'}>
					<Input
						name={type === 'radio' ? 'picked' : input.name}
						label={input.label}
						className="text-field"
						helperText={input.helperText}
					/>
				</div>
			)}
		</>
	);
};
