import React, {useState, useEffect} from 'react';
import {withTracker} from 'react-meteor-data-with-tracker';
import {RouteComponentProps, Link, useRouteMatch, useHistory} from 'react-router-dom';
import {Meteor} from 'meteor/meteor';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from 'client/components/common/icons/LockIcon';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Typography from '@material-ui/core/Typography';
import {push} from 'connected-react-router';
import Button from '@material-ui/core/Button';
import CloneIcon from '@material-ui/icons/Layers';
import SendIcon from '@material-ui/icons/Send';
import RepeatIcon from '@material-ui/icons/Repeat';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import {connect} from 'react-redux';
import {Switch, Grid, AppBar, Toolbar} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';

import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

import {format} from 'shared/utils/dates';
import {publishNames} from 'shared/constants/publishNames';
import routerUrls from 'client/constants/routerUrls';
import DeleteAppDialog from './DeleteAppDialog';
import ChangeAppNumberDialog from './ChangeAppNumberDialog';
import {ISlideshow, Slideshow, SlideshowLocationText} from 'shared/collections/Slideshows';
import {
	createSlideshow,
	toggleEditorSettingsPanel,
	updateSlideshowParams,
} from 'client/actions/slideShowEditor';
import PageTitle from 'client/components/common/PageTitle';
import {methodNames} from 'shared/constants/methodNames';
import {checkAdmin, checkRole} from 'shared/utils/user';
// @ts-ignore
import css from './SlideshowListPage.pcss';
import SendSlideShowDialog from 'client/components/user/screens/SendSlideShowDialog';
import AddSlideShowGroupDialog from 'client/components/user/screens/AddSlideShowGroupDialog';
import EditSlideShowGroupDialog from 'client/components/user/screens/EditSlideShowGroupDialog';
import {QuizDialog} from 'client/components/user/screens/QuizDialog';
import {Quiz} from 'shared/collections/Quizzes';
import {Group, IGroup} from 'shared/collections/Groups';
import appConsts from 'client/constants/appConsts';
import {PaidServicePackagesEnum} from 'shared/collections/PaidServices';
import getFileUrl from 'client/utils/getFileUrl';

interface IScreensPageData {
	groups: IGroup[];
	loading: boolean;
	quizzes: Array<Object>;
	slideshowList: ISlideshow[];
}

interface IScreensPageProps extends RouteComponentProps {
	createSlideshow: (groupId: string) => Promise<any>;
	push: typeof push;
	toggleEditorSettingsPanel: typeof toggleEditorSettingsPanel;
	updateSlideshowParams: typeof updateSlideshowParams;
}

const SlideshowListPage: React.FC<IScreensPageProps & IScreensPageData> = (props) => {
	const {loading, groups, quizzes} = props;
	const {slideshowList} = props;
	const [sendSlideShowDialog, setSendSlideShowDialog] = useState<null | string>(null);
	const [changeSlideNumberShowDialog, setChangeSlideNumberShowDialog] = useState<null | string>(
		null,
	);
	const [addSlideShowGroupDialog, setAddSlideShowGroupDialog] = useState(false);
	const [editSlideShowGroupDialog, setEditSlideShowGroupDialog] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState('');
	const [isStatisticsVisible, setStatisticsVisible] = useState(false);
	const [isStatisticsGranted, setStatisticsGranted] = useState(false);

	const [isWaitingSort, setWaitingSort] = useState(false);

	const {
		params: {groupId},
	} = useRouteMatch();
	const history = useHistory();

	useEffect(() => {
		setStatisticsVisible(true);
		Meteor.call(
			methodNames.paidServices.isGranted,
			[PaidServicePackagesEnum.STATISTICS],
			(error: Error | Meteor.Error, response: boolean) => {
				setStatisticsGranted(!!response[PaidServicePackagesEnum.STATISTICS]);
			},
		);
	}, []);

	useEffect(() => {
		if (!groups.length) {
			return;
		}

		setSelectedGroup(groupId);
	}, [loading]);

	const slideshowListByGroup = slideshowList
		.filter(({groupId: gId}) => gId === selectedGroup)
		.sort(({sort}, {sort: sort2}) => sort - sort2);

	const handleCloseAddSlideShowDialog = () => setAddSlideShowGroupDialog(false);
	const handleCloseEditSlideShowDialog = () => setEditSlideShowGroupDialog(false);
	const handleSuccessEditSlideShowDialog = () => {
		setEditSlideShowGroupDialog(false);
	};
	const handleDeleteEditSlideShowDialog = () => {
		setEditSlideShowGroupDialog(false);
		setSelectedGroup(groups[0]._id);
	};

	const handleCloseSendSlideShowDialog = () => setSendSlideShowDialog(null);
	const handleSuccessSendSlideShowDialog = () => setSendSlideShowDialog(null);

	const handleCloseChangeSlideNumberShowDialog = () => setChangeSlideNumberShowDialog(null);
	const handleSuccessChangeSlideNumberShowDialog = () => setChangeSlideNumberShowDialog(null);

	const handlePreviewApp = (orientation: string, appId: string) => (event: React.MouseEvent) => {
		// preview the app in a separate window to imitate TV
		event.preventDefault();
		let height = 720;
		let verticalWidth;

		if (orientation === 'vertical') {
			height = 850;
			verticalWidth = (height / 16) * 9;
		}

		const url = routerUrls.userViewSlideshow.replace(':id', appId);
		window.open(
			url + '?pr=1',
			'prtvPreview',
			`width=${verticalWidth || 1280},height=${height},toolbar=0,location=0,menubar=0`,
		);
	};

	const handleCopyApp = (slideshowId: string) => () => {
		Meteor.call(
			methodNames.slideshow.copySlideShow,
			slideshowId,
			(error: Error | Meteor.Error) => {
				if (error) {
					console.log('Ошибка');
				}
			},
		);
	};

	const handleSendApp = (slideshowId: string) => () => setSendSlideShowDialog(slideshowId);
	const handleChangeNumber = (slideshowId: string) => () =>
		setChangeSlideNumberShowDialog(slideshowId);

	const handleToggleSystem = (slideshow: ISlideshow) => () => {
		props.updateSlideshowParams(slideshow, {isSystem: !slideshow.isSystem});
	};

	const reorderSlideshows = () => {
		setWaitingSort(true);
		Meteor.call(
			methodNames.service.orderSlideshows,
			(error: Error | Meteor.Error, response: boolean) => {
				setWaitingSort(false);
				console.log({error, response});
				// setStatisticsGranted(!!response[PaidServicePackagesEnum.STATISTICS]);
			},
		);
	};

	const onBeforeDragStart = () => () => {};
	const onDragStart = () => () => {};
	const onDragUpdate = () => () => {};
	const onDragEnd = (result) => {
		// console.log('onDragEnd', result);
		if (!result.destination || !result.destination.droppableId) return;
		let from = result.source.index;
		let to = result.destination.index;
		if (from === to) return;
		let dir = from > to;

		// console.log({from, to});

		let slideshows = slideshowListByGroup.filter(
			({sort, _id}) =>
				sort < 0 ||
				_id === result.draggableId ||
				(dir ? sort >= to && sort <= from : sort >= from && sort <= to),
		);
		// .sort(({sort}, {sort: sort2}) => sort - sort2);
		if (!(slideshows && slideshows.length > 0)) return;

		// console.log({slideshows});

		let sorter = dir ? to + 1 : from - 1;
		slideshows.forEach((slideshow, i) => {
			if (result.draggableId === slideshow._id) {
				slideshow.sort = result.destination.index;
			} else {
				slideshow.sort = sorter + i;
				// i++;
			}
			// console.log({name: slideshow.name, sort: slideshow.sort});
			props.updateSlideshowParams(slideshow, {sort: slideshow.sort});
		});
	};

	const getPageContent = () => {
		if (loading) {
			return (
				<div className={css.centre}>
					<Typography variant="h6" gutterBottom>
						Загружаем ваши слайд-шоу...
					</Typography>

					<CircularProgress />
				</div>
			);
		}

		if (slideshowList && !slideshowList.length) {
			return (
				<div className={css.centre}>
					<Typography variant="h6" gutterBottom>
						У вас еще нет слайд-шоу
					</Typography>

					<Button
						className={css.createFirstSlideshowButton}
						variant="outlined"
						color="primary"
						size="large"
						onClick={handleCreateSlideshow}
					>
						Создать первое слайд-шоу
					</Button>
				</div>
			);
		}
		const isAdmin = checkAdmin();
		const canViewSystem = checkRole(undefined, 'template_editor') || isAdmin;
		// console.log({slideshowListByGroup});
		return (
			<Card className={css.tableCard}>
				<AppBar position="static" color="default" className={css.groupsAppBar}>
					<Toolbar disableGutters variant={'dense'}>
						<Tabs
							value={
								groups.find(({_id}) => _id === selectedGroup)
									? selectedGroup
									: groups[0]._id
							}
							indicatorColor="primary"
							textColor="primary"
							variant={'scrollable'}
							scrollButtons="desktop"
							TabIndicatorProps={{children: <div />}}
						>
							{groups.map(({_id, name}) => {
								const handleSelectGroup = () => {
									setSelectedGroup(_id);
									history.push(
										routerUrls.userSlideshowGroup.replace(':groupId', _id),
									);
								};

								const handleEditSlideShowGroup = (e: React.ChangeEvent<{}>) => {
									e.preventDefault();

									setEditSlideShowGroupDialog(true);
								};

								return (
									<Tab
										key={_id}
										classes={{
											root: css.groupTab,
											textColorPrimary: css.groupName,
											selected: css.selected,
										}}
										label={
											<Grid container alignItems={'center'}>
												<Grid item sm>
													{name}
												</Grid>

												{_id === selectedGroup && (
													<Grid item>
														<Tooltip title="Изменить имя группы" arrow>
															<IconButton
																onClick={handleEditSlideShowGroup}
																className={css.groupIcon}
															>
																<SettingsIcon fontSize={'small'} />
															</IconButton>
														</Tooltip>
													</Grid>
												)}
											</Grid>
										}
										value={_id}
										onClick={handleSelectGroup}
									/>
								);
							})}
						</Tabs>

						<Tooltip title="Добавить группу" arrow>
							<IconButton
								onClick={() => setAddSlideShowGroupDialog(true)}
								className={css.addGroupButton}
							>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</Toolbar>
				</AppBar>

				{!slideshowListByGroup.length && (
					<>
						<Typography align="center" gutterBottom>
							В выбранной группе нет слайд-шоу
						</Typography>

						<Button
							className={css.createFirstSlideshowButton}
							variant="outlined"
							color="primary"
							size="large"
							onClick={handleCreateSlideshow}
						>
							Создать слайд-шоу
						</Button>
					</>
				)}

				{!!slideshowListByGroup.length && (
					<Table size="small" className={css.table}>
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell>Фото</TableCell>
								<TableCell>Название</TableCell>
								<TableCell>Адрес</TableCell>
								{false && <TableCell>Вид помещения</TableCell>}
								<TableCell>Дата создания</TableCell>
								<TableCell>Номер для ТВ</TableCell>
								<TableCell style={{width: 280}}>Действия</TableCell>
								{canViewSystem && <TableCell>Системный</TableCell>}
							</TableRow>
						</TableHead>

						<DragDropContext
							onBeforeDragStart={onBeforeDragStart}
							onDragStart={onDragStart}
							onDragUpdate={onDragUpdate}
							onDragEnd={onDragEnd}
						>
							<Droppable
								isCombineEnabled
								droppableId={`slideshows`}
								type="slideshow"
								key={`slideshows_place`}
							>
								{(prov, snap) => {
									return (
										<tbody
											ref={prov.innerRef}
											style={{
												backgroundColor: snap.isDraggingOver ? '#eee' : '',
												borderColor: '#ddd',
											}}
											className={'MuiTableBody-root'}
											{...prov.droppableProps}
										>
											{slideshowListByGroup
												.filter(({isSystem}) => !isSystem || canViewSystem)
												.map((slideshow, i) => (
													<Draggable
														draggableId={slideshow._id}
														index={i}
														key={`show_${slideshow._id}`}
													>
														{(provided, snapshot) => (
															<tr
																ref={provided.innerRef}
																{...provided.draggableProps}
																className={
																	snapshot.isDragging
																		? 'dragging'
																		: 'MuiTableRow-root'
																}
																key={slideshow._id}
															>
																<TableCell>
																	<div
																		className={css.draghandler}
																		{...provided.dragHandleProps}
																	>
																		<div></div>
																		<div></div>
																		<div></div>
																		<div></div>
																		<div></div>
																		<div></div>
																		<div></div>
																		<div></div>
																	</div>
																</TableCell>
																<TableCell>
																	<div className="small-photo">
																		<img
																			src={
																				slideshow.previewImage
																					? getFileUrl(
																							slideshow.previewImage,
																					  )
																					: `${appConsts.imgUrl}/not_slideshow_img.png`
																			}
																			alt=""
																		/>
																	</div>
																</TableCell>

																<TableCell>
																	{slideshow.name}
																</TableCell>
																<TableCell>
																	{slideshow.address}
																</TableCell>
																{false && (
																	<TableCell>
																		{
																			SlideshowLocationText[
																				slideshow.location
																			]
																		}
																		}
																	</TableCell>
																)}
																<TableCell>
																	{format(
																		slideshow.createdAt,
																		'd MMM yyyy',
																	)}
																</TableCell>

																<TableCell>
																	prtv.su/
																	{slideshow.numId}
																</TableCell>

																<TableCell>
																	<Tooltip
																		title="Предварительный просмотр"
																		arrow
																	>
																		<IconButton
																			onClick={handlePreviewApp(
																				slideshow.orientation,
																				slideshow.numId,
																			)}
																		>
																			<RemoveRedEyeIcon />
																		</IconButton>
																	</Tooltip>

																	<Tooltip
																		title="Редактировать"
																		arrow
																	>
																		<Link
																			to={routerUrls.userEditSlideshow.replace(
																				':id',
																				slideshow._id,
																			)}
																		>
																			<IconButton>
																				<EditIcon />
																			</IconButton>
																		</Link>
																	</Tooltip>

																	<Tooltip
																		title="Клонировать"
																		arrow
																	>
																		<IconButton
																			onClick={handleCopyApp(
																				slideshow._id,
																			)}
																		>
																			<CloneIcon />
																		</IconButton>
																	</Tooltip>

																	{!slideshow.isSystem && (
																		<Tooltip
																			title="Передать другому пользователю"
																			arrow
																		>
																			<IconButton
																				onClick={handleSendApp(
																					slideshow._id,
																				)}
																			>
																				<SendIcon />
																			</IconButton>
																		</Tooltip>
																	)}

																	<DeleteAppDialog
																		slideshowId={slideshow._id}
																	/>
																	{isAdmin && (
																		<Tooltip
																			title="Изменить номер"
																			arrow
																		>
																			<IconButton
																				onClick={handleChangeNumber(
																					slideshow._id,
																				)}
																			>
																				<RepeatIcon />
																			</IconButton>
																		</Tooltip>
																	)}
																</TableCell>

																{canViewSystem && (
																	<TableCell>
																		{isAdmin && (
																			<Switch
																				size="small"
																				checked={
																					slideshow.isSystem
																				}
																				onChange={handleToggleSystem(
																					slideshow,
																				)}
																			/>
																		)}
																		{!isAdmin &&
																			(slideshow.isSystem
																				? 'Да'
																				: 'Нет')}
																	</TableCell>
																)}
															</tr>
														)}
													</Draggable>
												))}
											{prov.placeholder}
										</tbody>
									);
								}}
							</Droppable>
						</DragDropContext>
					</Table>
				)}
			</Card>
		);
	};

	const handleCreateSlideshow = () => {
		props.createSlideshow(groupId).then((slideshowId: string) => {
			props.toggleEditorSettingsPanel(true);

			props.push(routerUrls.userEditSlideshow.replace(':id', slideshowId));
		});
	};

	return (
		<div className={css.container}>
			<PageTitle
				title="слайд-шоу"
				buttonTitle="Создать слайд-шоу"
				onButtonClick={handleCreateSlideshow}
				extButtons={
					<>
						<Link to={routerUrls.userSelectTemplate} className={css.makeSystem}>
							<Button variant="outlined" color="primary" className={css.button}>
								<MenuIcon className={css.buttonIcon} />
								Создать из системных
							</Button>
						</Link>
						{false && checkAdmin() && (
							<Button variant="outlined" color="primary" onClick={reorderSlideshows}>
								{isWaitingSort ? 'Ждем' : 'Пересорт (долго)'}
							</Button>
						)}

						{isStatisticsVisible && (
							<Button
								variant="outlined"
								color="primary"
								className={css.button}
								href={
									isStatisticsGranted
										? routerUrls.statisticsGroup.replace(':groupId', groupId)
										: '/user/plan'
								}
							>
								Статистика
								<EqualizerIcon />
								{!isStatisticsGranted && (
									<LockIcon
										viewBox="0 0 11 14"
										style={{width: '11px', height: '14px'}}
										fill="none"
										pathProps={{
											fill: '#3f51b5',
											fillOpacity: '0.54',
										}}
									/>
								)}
							</Button>
						)}
					</>
				}
			/>

			<ChangeAppNumberDialog
				slideshowId={changeSlideNumberShowDialog}
				isOpen={!!changeSlideNumberShowDialog}
				onClose={handleCloseChangeSlideNumberShowDialog}
				onSuccess={handleSuccessChangeSlideNumberShowDialog}
			/>

			<SendSlideShowDialog
				slideshowId={sendSlideShowDialog}
				isOpen={!!sendSlideShowDialog}
				onClose={handleCloseSendSlideShowDialog}
				onSuccess={handleSuccessSendSlideShowDialog}
			/>

			<AddSlideShowGroupDialog
				isOpen={addSlideShowGroupDialog}
				onClose={handleCloseAddSlideShowDialog}
				onSuccess={handleCloseAddSlideShowDialog}
			/>

			<EditSlideShowGroupDialog
				isOpen={editSlideShowGroupDialog}
				group={groups.find(({_id}) => _id === selectedGroup)}
				onClose={handleCloseEditSlideShowDialog}
				onSuccess={handleSuccessEditSlideShowDialog}
				onDelete={handleDeleteEditSlideShowDialog}
			/>

			{quizzes.map((quiz) => (
				<QuizDialog quiz={quiz} isOpen={true} />
			))}

			{getPageContent()}
		</div>
	);
};

export default withTracker(
	(): IScreensPageData => {
		const subMyList = Meteor.subscribe(publishNames.slideshow.myList).ready();
		const subGroups = Meteor.subscribe(publishNames.group.groups).ready();
		const subQuizzes = Meteor.subscribe(publishNames.quiz.pendingList).ready();

		const loading = !subMyList || !subGroups || !subQuizzes;

		return {
			loading,
			groups: Group.find().fetch(),
			quizzes: Quiz.find({}).fetch(),
			slideshowList: Slideshow.find({}, {sort: {createdAt: -1}}).fetch(),
		};
	},
)(
	connect<RouteComponentProps>(null, {
		createSlideshow,
		push,
		toggleEditorSettingsPanel,
		updateSlideshowParams,
	})(SlideshowListPage),
);
