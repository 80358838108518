import React, {useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import PageTitle from 'client/components/common/PageTitle';
import {makeStyles} from '@material-ui/core/styles';
import {methodNames} from 'shared/constants/methodNames';
import {ISlideshowAggregatedStats} from 'shared/collections/SlideshowStats';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {TableBody} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SortIcon from '@material-ui/icons/Sort';
import SlideshowDetailedStatsDialog from 'client/components/user/screens/SlideshowDetailedStatsDialog';
import {useUnmounted} from '../../../hooks';

type SortOrder = 'asc' | 'desc';
type SortField = 'totalViews' | 'liveViews' | 'user';

export const useStyles = makeStyles((theme) => ({
	loaderContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		gap: 16,
	},

	subtitle: {
		padding: theme.spacing(0.5, 3),
	},

	cardsContainer: {
		padding: theme.spacing(0.5, 3),
		display: 'flex',
		flexWrap: 'wrap',
		gap: 16,
	},

	card: {
		padding: theme.spacing(1, 2),
		borderRadius: theme.spacing(1),
		background: theme.palette.background.paper,
	},

	cardTitle: {
		display: 'block',
		fontSize: '1.2rem',
		fontWeight: 'bold',
		marginBottom: theme.spacing(1),
	},

	cardContent: {
		paddingLeft: theme.spacing(2),
	},

	cardContentRow: {
		display: 'grid',
		gap: 12,
		gridTemplateColumns: '1fr auto',
		marginBottom: theme.spacing(0.5),
	},

	cardContentLabel: {
		fontSize: '1rem',
		opacity: 0.75,
	},

	cardContentValue: {
		fontSize: '1.1rem',
	},
}));

function sortDetails(
	details: ISlideshowAggregatedStats['details'],
	sortField: SortField,
	sortOrder: SortOrder,
) {
	return [...details].sort((a, b) => {
		let s1 = a;
		let s2 = b;
		if (sortOrder === 'desc') {
			s1 = b;
			s2 = a;
		}

		switch (sortField) {
			case 'totalViews':
				return s1.totalAmountOfViews - s2.totalAmountOfViews;
			case 'liveViews':
				return s1.amountOfActiveViews.total - s2.amountOfActiveViews.total;
			case 'user':
				return s1.owner.userEmail.localeCompare(s2.owner.userEmail);
		}
	});
}
const SlideshowStatsPage: React.FC = () => {
	const [loading, setLoading] = useState(true);
	const [stats, setStats] = useState<ISlideshowAggregatedStats>();
	const [details, setDetails] = useState<ISlideshowAggregatedStats['details'][number] | null>(
		null,
	);

	const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
	const [sortField, setSortField] = useState<SortField>('totalViews');
	const unmounted = useUnmounted();

	function sortByField(field: SortField) {
		if (field === sortField) {
			setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
		} else {
			setSortOrder('desc');
		}
		setSortField(field);
	}

	useEffect(() => {
		Meteor.callAsync(methodNames.slideshowStats.getFullStats).then(
			(res: ISlideshowAggregatedStats) => {
				if (unmounted.current) return;

				const stats = res;
				stats.details = sortDetails(stats.details, sortField, sortOrder);
				setStats(stats);
				setLoading(false);
			},
		);
	}, []);

	useEffect(() => {
		if (!stats) return;
		if (loading) return;

		const details = sortDetails(stats.details, sortField, sortOrder);

		setStats({...stats, details});
	}, [sortOrder, sortField]);

	const renderSortButton = (field: SortField) => {
		return (
			<IconButton onClick={() => sortByField(field)}>
				{sortField !== field ? (
					<SortIcon />
				) : sortOrder === 'desc' ? (
					<ArrowDropDownIcon />
				) : (
					<ArrowDropUpIcon />
				)}
			</IconButton>
		);
	};

	const classes = useStyles();

	return (
		<div>
			<PageTitle title="Статистика показов" />

			{loading ? (
				<div className={classes.loaderContainer}>
					<Typography variant="h5" gutterBottom>
						Формируем и загружаем статистику...
					</Typography>

					<CircularProgress />
				</div>
			) : (
				<>
					<div className={classes.cardsContainer}>
						<div className={classes.card}>
							<span className={classes.cardTitle}>Общая сводка</span>
							<div className={classes.cardContent}>
								<div className={classes.cardContentRow}>
									<span className={classes.cardContentLabel}>Всего слайдшоу</span>
									<span className={classes.cardContentValue}>
										{stats?.totalAmountOfSlideshows}
									</span>
								</div>
								<div className={classes.cardContentRow}>
									<span className={classes.cardContentLabel}>Всего показов</span>
									<span className={classes.cardContentValue}>
										{stats?.totalAmountOfViews}
									</span>
								</div>
							</div>
						</div>

						<div className={classes.card}>
							<span className={classes.cardTitle}>Актвные слайдшоу</span>
							<div className={classes.cardContent}>
								<div className={classes.cardContentRow}>
									<span className={classes.cardContentLabel}>Всего</span>
									<span className={classes.cardContentValue}>
										{stats?.amountOfActiveSlideshows.includingPreviews}
									</span>
								</div>
								<div className={classes.cardContentRow}>
									<span className={classes.cardContentLabel}>
										Без предпросмотров
									</span>
									<span className={classes.cardContentValue}>
										{stats?.amountOfActiveSlideshows.onlyShown}
									</span>
								</div>
							</div>
						</div>

						<div className={classes.card}>
							<span className={classes.cardTitle}>Показы сейчас</span>
							<div className={classes.cardContent}>
								<div className={classes.cardContentRow}>
									<span className={classes.cardContentLabel}>Всего</span>
									<span className={classes.cardContentValue}>
										{stats?.amountOfActiveViews.total}
									</span>
								</div>
								<div className={classes.cardContentRow}>
									<span className={classes.cardContentLabel}>
										Без предпросмотров
									</span>
									<span className={classes.cardContentValue}>
										{stats?.amountOfActiveViews.views}
									</span>
								</div>
								<div className={classes.cardContentRow}>
									<span className={classes.cardContentLabel}>Предпросмотр</span>
									<span className={classes.cardContentValue}>
										{stats?.amountOfActiveViews.previews}
									</span>
								</div>
							</div>
						</div>
					</div>

					<Typography className={classes.subtitle} variant="h6">
						Детализация
					</Typography>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Название</TableCell>
								<TableCell>Номер</TableCell>
								<TableCell>
									Всего показов
									{renderSortButton('totalViews')}
								</TableCell>
								<TableCell>
									В текущий момент
									{renderSortButton('liveViews')}
								</TableCell>
								<TableCell>
									Пользователь
									{renderSortButton('user')}
								</TableCell>
								<TableCell>Действия</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{stats?.details.map((slideshowDetails) => (
								<TableRow key={slideshowDetails.slideshowId}>
									<TableCell>{slideshowDetails.slideshowName}</TableCell>
									<TableCell>{slideshowDetails.slideshowNumId}</TableCell>
									<TableCell>{slideshowDetails.totalAmountOfViews}</TableCell>
									<TableCell>
										{slideshowDetails.amountOfActiveViews.total}
									</TableCell>
									<TableCell>{slideshowDetails.owner.userEmail}</TableCell>
									<TableCell>
										<Button onClick={() => setDetails(slideshowDetails)}>
											<HelpIcon />
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</>
			)}

			<SlideshowDetailedStatsDialog
				isOpened={!!details}
				details={details}
				onClose={() => setDetails(null)}
			/>
		</div>
	);
};

export default SlideshowStatsPage;
