import React from 'react';
import Button from '@material-ui/core/Button';
import {DialogContent, DialogActions} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {Formik, Form} from 'formik';
import {isEmpty} from 'lodash';

import Modal from 'client/components/common/ui/Modal';
import {Checkboxes} from 'client/components/common/ui/Checkboxes';
import {methodNames} from 'shared/constants/methodNames';
import './QuizDialog.scss';

export const QuizDialog = ({isOpen, quiz}) => {
	const {answers, customAnswer, sendPulseVariable, unisenderVariable} = quiz;

	const name = 'checkboxes';

	const closeDialog = (resetForm) => {
		/*
		For some reasons resetForm() do not flush checkboxes,
		so we can try to do it manually.
		*/
		const checkboxes = document.querySelectorAll('form input[type=checkbox]');
		checkboxes.forEach((checkbox) => {
			checkbox.checked = false;
		});
		resetForm();
	};

	const anythingIsSelected = (arr) => {
		return arr.reduce((res, item) => res || item, false);
	};

	const validateForm = (values) => {
		const {checkboxes, picked, customAnswer} = values;
		const {errorText} = customAnswer;
		const errors = {};
		if (quiz.type === 'checkbox') {
			if (!anythingIsSelected(checkboxes)) {
				errors[name] = '* Выберите минимум 1 вариант';
			}
			if (checkboxes[answers.length] && !customAnswer) {
				errors.customAnswer = errorText || '* Укажите значение или снимите галочку';
			}
		} else if (quiz.type === 'radio') {
			if (!picked) {
				errors.picked = '* Выберите один вариант';
			}
		}
		return errors;
	};

	const submit = (values, {resetForm}) => {
		const {customAnswer, checkboxes, picked} = values;
		let filteredAnswers;
		if (quiz.type === 'checkbox') {
			filteredAnswers = answers.filter((question, i) => checkboxes[i]);
			customAnswer && filteredAnswers.push(customAnswer.trim());
		} else {
			filteredAnswers = [picked.trim()];
		}

		Meteor.call(methodNames.quizResponse.create, {
			answers: filteredAnswers,
			quizId: quiz._id,
		});
		if (sendPulseVariable) {
			// it will set undefined to all fields
			const userId = Meteor.userId();
			const sendPulseData = {};
			sendPulseData[sendPulseVariable] = filteredAnswers.join(', ');
			Meteor.call(methodNames.sendpulse.uploadUser, userId, sendPulseData);
		}

		if (unisenderVariable) {
			// it will set undefined to all fields
			// call it from server
			// also don't use findOne use Meteor.user()
			const userId = Meteor.userId();
			const unisenderData = filteredAnswers
				.map((answ) => `${unisenderVariable}=${answ}`)
				.join(',');
			Meteor.call(methodNames.unisender.subscribe, userId, {tag: unisenderData});
		}
		closeDialog(resetForm);
	};
	return (
		<Modal title={quiz.title || 'Новый опрос'} isOpen={isOpen}>
			<Formik
				validate={validateForm}
				initialValues={{
					customAnswer: '',
					answers: {},
					validateOnMount: true,
					errors: {},
					picked: '',
				}}
				onSubmit={submit}
			>
				{({errors, isSubmitting, isValid, resetForm}) => (
					<Form>
						<Paper className="quiz-paper">
							<DialogContent>
								<p className="quizQuestion">{quiz.question}</p>
								<Checkboxes
									checkboxes={answers}
									type={quiz.type}
									name={name}
									addManualCheckbox={
										!isEmpty(customAnswer) && {
											checkboxLabel: 'Добавить свой вариант',
											input: {
												label: customAnswer?.label,
												name: 'customAnswer',
												helperText:
													customAnswer?.helperText || 'Helper text',
											},
										}
									}
								/>
							</DialogContent>
						</Paper>
						<DialogActions>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								disabled={!isEmpty(errors) || isSubmitting || !isValid}
							>
								Подтвердить
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};
