import {Slingshot} from 'meteor/ivadey:slingshot';
import {Tracker} from 'meteor/tracker';
import find from 'lodash/find';
import {SlingshotDirectiveName} from '../../shared/collections/Attachments';

type SendResult = {
	link: string;
	s3Link: string;
	key: string;
};

export default (
	directiveName: typeof SlingshotDirectiveName[keyof typeof SlingshotDirectiveName],
) => {
	const uploader = new Slingshot.Upload(directiveName);

	return {
		send: (file: File, onProgress?: (progress: number) => void) =>
			new Promise<SendResult>((resolve, reject) => {
				if (typeof onProgress === 'function') {
					Tracker.autorun(() => {
						onProgress?.(uploader.progress());
					});
				}

				uploader.send(file, (error, result) => {
					if (error) reject(error);
					else {
						resolve({
							link: result,
							s3Link: result,
							key: find(uploader.instructions.postData, {name: 'key'})!.value,
						});
					}
				});
			}),
	};
};
