import {ru} from './ru';
import {en} from './en';

export type LocalizationsKeys = keyof L[keyof L];

type L = typeof allLanguages;
type Localization = {[K in keyof L]: Record<LocalizationsKeys, string> & Record<string, string>};

const allLanguages = {ru, en};

const Localizations = allLanguages as Localization;

export type Locales = 'ru' | 'en';

export const getCurrentLocale = (): Locales => {
	const locale = localStorage.getItem('locale');
	const browserLanguage = getBrowserLanguage();
	if (locale) {
		return locale as Locales;
	}
	return browserLanguage === 'en' ? 'en' : 'ru';
};

type Params = Record<string, string>;

const defParam: Params = {};

export const LL = (
	key: LocalizationsKeys,
	paramsOrLocale: Params | Locales = defParam,
	locale: Locales = getCurrentLocale(),
): string => {
	let params = defParam;

	if (typeof paramsOrLocale === 'string') {
		locale = paramsOrLocale;
	} else if (Object.keys(paramsOrLocale).length > 0) {
		params = paramsOrLocale;
	}

	const dictLang = Localizations[locale];

	if (!dictLang) return key;

	let translate = dictLang[key];

	if (translate === undefined) return key;

	if (params === defParam) return translate;

	const re = /\$\{(\w+)\}/;
	const stringKeys = translate.match(new RegExp(re, 'gm'));

	if (!stringKeys) return translate;

	for (const stringKey of stringKeys) {
		const prop = stringKey.match(re)![1];

		if (params[prop] === undefined) {
			throw new Error(`Key ${prop} not found in params`);
		}

		translate = translate.replace(stringKey, params[prop]);
	}

	return translate;
};

export const hasLocalizationForKey = (
	key: string,
	locale: Locales = getCurrentLocale(),
): boolean => {
	const lang = Localizations[locale];

	if (lang == null) return false;

	return lang[key] != null;
};

function getBrowserLanguage() {
	return 'ru';
	if (navigator.languages && navigator.languages.length > 0) {
		return navigator.languages[0].slice(0, 2);
	}
	if (navigator.language) {
		return navigator.language;
	}
	return undefined;
}
