import moment from 'moment';
import {Meteor} from 'meteor/meteor';
import {methodNames} from 'shared/constants/methodNames';
import {IResponseError, IResponseSuccess} from 'shared/models/Response';
import {EventResizeDoneArg} from '@fullcalendar/interaction';

export default (slideStreamId: string, {event, endDelta, revert}: EventResizeDoneArg) => {
	const {rawEvent} = event.extendedProps;

	const payload = {
		...rawEvent,
		slideStreamId,
		endDate: moment(rawEvent.endDate)
			.add(endDelta.milliseconds, 'milliseconds')
			.add(endDelta.days, 'days')
			.toDate(),
	};

	Meteor.call(
		methodNames.slideStream.updateSchedule,
		payload,
		(_: unknown, res: IResponseSuccess | IResponseError) => {
			if ('error' in res && res.error) revert();
		},
	);
};
