exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container--2XNbg {\n\ttext-shadow: 0 1px 3px rgba(0, 0, 0, .9);\n}\n\n\t.container--2XNbg .title--tVJ5h {\n\t\tmargin-top: 2vh;\n\t\tfont-size: 5vh;\n\t\tmargin-bottom: 2vh;\n\t}\n\n\t.container--2XNbg .welcome--LHV_H {\n\t\tfont-size: 3vh;\n\t\ttext-align: center;\n\t}\n\n\t.container--2XNbg .instructions--QxoLn {\n\t\tfont-size: 3vh;\n\t\tmargin-bottom: 3vh;\n\t}\n\n\t.container--2XNbg .items--2sm4A {\n\t\tmargin-left: auto;\n\t\tmargin-right: auto;\n\t\tmargin-bottom: 40px;\n\t}\n\n\t.container--2XNbg .items--2sm4A .item--315pE {\n\t\t\tpadding: 2vh;\n\t\t\tmargin: 2vh 2vh 0;\n\t\t\twidth: -webkit-calc(13.4vw + 2.4vw);\n\t\t\twidth: -moz-calc(13.4vw + 2.4vw);\n\t\t\twidth: calc(13.4vw + 2.4vw);\n\t\t\tborder-radius: .2vh;\n\t\t\tborder-width: .2vh;\n\t\t\tborder-color: transparent;\n\t\t\toverflow: hidden;\n\t\t}\n\n\t.container--2XNbg .items--2sm4A .item--315pE .imageContainer--1tgxn {\n\t\t\t\twidth: 13.4vw;\n\t\t\t\theight: 13.4vw;\n\t\t\t\tmargin-left: auto;\n\t\t\t\tmargin-right: auto;\n\t\t\t\t-webkit-box-align: center;\n\t\t\t\t-webkit-align-items: center;\n\t\t\t\t   -moz-box-align: center;\n\t\t\t\t        align-items: center;\n\t\t\t\tdisplay: -webkit-box;\n\t\t\t\tdisplay: -webkit-flex;\n\t\t\t\tdisplay: -moz-box;\n\t\t\t\tdisplay: flex;\n\t\t\t}\n\n\t.container--2XNbg .items--2sm4A .item--315pE .imageContainer--1tgxn .img--7-mkc {\n\t\t\t\t\twidth: 13.4vw;\n\t\t\t\t\tmax-height: 13.4vw;\n\t\t\t\t}\n\n\t.container--2XNbg .items--2sm4A .item--315pE .title--tVJ5h {\n\t\t\t\tfont-size: 2.8vh;\n\t\t\t\ttext-align: center;\n\t\t\t\tmargin-top: 1vh;\n\t\t\t}", ""]);

// Exports
exports.locals = {
	"container": "container--2XNbg",
	"title": "title--tVJ5h",
	"welcome": "welcome--LHV_H",
	"instructions": "instructions--QxoLn",
	"items": "items--2sm4A",
	"item": "item--315pE",
	"imageContainer": "imageContainer--1tgxn",
	"img": "img--7-mkc"
};