import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PasswordForm from 'client/components/admin/users/PasswordForm';
import Button from '@material-ui/core/Button';

interface IChangeUserPassProps {
	userId: string;
	title: string;
}

class ChangeUserPasswordDialog extends React.PureComponent<IChangeUserPassProps> {
	state = {open: false};

	handleClickOpen = () => {
		this.setState({open: true});
	};

	handleCancel = () => {
		this.setState({open: false});
	};

	render() {
		const {title} = this.props;

		return (
			<>
				<a href="#" onClick={this.handleClickOpen}>
					{title}
				</a>
				<Dialog
					open={this.state.open}
					keepMounted
					onClose={this.handleCancel}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
					style={{padding: '10px'}}
				>
					<PasswordForm userId={this.props.userId} handleClose={this.handleCancel} />
					<Button onClick={this.handleCancel} color="primary">
						Отмена
					</Button>
				</Dialog>
				<br />
			</>
		);
	}
}

export default ChangeUserPasswordDialog;
