import React from 'react';
import {withTracker} from 'react-meteor-data-with-tracker';
import {ISlideshow} from 'shared/collections/Slideshows';
import {Box, CardMedia} from '@material-ui/core';
import css from './SelectedSlideshow.pcss';
import appConsts from 'client/constants/appConsts';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Button from '@material-ui/core/Button';
import routerUrls from 'client/constants/routerUrls';
import getFileUrl from 'client/utils/getFileUrl';

interface ISelectedSlideshowProps {
	slideshow: ISlideshow;
}

const SelectedSlideshow: React.FC<ISelectedSlideshowProps> = ({slideshow}) => {
	const handleViewSlideshowButton = () => () => {
		const win = window.open(
			routerUrls.userViewSlideshow.replace(':id', slideshow.numId),
			'_blank',
		);

		win!.focus();
	};

	return (
		<Box p={3} borderRadius={12} border={1} borderColor="grey.300" className={css.planBox}>
			<div className={css.planBox__inner}>
				<div className={css.planBox__minor}>
					<div>
						<div className={css['planBox__minor-title']}>
							<CardMedia
								image={
									slideshow.previewImage
										? getFileUrl(slideshow.previewImage)
										: `${appConsts.imgUrl}/not_slideshow_img.png`
								}
								title="Contemplative Reptile"
							/>
						</div>
					</div>
					<div className={css['planBox__minor-bottom']}>
						<div className={css.planBox__subtitle}>
							<Button
								size="small"
								color="primary"
								endIcon={<RemoveRedEyeIcon />}
								onClick={handleViewSlideshowButton()}
							>
								{slideshow.numId}
							</Button>
						</div>
					</div>
				</div>
				<div className={css.planBox__major}>
					<div className={css['planBox__major-title']}>
						<div className={css.planBox__title}>{slideshow.name}</div>
					</div>
					<div className={css['planBox__major-props']}>
						<div className={css.planBox__props}>
							<div className={css.planBox__prop}>
								<div className={css['planBox__prop-key']}>Срок</div>
								<div className={css['planBox__prop-value']}>Навсегда</div>
							</div>
							<div className={css.planBox__prop}>
								<div className={css['planBox__prop-key']}>Итого</div>
								<div className={css['planBox__prop-value']}>
									{slideshow.systemPrice} ₽
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Box>
	);
};

export default withTracker((slideshow: {slideshow: ISlideshow}) => {
	const slideshowRes = slideshow.slideshow;
	return {
		slideshowRes,
	};
})(SelectedSlideshow);
