import React, {useEffect, useState} from 'react';
import {IOAuthAppAuthorizationInfo} from '../../shared/collections/oauthApp';
import css from './OAuthPage.pcss';
import {withTracker} from 'react-meteor-data-with-tracker';
import {Redirect} from 'react-router-dom';
import routerUrls from 'client/constants/routerUrls';

class OAuthPage extends React.Component<
	any,
	{authorizationRes?: IOAuthAppAuthorizationInfo | string}
> {
	willUnmount = false;

	constructor(props: any) {
		super(props);

		this.state = {
			authorizationRes: undefined,
		};

		this.grantPermissions = this.grantPermissions.bind(this);
	}

	componentDidMount() {
		const searchParams = new URLSearchParams(document.location.search);
		const clientId = searchParams.get('client_id') as string;
		const scope = searchParams.get('scope') as string;
		const redirectUri = searchParams.get('redirect_uri') as string;
		const state = searchParams.get('state') as string;

		if (!clientId || !scope || !redirectUri) this.setState({authorizationRes: 'Wrong params'});
		else {
			Meteor.call(
				'oauthAppAuthorize',
				clientId,
				scope,
				redirectUri,
				(err: any, res: IOAuthAppAuthorizationInfo | string) => {
					if (err) {
						console.error(err);
						return;
					}
					if (this.willUnmount) return;

					if (
						typeof res === 'object' &&
						(res as IOAuthAppAuthorizationInfo).authorizationCode
					) {
						const redirectToUrl = new URL(redirectUri);
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						redirectToUrl.searchParams.append(
							'code',
							(res as IOAuthAppAuthorizationInfo).authorizationCode as string,
						);
						redirectToUrl.searchParams.append('state', state);
						window.location.replace(redirectToUrl.toString());
					} else this.setState({authorizationRes: res});
				},
			);
		}
	}

	componentWillUnmount() {
		this.willUnmount = true;
	}

	grantPermissions() {
		const searchParams = new URLSearchParams(document.location.search);
		const clientId = searchParams.get('client_id') as string;
		const redirectUri = searchParams.get('redirect_uri') as string;

		Meteor.call('allowOAuthApp', clientId, (err: any, res: any) => {
			if (res) {
				const redirectToUrl = new URL(redirectUri);
				redirectToUrl.searchParams.append('code', res.authorizationCode);
				window.location.replace(redirectToUrl.toString());
			} else console.error(err);
		});
	}

	render() {
		if (this.props.userId === null) return <Redirect to={routerUrls.authLogin} />;

		const {authorizationRes} = this.state;

		if (!authorizationRes) {
			return (
				<div className={css.container}>
					<div className={css.preloader} />
				</div>
			);
		}

		if (typeof authorizationRes === 'string')
			return <div className={css.container}>{authorizationRes}</div>;

		return (
			<div className={css.container}>
				<p className={css.message}>
					{`Приложение ${authorizationRes?.appName} запрашивает доступ к информации из вашего профиля и адресу электронной почты`}
				</p>

				<button className={css.button} onClick={this.grantPermissions}>
					Разрешить
				</button>
			</div>
		);
	}
}

export default withTracker(() => ({userId: Meteor.userId()}))(OAuthPage);
