import {appConfig} from 'client/constants/config';
import {attacmentsSizeRestrictions, SlingshotDirectiveName} from 'shared/collections/Attachments';

interface IAppConsts {
	uploadUrl: string;
	maxImgSize: number;
	imgUrl: string;
	svgUrl: string;
	transitionDuration: number;

	ya: {
		apiKey: {apikey: string; ns: string};
	};

	unisender: {
		apiKey: {apikey: string};
	};
}

// @ts-ignore
const backendUrl = window.__meteor_runtime_config__.DDP_DEFAULT_CONNECTION_URL;

const appConsts: IAppConsts = {
	uploadUrl: `${backendUrl}/upload`,
	//TODO это бы объединить с attacmentsSizeRestrictions[SlingshotDirectiveName.Images],
	maxImgSize: 1000 * 1000 * 20,
	imgUrl: `${backendUrl}/img`,
	svgUrl: `${backendUrl}/svg`,
	transitionDuration: 1000,

	ya: {
		apiKey: {apikey: appConfig.YANDEX_GEOCODER_API_KEY, ns: 'YMaps'},
	},

	unisender: {
		apiKey: {apikey: appConfig.UNISENDER_API_KEY},
	},
	tinymce: {
		apiKey: appConfig.TINYMCE_API_KEY,
	},
};

export default appConsts;
