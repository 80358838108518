import {Mongo} from 'meteor/mongo';
import {Class} from 'meteor/jagi:astronomy';
import {getDefaultTimestamp} from 'shared/utils/collections';

export interface IPurchasedSlideshow {
	_id: string;
	userId: string;
	slideshowId: string;
	paymentId: string;
	createdAt: Date;
	updatedAt: Date;
}

const PurchasedSlideshows = new Mongo.Collection<IPurchasedSlideshow>('purchasedSlideshows');

export const PurchasedSlideshow = Class.create<IPurchasedSlideshow>({
	name: 'PurchasedSlideshow',
	collection: PurchasedSlideshows,
	fields: {
		userId: String,
		slideshowId: String,
		paymentId: String,
		createdAt: Date,
		updatedAt: Date,
	},
	behaviors: {timestamp: getDefaultTimestamp()},
});
