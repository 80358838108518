exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".itemFeed--1DE2V {\n\tborder: 1px solid #e1e8ed;\n\tbox-shadow: none !important;\n}\n\n\t.itemFeed--1DE2V + .itemFeed--1DE2V {\n\t\tmargin-top: 20px;\n\t}\n\n.feedHeader--F9f7k {\n\twidth: 100%;\n}\n\n.feedContent--38eeh {\n\tpadding-bottom: 10px !important;\n}\n\n.feedFaveDate--3aqFj {\n\tpadding-top: 10px !important;\n}\n\n.likeAndDate--3VRyO {\n\tmargin-top: 5px !important;\n\tdisplay: -webkit-box;\n\tdisplay: -webkit-flex;\n\tdisplay: -moz-box;\n\tdisplay: flex;\n\t-webkit-box-align: center;\n\t-webkit-align-items: center;\n\t   -moz-box-align: center;\n\t        align-items: center;\n\tcolor: #697882;\n}\n\n.reply--2Ub8z {\n\tborder-top: 1px solid #e1e8ed;\n\tpadding: 10px 16px !important;\n\tdisplay: -webkit-box;\n\tdisplay: -webkit-flex;\n\tdisplay: -moz-box;\n\tdisplay: flex;\n\t-webkit-box-align: center;\n\t-webkit-align-items: center;\n\t   -moz-box-align: center;\n\t        align-items: center;\n\tcolor: #2b7bb9;\n}\n\n.icon--16kJI {\n\twidth: 18px !important;\n\theight: 18px !important;\n}", ""]);

// Exports
exports.locals = {
	"itemFeed": "itemFeed--1DE2V",
	"feedHeader": "feedHeader--F9f7k",
	"feedContent": "feedContent--38eeh",
	"feedFaveDate": "feedFaveDate--3aqFj",
	"likeAndDate": "likeAndDate--3VRyO",
	"reply": "reply--2Ub8z",
	"icon": "icon--16kJI"
};