exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".preloader--344a3 {\n\twidth: 32px;\n\theight: 32px;\n\tborder: 2px solid lightgray;\n\tborder-top-color: black;\n\tborder-radius: 50%;\n\t-webkit-animation: spin--1VSeZ 1s linear infinite;\n\t   -moz-animation: spin--1VSeZ 1s linear infinite;\n\t        animation: spin--1VSeZ 1s linear infinite;\n}\n@-webkit-keyframes spin--1VSeZ {\n\tfrom { -webkit-transform: rotate(0); transform: rotate(0) }\n\tto { -webkit-transform: rotate(360deg); transform: rotate(360deg) }\n}\n@-moz-keyframes spin--1VSeZ {\n\tfrom { -moz-transform: rotate(0); transform: rotate(0) }\n\tto { -moz-transform: rotate(360deg); transform: rotate(360deg) }\n}\n@keyframes spin--1VSeZ {\n\tfrom { -webkit-transform: rotate(0); -moz-transform: rotate(0); transform: rotate(0) }\n\tto { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); transform: rotate(360deg) }\n}\n\n.container--QvW9K {\n\twidth: 100vw;\n\theight: 100vh;\n\tdisplay: -webkit-box;\n\tdisplay: -webkit-flex;\n\tdisplay: -moz-box;\n\tdisplay: flex;\n\t-webkit-box-orient: vertical;\n\t-webkit-box-direction: normal;\n\t-webkit-flex-direction: column;\n\t   -moz-box-orient: vertical;\n\t   -moz-box-direction: normal;\n\t        flex-direction: column;\n\tgrid-gap: 2rem;\n\tgap: 2rem;\n\t-webkit-box-pack: center;\n\t-webkit-justify-content: center;\n\t   -moz-box-pack: center;\n\t        justify-content: center;\n\t-webkit-box-align: center;\n\t-webkit-align-items: center;\n\t   -moz-box-align: center;\n\t        align-items: center;\n}\n\n.message--MPmAm {\n\tmax-width: 480px;\n\ttext-align: center;\n}\n\n.button--3fPbg {\n\toutline: none;\n\tbackground: transparent;\n\tborder: 1px solid #3f51b5;\n\tborder-radius: 5px;\n\tfont-size: 1rem;\n\tpadding: 8px 16px;\n\tcursor: pointer;\n}\n.button--3fPbg:hover {\n\tbackground-color: rgba(63, 81, 181, 0.1);\n}\n", ""]);

// Exports
exports.locals = {
	"preloader": "preloader--344a3",
	"spin": "spin--1VSeZ",
	"container": "container--QvW9K",
	"message": "message--MPmAm",
	"button": "button--3fPbg"
};