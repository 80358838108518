import React, {useState, useEffect} from 'react';
import RRule, {Weekday} from 'rrule'; // rrule – dependency from @fullcalendar/rrule
import RepeatIcon from '../../../icons/RepeatIcon';
import {Select} from '../../common/select/select';
import {Box} from '@material-ui/core';
import useStyles from './useStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import DateEditor from './DateEditor';
import Typography from '@material-ui/core/Typography';
import TextInputNaked from './DateEditor/TextInputNaked';
import moment from 'moment';
import {LL} from 'shared/localization';

export const NEVER_REPEAT_ID = -111;
const frequencyOptions = [
	{id: NEVER_REPEAT_ID, text: LL('components.notRepeat')},
	{id: RRule.DAILY, text: LL('components.daily')},
	{id: RRule.WEEKLY, text: LL('components.weekly')},
	{id: RRule.MONTHLY, text: LL('components.monthly')},
	{id: RRule.YEARLY, text: LL('components.yearly')},
];

const RepeatType = {
	byCount: 'by-count',
	untilDate: 'until-date',
};

const weekdaysShort = moment.weekdaysShort(true);
const WEEKDAYS = [
	{id: RRule.MO, label: weekdaysShort[0]},
	{id: RRule.TU, label: weekdaysShort[1]},
	{id: RRule.WE, label: weekdaysShort[2]},
	{id: RRule.TH, label: weekdaysShort[3]},
	{id: RRule.FR, label: weekdaysShort[4]},
	{id: RRule.SA, label: weekdaysShort[5]},
	{id: RRule.SU, label: weekdaysShort[6]},
];

interface IRecurrenceSettingsProps {
	frequency?: number;
	amountOfRepeats?: number | null;
	repeatUntil?: Date | null;
	byweekday?: Weekday[];
	onChange: (
		data: Pick<
			IRecurrenceSettingsProps,
			'frequency' | 'amountOfRepeats' | 'repeatUntil' | 'byweekday'
		>,
	) => void;
}

const RecurrenceSettings: React.FC<IRecurrenceSettingsProps> = ({
	frequency: frequencyFromProps,
	amountOfRepeats: amountOfRepeatsFromProps,
	repeatUntil,
	byweekday: byweekdayFromProps,
	onChange,
}) => {
	const classes = useStyles();

	const [frequency, setFrequency] = useState(NEVER_REPEAT_ID);
	const [endRepeatType, setEndRepeatType] = useState(RepeatType.byCount);

	const [amountOfRepeats, setAmountOfRepeats] = useState(1);
	const [repeatsEndDate, setRepeatsEndDate] = useState(
		moment()
			.endOf('day')
			.toDate(),
	);

	const [byweekday, setByweekday] = useState(WEEKDAYS.map((d) => d.id));

	useEffect(() => {
		setFrequency(frequencyFromProps || NEVER_REPEAT_ID);
		setEndRepeatType(repeatUntil ? RepeatType.untilDate : RepeatType.byCount);
		setAmountOfRepeats(amountOfRepeatsFromProps || 1);
		setRepeatsEndDate(
			repeatUntil ||
				moment()
					.endOf('day')
					.toDate(),
		);
		setByweekday(byweekdayFromProps || WEEKDAYS.map((d) => d.id));
	}, [frequencyFromProps, amountOfRepeatsFromProps, repeatUntil, byweekdayFromProps]);

	const handleFrequencyChange = (v) => {
		setFrequency(v);
		onChange({
			repeatUntil,
			amountOfRepeats,
			byweekday: frequency === RRule.DAILY ? undefined : byweekday,
			frequency: v,
		});
	};
	const handleRepeatsAmountChange = (e) => {
		const newValue = Number(e.target.value || 1);
		setAmountOfRepeats(newValue);
		onChange({frequency, byweekday, amountOfRepeats: newValue});
	};
	const handleRepeatUntilChange = (d: Date) => {
		const md = moment(d).endOf('day');

		setRepeatsEndDate(md.toDate());
		onChange({frequency, byweekday, repeatUntil: md.toDate()});
	};
	const handleRepeatTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEndRepeatType(event.target.value);
		if (event.target.value === RepeatType.byCount) onChange({frequency, amountOfRepeats});
		else onChange({frequency, byweekday, repeatUntil: repeatsEndDate});
	};
	const handleByweekdayChange = (weekdayId) => {
		const newValue = byweekday.includes(weekdayId)
			? byweekday.filter((weekday) => weekday !== weekdayId)
			: [...byweekday, weekdayId];

		setByweekday(newValue);
		onChange({frequency, repeatUntil, amountOfRepeats, byweekday: newValue});
	};

	return (
		<div className={classes.recurrenceWrapper}>
			<div className={classes.side}>
				<RepeatIcon
					viewBox="0 0 16 16"
					fill="none"
					style={{
						width: '16',
						height: '16',
					}}
					pathProps={{
						fillRule: 'evenodd',
						clipRule: 'evenodd',
						fill: 'black',
						fillOpacity: '0.54',
					}}
				/>
			</div>
			<div>
				<Box display="flex" alignItems="center">
					<Select
						onValueChange={handleFrequencyChange}
						availableOptions={frequencyOptions}
						value={frequency}
						style={{marginTop: 0}}
					/>
				</Box>
				{frequency !== NEVER_REPEAT_ID && (
					<div>
						<RadioGroup onChange={handleRepeatTypeChange} value={endRepeatType}>
							<div className={classes.afterLabel}>
								<Typography>{LL('components.daysOfWeek')}</Typography>
							</div>
							<ButtonGroup style={{marginBottom: 10}}>
								{WEEKDAYS.map((weekday) => (
									<Button
										key={weekday.id}
										variant={
											byweekday.includes(weekday.id) ? 'contained' : undefined
										}
										color="primary"
										onClick={() => handleByweekdayChange(weekday.id)}
									>
										{weekday.label}
									</Button>
								))}
							</ButtonGroup>
							<FormControlLabel
								value={RepeatType.byCount}
								control={<Radio color="primary" />}
								label={
									<Grid
										container
										direction="row"
										justifyContent="flex-start"
										alignItems="center"
									>
										<Typography style={{marginRight: '8px'}}>
											{LL('components.howManyTimes')}
										</Typography>
										<TextInputNaked
											type="number"
											value={amountOfRepeats}
											onChange={handleRepeatsAmountChange}
											disabled={endRepeatType !== RepeatType.byCount}
										/>
									</Grid>
								}
							/>
							<div className={classes.afterLabel}>
								<Typography>{LL('components.untilDate')}</Typography>
							</div>
							<FormControlLabel
								value={RepeatType.untilDate}
								control={<Radio color="primary" />}
								label={
									<Grid
										container
										direction="row"
										justifyContent="flex-start"
										alignItems="center"
									>
										<DateEditor
											value={repeatsEndDate}
											onChange={handleRepeatUntilChange}
											excludeTime
										/>
									</Grid>
								}
							/>
						</RadioGroup>
					</div>
				)}
			</div>
		</div>
	);
};

export default RecurrenceSettings;
