import * as React from 'react';
import {withTracker} from 'react-meteor-data-with-tracker';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Meteor} from 'meteor/meteor';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import {IconButton, TextField} from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import Switch from '@material-ui/core/Switch';

import {format} from 'shared/utils/dates';
import {Quiz} from 'shared/collections/Quizzes';
import {publishNames} from 'shared/constants/publishNames';
import {methodNames} from 'shared/constants/methodNames';
import {SurveyFormDialog} from './SurveyFormDialog';
import DialodWithElement from 'client/components/common/ui/DialodWithElement';
import routerUrls from 'client/constants/routerUrls';
import './SurveysPage.scss';
import {LL} from 'shared/localization';

class UsersPage extends React.PureComponent<IUsersPageProps & RouteComponentProps> {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			checkedActive: true,
		};
	}

	getBody = () => {
		const {quizzes, loading} = this.props;

		if (loading) return <CircularProgress />;
		if (quizzes && !quizzes.length) {
			return (
				<Typography variant="h6" className="alert alert-warning">
					{LL('admin.surveys.noSurveysToDisplay')}
				</Typography>
			);
		}

		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>#</TableCell>
						<TableCell>{LL('admin.surveys.question')}</TableCell>
						<TableCell>{LL('admin.surveys.customField')}</TableCell>
						<TableCell>{LL('admin.surveys.completedSurvey')}</TableCell>
						<TableCell>{LL('admin.surveys.variableSendPulse')}</TableCell>
						<TableCell>{LL('admin.surveys.active')}</TableCell>
						<TableCell>{LL('common.actions')}</TableCell>
						<TableCell>{LL('admin.surveys.updated')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{quizzes.map((quiz, index) => (
						<TableRow key={quiz._id}>
							<TableCell>{index + 1}</TableCell>
							<TableCell>{quiz.question}</TableCell>
							<TableCell>
								{quiz.customAnswer ? LL('common.yes') : LL('common.no')}
							</TableCell>
							<TableCell>
								{quiz.userIds.length}
								<Link
									to={routerUrls.adminSurveysStatistic
										.replace(':id', quiz._id)
										.replace(':question', quiz.question)}
								>
									<IconButton title={LL('admin.surveys.statistics')} edge="end">
										<TrendingUpRoundedIcon style={{color: '#3f51b5'}} />
									</IconButton>
								</Link>
							</TableCell>
							<TableCell>{quiz.sendPulseVariable || LL('common.no')}</TableCell>
							<TableCell>
								<DialodWithElement
									title={`${
										quiz.isActive
											? LL('admin.surveys.toDeactivate')
											: LL('admin.surveys.toActivate')
									} ${LL('admin.surveys.thisSurvey')}`}
									onSuccess={() => {
										Meteor.call(methodNames.quiz.update, {
											...quiz,
											isActive: !quiz.isActive,
										});
									}}
								>
									<Switch
										title={`${
											quiz.isActive
												? LL('admin.surveys.deactivate')
												: LL('admin.surveys.activate')
										} ${LL('admin.surveys.aSurvey')}`}
										checked={quiz.isActive}
										inputProps={{'aria-label': 'secondary checkbox'}}
									/>
								</DialodWithElement>
							</TableCell>
							<TableCell>
								<Grid container={true} wrap="nowrap">
									<SurveyFormDialog
										title={LL('admin.surveys.editYourSurvey')}
										quizData={quiz}
										onSuccess={(quiz) =>
											Meteor.call(methodNames.quiz.update, quiz)
										}
									>
										<IconButton
											edge="end"
											title={LL('common.edit')}
											onClick={() => this.setState({isOpen: true})}
										>
											<EditRoundedIcon style={{color: '#3f51b5'}} />
										</IconButton>
									</SurveyFormDialog>
									<DialodWithElement
										title={LL('common.delete')}
										onSuccess={() =>
											Meteor.call(methodNames.quiz.remove, quiz._id)
										}
									>
										<IconButton title={LL('common.delete')} edge="end">
											<DeleteForeverRoundedIcon style={{color: '#f00'}} />
										</IconButton>
									</DialodWithElement>
								</Grid>
							</TableCell>
							<TableCell>
								<div style={{whiteSpace: 'nowrap'}}>
									{quiz.updatedAt ? format(quiz.updatedAt!, 'd MMM yyyy') : ''}
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		);
	};

	handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.history.replace({
			pathname: this.props.match.path,
			search: `?search=${e.target.value}`,
		});
	};

	render() {
		return (
			<div>
				<Grid container justify="space-between">
					<Grid item className="headerContainer">
						<Typography className="pageTitle" variant="h5">
							{LL('common.adminHeader.surveys')}
						</Typography>
						<Grid container justify="space-between">
							<TextField
								label={LL('common.search')}
								variant="outlined"
								onChange={this.handleFontSizeChange}
								defaultValue={this.props.search}
								className="searchField"
							/>
							<SurveyFormDialog
								title={LL('admin.surveys.newPoll')}
								onSuccess={(quiz) => Meteor.call(methodNames.quiz.create, quiz)}
							>
								<Button
									variant="outlined"
									color="primary"
									className="add-button"
									onClick={() => this.setState({isOpen: true})}
								>
									{<AddIcon className="buttonIcon" />}
									{LL('admin.surveys.toAddPoll')}
								</Button>
							</SurveyFormDialog>
						</Grid>
					</Grid>
				</Grid>

				<Card className="tableCard">{this.getBody()}</Card>
			</div>
		);
	}
}

export default withTracker((props) => {
	const query = new URLSearchParams(props.location.search);

	// When the URL is /the-path?some-key=a-value ...
	const search = query.get('search');
	const subQuizzes = Meteor.subscribe(publishNames.quiz.all, {search}).ready();
	const loading = !subQuizzes;

	return {
		search,
		loading,
		quizzes: Quiz.find({}).fetch(),
	};
})(UsersPage);
