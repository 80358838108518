import React from 'react';
import useStyles from './useStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

interface IFastActionsProps {
	onDuplicate: () => void;
	onRepeatAllWeak: () => void;
}

const FastActions: React.FC<IFastActionsProps> = ({onDuplicate, onRepeatAllWeak}) => {
	const classes = useStyles();

	return (
		<div className={classes.fastActionsWrapper}>
			<div className={classes.fastActionsTitle}>
				<Typography>Быстрые действия</Typography>
			</div>

			<Button variant="contained" color="primary" onClick={onDuplicate}>
				Дублировать
			</Button>
			<Button variant="outlined" onClick={onRepeatAllWeak}>
				Повторять до конца недели
			</Button>
		</div>
	);
};

export default FastActions;
