import * as React from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import Typography from '@material-ui/core/Typography/Typography';

import StaticPage from 'client/components/pages/StaticPage';
import routerUrls from 'client/constants/routerUrls';
import {Box} from '@material-ui/core';

const PricesPage: React.FC<RouteComponentProps> = () => (
	<StaticPage title="Цены на услуги">
		<Typography variant="h6" gutterBottom>
			Подписка на 1 месяц
		</Typography>
		<Typography gutterBottom>
			- дополнительная функциональность Сервиса по настройке показа слайд-шоу по заранее
			определенному расписанию (потока) — 500 руб.
		</Typography>
		<Typography gutterBottom>- предоставление статистики показов — 500 руб.</Typography>
		<Typography gutterBottom>
			- использование платного хостинга для видеоматериалов (Vimeo) — 500 руб.
		</Typography>
		<Typography variant="h6" gutterBottom>
			Подписка на 3 месяца
		</Typography>
		<Typography gutterBottom>
			- дополнительная функциональность Сервиса по настройке показа слайд-шоу по заранее
			определенному расписанию (потока) — 1 500 руб.
		</Typography>
		<Typography gutterBottom>- предоставление статистики показов — 1 500 руб.</Typography>
		<Typography gutterBottom>
			- использование платного хостинга для видеоматериалов (Vimeo) — 1 500 руб.
		</Typography>
		<Typography variant="h6" gutterBottom>
			Подписка на 6 месяцев
		</Typography>
		<Typography gutterBottom>
			- дополнительная функциональность Сервиса по настройке показа слайд-шоу по заранее
			определенному расписанию (потока) — 3 000 руб.
		</Typography>
		<Typography gutterBottom>- предоставление статистики показов — 3 000 руб.</Typography>
		<Typography gutterBottom>
			- использование платного хостинга для видеоматериалов (Vimeo) — 3 000 руб.
		</Typography>
		<Typography gutterBottom>
			Все услуги приобретаются независимо в разделе{' '}
			<Link to={routerUrls.userPlan}>Мой тариф</Link>. Указанные цены действительны с 1 марта
			2024 года. Сервис оставляет за собой право на изменение цен в соответствии с{' '}
			<Link to={routerUrls.termsOfUse}>Пользовательским соглашением</Link>.
		</Typography>
		<Typography gutterBottom>
			Для оплаты на сайте используется платежная система{' '}
			<a href="https://paykeeper.ru/" target="_blank">
				PayKeeper
			</a>
			.
		</Typography>
		<Box display="flex" justifyContent="center" mt={2}>
			<Box maxWidth="406px">
				<img src="/img/payment/logos.png" alt="" style={{maxWidth: '100%'}} />
			</Box>
		</Box>
		<Typography gutterBottom variant="h5" align="center">
			Правила оплаты
		</Typography>
		<Typography gutterBottom>
			К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide, МИР
		</Typography>
		<Typography gutterBottom>
			Для оплаты товара банковской картой при оформлении заказа в интернет-магазине выберите
			способ оплаты: банковской картой.
		</Typography>
		<Typography gutterBottom>
			При оплате заказа банковской картой, обработка платежа происходит на авторизационной
			странице банка, где Вам необходимо ввести данные Вашей банковской карты:
		</Typography>
		<Box ml={2}>
			<ol>
				<li>тип карты</li>
				<li>номер карты,</li>
				<li>срок действия карты (указан на лицевой стороне карты)</li>
				<li>Имя держателя карты (латинскими буквами, точно также как указано на карте)</li>
				<li>CVC2/CVV2 код</li>
			</ol>
		</Box>
		<Box my={2}>
			<Box maxWidth="392px">
				<img src="/img/payment/card-explanation.png" />
			</Box>
		</Box>
		<Typography gutterBottom>
			Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически переадресованы на
			страницу банка, выпустившего карту, для прохождения процедуры аутентификации. Информацию
			о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам
			банковскую карту.
		</Typography>
		<Typography gutterBottom>
			Безопасность обработки интернет-платежей через платежный шлюз банка гарантирована
			международным сертификатом безопасности PCI DSS. Передача информации происходит с
			применением технологии шифрования SSL. Эта информация недоступна посторонним лицам.
		</Typography>
		<Typography gutterBottom>
			<strong>
				Советы и рекомендации по необходимым мерам безопасности проведения платежей с
				использованием банковской карты:
			</strong>
		</Typography>
		<Box ml={2}>
			<ol>
				<li>
					берегите свои пластиковые карты так же, как бережете наличные деньги. Не
					забывайте их в машине, ресторане, магазине и т.д.
				</li>
				<li>
					никогда не передавайте полный номер своей кредитной карты по телефону каким-либо
					лицам или компаниям
				</li>
				<li>
					всегда имейте под рукой номер телефона для экстренной связи с банком,
					выпустившим вашу карту, и в случае ее утраты немедленно свяжитесь с банком
				</li>
				<li>
					вводите реквизиты карты только при совершении покупки. Никогда не указывайте их
					по каким-то другим причинам.
				</li>
			</ol>
		</Box>
		<Box maxWidth="406px" my={2}>
			<img src="/img/payment/logos.png" alt="" style={{maxWidth: '100%'}} />
		</Box>
		<Typography gutterBottom variant="h6">
			Возврат товара
		</Typography>
		<Typography gutterBottom>
			Процедура возврата товара регламентируется статьей 26.1 федерального закона «О защите
			прав потребителей».
		</Typography>
		<ul>
			<li>
				Потребитель вправе отказаться от товара в любое время до его передачи, а после
				передачи товара - в течение семи дней;
			</li>
			<li>
				Возврат товара надлежащего качества возможен в случае, если сохранены его товарный
				вид, потребительские свойства, а также документ, подтверждающий факт и условия
				покупки указанного товара;
			</li>
			<li>
				Потребитель не вправе отказаться от товара надлежащего качества, имеющего
				индивидуально-определенные свойства, если указанный товар может быть использован
				исключительно приобретающим его человеком;
			</li>
			<li>
				При отказе потребителя от товара продавец должен возвратить ему денежную сумму,
				уплаченную потребителем по договору, за исключением расходов продавца на доставку от
				потребителя возвращенного товара, не позднее чем через десять дней со дня
				предъявления потребителем соответствующего требования;
			</li>
		</ul>
		<Typography gutterBottom variant="h6">
			Отказ от услуги
		</Typography>
		<Typography gutterBottom>
			Право потребителя на расторжение договора об оказании услуги регламентируется статьей 32
			федерального закона «О защите прав потребителей»
		</Typography>
		<ul>
			<li>
				Потребитель вправе расторгнуть договор об оказании услуги в любое время, уплатив
				исполнителю часть цены пропорционально части оказанной услуги до получения извещения
				о расторжении указанного договора и возместив исполнителю расходы, произведенные им
				до этого момента в целях исполнения договора, если они не входят в указанную часть
				цены услуги;
			</li>
			<li>
				Потребитель при обнаружении недостатков оказанной услуги вправе по своему выбору
				потребовать:
			</li>
			<ul>
				<li>безвозмездного устранения недостатков;</li>
				<li>соответствующего уменьшения цены;</li>
				<li>
					возмещения понесенных им расходов по устранению недостатков своими силами или
					третьими лицами;
				</li>
			</ul>
			<li>
				Потребитель вправе предъявлять требования, связанные с недостатками оказанной
				услуги, если они обнаружены в течение гарантийного срока, а при его отсутствии в
				разумный срок, в пределах двух лет со дня принятия оказанной услуги;
			</li>
			<li>
				Исполнитель отвечает за недостатки услуги, на которую не установлен гарантийный
				срок, если потребитель докажет, что они возникли до ее принятия им или по причинам,
				возникшим до этого момента;
			</li>
		</ul>
		<Typography gutterBottom variant="h6">
			Реквизиты организации
		</Typography>
		<Typography>Генеральный директор: Кузнецов Александр Юрьевич</Typography>
		<Typography>Адрес: 115573, Москва г, Мусы Джалиля ул, дом 8, корпус 4, офис 612</Typography>
		<Typography>ИНН: 7724818587</Typography>
		<Typography>КПП: 772401001</Typography>
		<Typography>ОГРН: 1127746004693</Typography>
		<Typography>ОКПО: 37307897</Typography>
		<Typography>ОКАТО: 45296565000</Typography>
		<Typography>ОКВЭД: 74.40</Typography>
		<Typography>Рег. № ПФР: 087603003731</Typography>
		<Typography>Расчетный счет: 40702810238250013522</Typography>
		<Typography>Банк: ОАО "СБЕРБАНК РОССИИ"</Typography>
		<Typography>БИК: 044525225</Typography>
		<Typography>Kорр. счет: 30101810400000000225</Typography>
	</StaticPage>
);

export default PricesPage;
