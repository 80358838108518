import * as React from 'react';
import {withTracker} from 'react-meteor-data-with-tracker';
import {Meteor} from 'meteor/meteor';
import {RouteComponentProps} from 'react-router-dom';

import {publishNames} from 'shared/constants/publishNames';
import {UsersTable} from './UsersTable';
import {SearchField} from 'client/components/common/ui/SearchField';
import './UsersPage.scss';

interface IEmail {
	address: string;
	verified: boolean;
}

interface IUser extends Meteor.User {
	_id: string;
	name: string;
	surname: string;
	emails: IEmail[];
	phone: string;
	status: string;
}

interface IUsersPageProps {
	loading: boolean;
	users: [IUser];
	caption: string;
	search: string;
}

export class UsersPage extends React.PureComponent<IUsersPageProps & RouteComponentProps> {
	render() {
		return (
			<div>
				<SearchField
					caption={this.props.caption}
					search={this.props.search}
					match={this.props.match}
					history={this.props.history}
				/>
				<UsersTable users={this.props.users} loading={this.props.loading} />
			</div>
		);
	}
}

export default withTracker<{}, RouteComponentProps>(
	(props: {
		location: {
			search: string | string[][] | Record<string, string> | URLSearchParams | undefined;
		};
	}) => {
		const query = new URLSearchParams(props.location.search);

		// When the URL is /the-path?some-key=a-value ...
		const search = query.get('search');

		const usersSub = Meteor.subscribe(publishNames.user.users, {search});
		const loading = !usersSub.ready();

		return {
			search,
			loading,
			users: Meteor.users.find({}, {sort: {_id: 1}}).fetch(),
		};
	},
)(UsersPage);
