import {Mongo} from 'meteor/mongo';
import {Class} from 'meteor/jagi:astronomy';

export interface IAttachment {
	_id: string;
	name: string;
	size: number;
	contentType: string;
	folder: string;
	uploadedAt: Date;
	uploadedBy: string;
	key: string;
}

export const Attachment = Class.create<IAttachment>({
	name: 'Attachment',
	collection: new Mongo.Collection<IAttachment>('attachments'),
	fields: {
		name: String,
		size: Number,
		contentType: String,
		folder: String,
		uploadedAt: Date,
		uploadedBy: String,
		key: String,
	},
});

export const SlingshotDirectiveName = {
	Images: 'images',
	Fonts: 'fonts',
} as const;
export const attacmentsSizeRestrictions = {
	[SlingshotDirectiveName.Images]: 1000 * 1000 * 20,
};
