import React from 'react';
import ClockIcon from 'client/components/common/icons/ClockIcon';
import {Box, Grid, Switch} from '@material-ui/core';
import useStyles from './useStyles';
import DateEditor from './DateEditor';
import {Warning} from '@material-ui/icons';
import moment from 'moment';
import {LL} from 'shared/localization';

interface IEventStartEndDatesProps {
	startDate: Date;
	endDate: Date;
	allDay?: boolean;
	date?: string;
	onChange: (
		eventDatesInfo: Pick<IEventStartEndDatesProps, 'startDate' | 'endDate' | 'allDay' | 'date'>,
	) => void;
}

const EventStartEndDates: React.FC<IEventStartEndDatesProps> = ({
	startDate,
	endDate,
	allDay,
	date,
	onChange,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.main}>
			<div className={classes.side}>
				<ClockIcon
					viewBox="0 0 18 18"
					fill="none"
					style={{
						width: '18px',
						height: '18px',
					}}
					pathProps={{
						fill: 'black',
						fillOpacity: '0.54',
						fillRule: 'evenodd',
						clipRule: 'evenodd',
					}}
				/>
			</div>
			<div>
				<Grid xs={12}>
					{allDay && date ? (
						<Grid item xs={12}>
							<Box mb={0.5} color="rgba(0, 0, 0, 0.6)" fontSize={16} lineHeight={1.5}>
								{LL('components.showDate')}
							</Box>
							<DateEditor
								value={new Date(date)}
								onChange={(newStartDate) =>
									onChange({
										startDate: moment(newStartDate)
											.startOf('day')
											.toDate(),
										endDate: moment(newStartDate)
											.endOf('day')
											.toDate(),
										allDay: !!allDay,
										date: moment(newStartDate).format('YYYY-MM-DD'),
									})
								}
								excludeTime
							/>
						</Grid>
					) : (
						<>
							<Grid item xs={12}>
								<Box
									mb={0.5}
									color="rgba(0, 0, 0, 0.6)"
									fontSize={16}
									lineHeight={1.5}
								>
									{LL('components.showStartDate')}
								</Box>
								<DateEditor
									value={startDate}
									onChange={(newStartDate) =>
										onChange({
											endDate,
											startDate: newStartDate,
											allDay: !!allDay,
										})
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<Box
									mb={0.5}
									color="rgba(0, 0, 0, 0.6)"
									fontSize={16}
									lineHeight={1.5}
									mt={2}
								>
									{LL('components.showEndDate')}
								</Box>
								<DateEditor
									value={endDate}
									onChange={(newEndDate) =>
										onChange({
											startDate,
											endDate: newEndDate,
											allDay: !!allDay,
										})
									}
								/>
							</Grid>
						</>
					)}
					<Grid item xs={12}>
						<Box
							mt={2}
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 5,
							}}
						>
							{LL('components.allDay')}
							<Switch
								checked={allDay}
								onChange={(_, isChecked) =>
									onChange({
										endDate,
										startDate,
										allDay: isChecked,
										date: isChecked
											? moment(startDate).format('YYYY-MM-DD')
											: undefined,
									})
								}
							/>
						</Box>
					</Grid>
					{moment(endDate).isBefore(moment(startDate)) && (
						<Grid item xs={12}>
							<Box
								mt={2}
								color="red"
								fontSize={18}
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: 5,
								}}
							>
								<Warning />
								{LL('components.showWarning')}
							</Box>
						</Grid>
					)}
				</Grid>
			</div>
		</div>
	);
};

export default EventStartEndDates;
