import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Grid from '@material-ui/core/Grid/Grid';
import {Meteor} from 'meteor/meteor';
import {Accounts} from 'meteor/accounts-base';
import Paper from '@material-ui/core/Paper/Paper';
import {TextField} from 'final-form-material-ui';
import {Field, Form} from 'react-final-form';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import {FORM_ERROR} from 'final-form';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';

import routerUrls from 'client/constants/routerUrls';
import LoginWithFacebookButton from 'client/components/oauth/LoginWithFacebookButton';
import {requiredInput} from 'client/components/common/ui/requiredValidator';
import {LL} from 'shared/localization';

interface IRegistrationFormData {
	email: string;
	password: string;
	name: string;
	company?: string;
	userExist?: boolean;
}

class RegistrationPage extends React.PureComponent<RouteComponentProps> {
	constructor(props) {
		super(props);
		this.state = {userExist: false};
	}
	onSubmitHandler = (values: IRegistrationFormData) => {
		return new Promise((resolve, reject) => {
			const user = {
				email: values.email,
				password: values.password,
				profile: {
					company: values.company,
					name: values.name,
				},
			};

			Accounts.createUser(user, (err: any) => {
				if (err) {
					console.log(err);
					if (err.error == 403) {
						this.setState({
							userExist: true,
						});
					}
					reject({[FORM_ERROR]: err.reason});
				} else {
					// Send verification email
					// TODO: переместить на сервер
					this.setState({
						userExist: false,
					});
					Meteor.call('users.sendVerificationEmail', () => {});
					resolve();
				}
			});
		});
	};

	render() {
		return (
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				className="loginPage"
			>
				<Grid item>
					<Paper className="paperWrap">
						<Typography className="title" variant="h5">
							{LL('common.registration')}
						</Typography>

						<Form
							onSubmit={this.onSubmitHandler}
							render={({handleSubmit, submitError, submitting}) => (
								<form onSubmit={handleSubmit} name="registration">
									<Field
										name="name"
										component={TextField}
										type="text"
										label={`${LL('common.name')} *`}
										validate={requiredInput()}
										variant="outlined"
										className="field"
										margin="dense"
										fullWidth
									/>

									<Field
										name="company"
										component={TextField}
										type="text"
										label={LL('common.company')}
										variant="outlined"
										className="field"
										margin="dense"
										fullWidth
									/>

									<Field
										name="email"
										component={TextField}
										type="email"
										label="E-mail *"
										validate={requiredInput()}
										variant="outlined"
										className="field"
										margin="dense"
										fullWidth
									/>

									{this.state.userExist ? (
										<p
											style={{
												color: '#f44336',
												textAlign: 'center',
												margin: '10px 0',
											}}
										>
											{LL('auth.regDescription')}
										</p>
									) : (
										''
									)}

									<Field
										name="password"
										component={TextField}
										type="password"
										label={`${LL('common.password')} *`}
										validate={requiredInput()}
										variant="outlined"
										className="field"
										margin="dense"
										fullWidth
									/>

									{!submitting && submitError && (
										<FormHelperText error>{submitError}</FormHelperText>
									)}

									<Button
										disabled={submitting}
										className="button"
										type="submit"
										color="primary"
										variant="outlined"
										size="large"
										fullWidth
									>
										{LL('auth.register')}
									</Button>

									<LoginWithFacebookButton />
								</form>
							)}
						/>
					</Paper>

					<div className="links">
						<a className="link" href={routerUrls.authLogin}>
							{LL('common.login')}
						</a>
						&nbsp; &middot; &nbsp;
						<a className="link" href={routerUrls.authForgotPassword}>
							{LL('auth.forgotPass')}
						</a>
					</div>

					<div className="links">
						<a className="link" href={routerUrls.termsOfUse}>
							{LL('auth.termsOfUse')}
						</a>
						<br />
						<a className="link" href={routerUrls.privacyPolicy}>
							{LL('auth.privacyPolicy')}
						</a>
						<br />
						<a className="link" href={routerUrls.prices}>
							{LL('auth.pricesForServices')}
						</a>
					</div>
				</Grid>
			</Grid>
		);
	}
}

export default RegistrationPage;
