exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".actions--3-_Xt {\n\tpadding-bottom: 15px;\n}\n\n.label--2N4mo {\n\tmargin-bottom: 4px;\n\tfont-size: 11px;\n\tfont-weight: normal;\n\ttext-transform: uppercase;\n\tcolor: #ddd;\n\tcursor: default;\n}\n\n.tabsFlexContainer--1N2Ri {\n\twidth: 100%;\n}\n\n.tabsIndicator--Buyt8 {\n\tdisplay: none;\n}\n\n.tabs--1sAWH {\n\tdisplay: -webkit-box;\n\tdisplay: -webkit-flex;\n\tdisplay: -moz-box;\n\tdisplay: flex;\n\tmin-height: unset !important;\n}\n\n.tabs--1sAWH .tab--3C-OI {\n\t\topacity: unset;\n\t\tdisplay: inline-block;\n\t\tpadding: 4px 4px;\n\t\tcursor: pointer;\n\t\ttext-align: center;\n\t\tbackground: #4e4e4e;\n\t\tborder: 1px solid transparent;\n\t\tborder-radius: 2px;\n\t\t-webkit-box-flex: 2;\n\t\t-webkit-flex-grow: 2;\n\t\t   -moz-box-flex: 2;\n\t\t        flex-grow: 2;\n\t\tcolor: #fff;\n\t\theight: 28px;\n\t\tline-height: 26px;\n\t\twidth: unset;\n\t\tmin-width: unset;\n\t\tmin-height: unset;\n\t\tmargin-left: 4px;\n\t}\n\n.tabs--1sAWH .tab--3C-OI:first-child {\n\t\t\tmargin-left: 0;\n\t\t}\n\n.tabs--1sAWH .tab--3C-OI:hover {\n\t\t\tbackground: #555555;\n\t\t}\n\n.tabs--1sAWH .tab--3C-OI.selected--3BDwQ {\n\t\t\tbackground-color: #1baee1;\n\t\t}\n\n.tabs--1sAWH .tab--3C-OI svg {\n\t\t\tfont-size: 18px;\n\t\t}\n\n.together--WKpFj .tab--3C-OI {\n\t\tmargin-left: 0;\n\t\tborder-radius: 0;\n\t}\n\n.together--WKpFj .tab--3C-OI:first-child {\n\t\t\tborder-radius: 2px 0 0 2px;\n\t\t}\n\n.together--WKpFj .tab--3C-OI:last-child {\n\t\t\tborder-radius: 0 2px 2px 0;\n\t\t}", ""]);

// Exports
exports.locals = {
	"actions": "actions--3-_Xt",
	"label": "label--2N4mo",
	"tabsFlexContainer": "tabsFlexContainer--1N2Ri",
	"tabsIndicator": "tabsIndicator--Buyt8",
	"tabs": "tabs--1sAWH",
	"tab": "tab--3C-OI",
	"selected": "selected--3BDwQ",
	"together": "together--WKpFj"
};