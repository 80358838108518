import React from 'react';
import {withTracker} from 'react-meteor-data-with-tracker';
import {ISlideshow, Slideshow} from 'shared/collections/Slideshows';
import Select, {components} from 'react-select';
import groupBy from 'lodash/groupBy';
import {Group} from 'shared/collections/Groups';

interface ISlideshowSelectProps {
	options: ISlideshow[];
	value?: ISlideshow;
	onChange: (newValue: ISlideshowSelectProps['value']) => void;
}

const Option = (props: any) => {
	// delete props.children;
	return (
		<components.Option {...props}>
			{props.label}
			&nbsp;
			<span
				style={{opacity: 0.6, fontStyle: 'italic', fontSize: 'smaller'}}
			>{`(номер: ${props.value})`}</span>
		</components.Option>
	);
};

const SlideshowSelect: React.FC<ISlideshowSelectProps> = ({options, value, onChange}) => (
	<Select
		options={options}
		components={{Option}}
		placeholder="Выберите слайдшоу"
		isClearable={true}
		menuPortalTarget={document.body}
		className="react-select-container"
		classNamePrefix="react-select"
		value={value ? {value: value.numId, label: value.name} : null}
		onChange={(selected: any) => onChange(selected?.value)}
	/>
);

export default withTracker((props: any) => {
	const grouped = groupBy(Slideshow.find().fetch(), (slideshow) => slideshow.groupId);

	return {
		options: Object.entries(grouped).map(([groupId, slideshows]) => ({
			label: Group.findOne(groupId)?.name,
			options: slideshows.map((slideshow) => ({
				label: slideshow.name,
				value: slideshow.numId,
			})),
		})),
		value:
			typeof props.value === 'object' ? props.value : Slideshow.findOne({numId: props.value}),
	};
})(SlideshowSelect);
