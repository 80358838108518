exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".circleTabsWrapper--2gHGz {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -moz-box;\n    display: flex;\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n       -moz-box-pack: center;\n            justify-content: center;\n}\n.circleTabs--Y3lc2 {\n    display: -webkit-inline-box;\n    display: -webkit-inline-flex;\n    display: -moz-inline-box;\n    display: inline-flex;\n    border-radius: 32px;\n    border: 1px solid rgba(0, 0, 0, 0.12)\n}\n.circleTab--3RdsK {\n    display: -webkit-inline-box;\n    display: -webkit-inline-flex;\n    display: -moz-inline-box;\n    display: inline-flex;\n    padding: 8px 16px;\n    border: 2px solid transparent;\n    border-radius: 32px;\n    text-decoration: none;\n    color: rgba(0, 0, 0, 0.6);\n    margin: -1px;\n}\n.circleTab--3RdsK.active--2aLnb {\n        border-color: #3F51B5;\n    }\n", ""]);

// Exports
exports.locals = {
	"circleTabsWrapper": "circleTabsWrapper--2gHGz",
	"circleTabs": "circleTabs--Y3lc2",
	"circleTab": "circleTab--3RdsK",
	"active": "active--2aLnb"
};