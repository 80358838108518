import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default React.memo(({pathProps, ...restProps}) => (
	<SvgIcon {...restProps}>
		<path
			{...pathProps}
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.50008 4.66667H8.83341V3.33333C8.83341 1.49333 7.34008 0 5.50008 0C3.66008 0 2.16675 1.49333 2.16675 3.33333V4.66667H1.50008C0.766748 4.66667 0.166748 5.26667 0.166748 6V12.6667C0.166748 13.4 0.766748 14 1.50008 14H9.50008C10.2334 14 10.8334 13.4 10.8334 12.6667V6C10.8334 5.26667 10.2334 4.66667 9.50008 4.66667ZM5.50008 10.6667C4.76675 10.6667 4.16675 10.0667 4.16675 9.33333C4.16675 8.6 4.76675 8 5.50008 8C6.23341 8 6.83341 8.6 6.83341 9.33333C6.83341 10.0667 6.23341 10.6667 5.50008 10.6667ZM3.50008 3.33333V4.66667H7.50008V3.33333C7.50008 2.22667 6.60675 1.33333 5.50008 1.33333C4.39341 1.33333 3.50008 2.22667 3.50008 3.33333Z"
		/>
	</SvgIcon>
));
